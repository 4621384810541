import React, { useEffect, useState } from "react";

import { getMe, getUser, post } from "../../networking/Server";
import { observer } from "mobx-react-lite";
import {
  Row,
  Col,
  Button,
  Card,
  Divider,
  Form,
  Input,
  InputNumber,
  Spin,
  Checkbox,
  TimePicker,
  Switch,
  Select,
} from "antd";
import { getCafe, getCafes } from "../../functions/getCafe";
import ImagePicker from "../../components/ImagePicker";
import { error, success } from "../../functions/toast";
import { MStore } from "../../networking/stores/MainStore";
import { toJSON, toParse } from "../../functions/json";
import { l_moment } from "../../functions/l_moment";
import { TwitterPicker, BlockPicker } from "react-color";
import { createColor } from "../../functions/createColor";
import Customizer from "../../containers/Customizer";
import IntlMessages from "../../util/IntlMessages";
import { OrderTypes } from "../../constants/Config";
import { useIntl } from "react-intl";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const _colors = [
  "#31c149",
  "#fb3d67",
  "#D9E3F0",
  "#F47373",
  "#697689",
  "#37D67A",
  "#2CCCE4",
  "#555555",
  "#ff8a65",
  "#ba68c8",
];
const Cafe = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [on_loading, setOnLoading] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    post("api/cafe/get", { cafe_id: getCafe() }).then((res) => {
      if (res.result && res.cafe) {
        setData(res.cafe);
      }
    });
  }

  function setCafe(_data) {
    _data["image"] = data["image"];
    _data["opening_hours"] = data["opening_hours"];
    _data["colors"] = data["colors"];
    _data["cafe_id"] = data["cafe_id"];
    _data["order_types"] = toJSON(_data["order_types"]);
    _data["no_require_pay"] = toJSON(_data["no_require_pay"]);

    setLoading(true);
    post("api/cafe/set", { cafe_id: data["cafe_id"], data: _data }, true).then(
      (res) => {
        getMe(true).then(() => {
          setLoading(false);
          if (res.result) {
            success();
            try {
              MStore.setCafe(
                getCafes().filter((e) => e.cafe_id == data["cafe_id"])[0]
              );
              window.location.reload(false);
            } catch (e) {}
          } else {
            if (res.error) {
              error(intl.formatMessage({ id: res.error }));
            } else {
              error();
            }
          }
        });
      }
    );
  }

  function setOnOff(cafe_id, on) {
    setOnLoading(true);
    post("api/cafe/set", { cafe_id, data: { on } }, true).then((res) => {
      getMe(true).then(() => {
        setOnLoading(false);
        if (res.result) {
          success();
          try {
            MStore.setCafe(
              getCafes().filter((e) => e.cafe_id == data["cafe_id"])[0]
            );
            window.location.reload(false);
          } catch (e) {}
        } else {
          if (res.error) {
            error(intl.formatMessage({ id: res.error }));
          } else {
            error();
          }
        }
      });
    });
  }

  if (!data["cafe_id"]) {
    return <Spin className="gx-loader-container" />;
  }

  let opening_hours = toParse(data, "opening_hours");
  let colors = toParse(data, "colors");

  let setting = {};
  try {
    setting = data["settings"][0];
  } catch (e) {}

  const currenct = getCafe("currency");
  return (
    <Card style={{ padding: 20 }}>
      <ImagePicker
        style={{ marginLeft: 10 }}
        onChange={(images) => {
          setData({ ...data, image: images[0] });
        }}
        images={[data["image"]]}
        multiple
        accept={".png, .jpg, .jpeg"}
        maxCount={1}
      />
      <Form
        style={{ marginTop: 20 }}
        {...layout}
        layout="vertical"
        name="validate_other"
        initialValues={data}
        onFinish={setCafe}
      >
        <Form.Item
          label={<IntlMessages id={"aktiflik"} />}
          valuePropName={"checked"}
          name={"on"}
          rules={[{ required: false }]}
        >
          <Switch
            loading={on_loading}
            checkedChildren={<IntlMessages id={"acik"} />}
            unCheckedChildren={<IntlMessages id={"kapali"} />}
            onChange={(on) => {
              setOnOff(data["cafe_id"], on);
            }}
          />
        </Form.Item>

        <Form.Item
          name={"name"}
          label={<IntlMessages id={"kafeismi"} />}
          rules={[
            { required: true, message: <IntlMessages id="gecersizbilgi" /> },
          ]}
        >
          <Input maxLength={100} />
        </Form.Item>

        <Form.Item
          style={{ maxWidth: "50%" }}
          name={"currency"}
          label={<IntlMessages id="parabirimi" />}
          rules={[
            { required: true, message: <IntlMessages id="gecersizbilgi" /> },
          ]}
        >
          <Input maxLength={5} />
        </Form.Item>

        <Form.Item
          name={"device_id"}
          initialValue={setting["device_id"]}
          label={"Termux Device ID"}
        >
          <Input maxLength={100} />
        </Form.Item>

        <Form.Item
          name={"address"}
          label={<IntlMessages id="adres" />}
          rules={[{ required: false }]}
        >
          <Input.TextArea rows={2} />
        </Form.Item>

        <Form.Item
          name={"phone"}
          label={<IntlMessages id="telefon" />}
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          style={{ maxWidth: "50%" }}
          name={"tax_name"}
          label={<IntlMessages id="vergiismi" />}
          rules={[{ required: false }]}
        >
          <Input maxLength={50} />
        </Form.Item>

        <Form.Item
          style={{ maxWidth: "50%" }}
          name={"tax_rate"}
          label={<IntlMessages id="vergiorani" />}
          rules={[{ required: false }]}
        >
          <InputNumber
            min={0}
            max={100}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace("%", "")}
          />
        </Form.Item>

        <div>
          <Divider dashed orientation="left">
            <IntlMessages id="renkler" />
          </Divider>
          <Row
            style={{ flexDirection: "row", alignItems: "center", margin: 20 }}
          >
            <Col
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                <IntlMessages id="posanarenk" />
              </h4>
              <BlockPicker
                colors={_colors}
                color={colors["primary"] ? colors["primary"] : "#31c149"}
                onChangeComplete={({ hex }) => {
                  colors["primary"] = hex;
                  data["colors"] = toJSON(colors);
                  setData({ ...data });
                }}
              />
            </Col>
            <Col
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                <IntlMessages id="posikincirenk" />
              </h4>
              <BlockPicker
                colors={_colors}
                color={colors["secondary"] ? colors["secondary"] : "#fb3d67"}
                onChangeComplete={({ hex }) => {
                  colors["secondary"] = hex;
                  data["colors"] = toJSON(colors);
                  setData({ ...data });
                }}
              />
            </Col>
            <Col
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                <IntlMessages id="istatistikgrafikrengi" />
              </h4>
              <BlockPicker
                colors={_colors}
                color={colors["statistic"]}
                onChangeComplete={({ hex }) => {
                  colors["statistic"] = hex;
                  data["colors"] = toJSON(colors);
                  setData({ ...data });
                }}
              />
            </Col>
          </Row>
        </div>

        <div>
          <Divider dashed orientation="left">
            <IntlMessages id="qruygulamaayarlari" />
          </Divider>
          <Form.Item
            name={"order_types"}
            initialValue={toParse(setting["order_types"])}
            label={<IntlMessages id="desteklenensiparisler" />}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              defaultValue={data["order_types"]}
            >
              {Object.values(OrderTypes)
                .filter(({ id }) => [3, 4, 7].includes(id))
                .map(({ id, value }) => (
                  <Select.Option value={id}>
                    <IntlMessages id={value} />
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name={"no_require_pay"}
            initialValue={toParse(setting["no_require_pay"])}
            label={<IntlMessages id="nakitodemedestekleyensiparisler" />}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              defaultValue={data["no_require_pay"]}
            >
              {Object.values(OrderTypes)
                .filter(({ id }) => [3, 4, 7].includes(id))
                .map(({ id, value }) => (
                  <Select.Option value={id}>
                    <IntlMessages id={value} />
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name={"min_order_price"}
            initialValue={setting["min_order_price"]}
            label={<IntlMessages id="minimumsiparistutari" />}
            rules={[{ required: false }]}
          >
            <InputNumber
              min={1}
              formatter={(value) =>
                `${currenct} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) =>
                value.replace(new RegExp(currenct + "s?|(,*)", "g"), "")
              }
            />
          </Form.Item>
        </div>

        {Array.isArray(opening_hours) && opening_hours.length ? (
          <div style={{ marginTop: 50 }}>
            <Divider dashed orientation="left">
              <IntlMessages id="calismasaatleri" />
            </Divider>
            {opening_hours.map(({ open, start, end }, index) => {
              return (
                <Row
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    margin: 20,
                  }}
                >
                  <Col xl={4} lg={6} md={8} sm={8} xs={24}>
                    <Checkbox
                      checked={open}
                      style={
                        !open ? { textDecorationLine: "line-through" } : {}
                      }
                      onChange={(e) => {
                        opening_hours[index]["open"] = e.target.checked;
                        data["opening_hours"] = toJSON(opening_hours);
                        setData({ ...data });
                      }}
                    >
                      {l_moment()
                        .day(index + 1)
                        .format("dddd")}
                    </Checkbox>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                    <TimePicker.RangePicker
                      order={false}
                      disabled={!open}
                      minuteStep={15}
                      allowClear={false}
                      format={"HH:mm"}
                      value={[l_moment(start, "HH:mm"), l_moment(end, "HH:mm")]}
                      onChange={(_, hours) => {
                        opening_hours[index]["start"] = hours[0];
                        opening_hours[index]["end"] = hours[1];
                        data["opening_hours"] = toJSON(opening_hours);

                        setData({ ...data });
                      }}
                    />
                  </Col>
                </Row>
              );
            })}
          </div>
        ) : null}

        <Form.Item style={{ marginTop: 50 }}>
          <Button
            loading={loading}
            className="gx-mr-2"
            type="primary"
            htmlType="submit"
          >
            <IntlMessages id="kaydet" />
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default observer(Cafe);
