import {getCafe} from "./getCafe";
import {toParse} from "./json";

export function createColor(str, s=70, l=70,o=1) {


  if(!str)
    str=""
  str=str.toString()
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }


  let h = hash % 360;



  return 'hsl('+h+', '+s+'%, '+l+'%, '+o+')';
}
