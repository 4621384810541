import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
} from "recharts";
import { Col, Row, Spin } from "antd";
import { createColor } from "../../functions/createColor";
import LineIndicator from "../dashboard/CRM/LineIndicator";
import IntlMessages from "../../util/IntlMessages";

const CartPie = ({
  data,
  height,
  hideInfo = false,
  unit = "",
  format = (item) => item,
  header = () => {},
  total_values,
}) => {
  let total = 0;
  let header_total = 0;
  function createData(_data) {
    let data = [];
    total = 0;
    header_total = 0;

    try {
      _data.map(({ name, value }) => {
        total += value;
        if (total_values) {
          //console.warn(total_values, name, value, total_values.includes(name));
          if (total_values.includes(name)) {
            header_total += value;
          }
        } else {
          header_total += value;
        }
      });

      data = _data.map((item, index) => {
        let percent = parseFloat(((100 * item.value) / total).toFixed(1));
        item = format(item);
        return { ...item, percent };
      });
    } catch (e) {}

    return data.sort((a, b) => (a.percent > b.percent ? -1 : 1));
  }

  if (!Array.isArray(data)) {
    return (
      <div
        style={{ height, width: "100%" }}
        className="gx-flex-row gx-justify-content-center gx-align-items-center"
      >
        <Spin />
      </div>
    );
  }

  data = createData(data);

  if (!data.length) {
    return (
      <div
        style={{ height, width: "100%" }}
        className="gx-flex-row gx-justify-content-center gx-align-items-center"
      >
        <IntlMessages id="noistatis" />
      </div>
    );
  }

  return (
    <div style={{ height }}>
      {header({ total: header_total.toFixed(1), unit })}
      <Row className="gx-flex-row gx-justify-content-center gx-align-items-center">
        <Col xs={24} sm={12}>
          <ResponsiveContainer width="100%" height={height}>
            <PieChart width={"100%"} height={200}>
              <Pie
                isAnimationActive
                dataKey="percent"
                data={data}
                innerRadius={40}
                outerRadius={80}
              >
                {data.map(({ name, color }, index) => (
                  <Cell
                    key={index}
                    unit={"%"}
                    fill={color ? color : createColor(name)}
                  />
                ))}
              </Pie>
              <Tooltip formatter={(value) => value + "%"} />
            </PieChart>
          </ResponsiveContainer>
        </Col>
        {!hideInfo ? (
          <Col
            xs={24}
            sm={12}
            className="gx-site-dash gx-mb-2 gx-pt-3 gx-pt-sm-0 gx-pt-xl-2"
          >
            <ul className="gx-line-indicator">
              {data.map(({ name, value, percent, color }) => (
                <li>
                  <LineIndicator
                    width={percent + "%"}
                    title={name}
                    hcolor={color ? color : createColor(name)}
                    value={value + unit}
                  />
                </li>
              ))}
            </ul>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

export default CartPie;
