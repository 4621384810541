import { observable, action } from "mobx";

class InstantStoreC {
  @observable loading = false;
  @observable modal = {};
  @observable modal2 = {};
  @observable intervals = null;

  @observable orders = [];

  @action setLoading(data) {
    this.loading = data;
  }
  @action setModal(data) {
    this.modal = data;
  }
  @action setModal2(data) {
    this.modal2 = data;
  }
  @action changeModal(data) {
    this.modal = { ...this.modal, ...data };
  }
  @action setIntervals(data) {
    this.intervals = data;
  }
  @action setOrders(data) {
    this.orders = data;
  }
}

export const Store = new InstantStoreC();
