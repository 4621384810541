import React, { useEffect, useState } from "react";

import { getMe, getUser, post } from "../../networking/Server";
import { observer } from "mobx-react-lite";
import {
  Button,
  Card,
  Collapse,
  Form,
  Input,
  Divider,
  Popconfirm,
  Spin,
} from "antd";
import { getCafe, getCafes } from "../../functions/getCafe";
import { PlusOutlined } from "@ant-design/icons";
import { Store } from "../../networking/stores/Store";
import { error, success } from "../../functions/toast";
import EditTable from "./edit";
import IntlMessages from "../../util/IntlMessages";
import { useIntl } from "react-intl";

const Tables = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [edit_loading, setEditLoading] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    post("api/cafe/get_tables", { cafe_id: getCafe() }).then((res) => {
      setLoading(false);
      setEditLoading(false);
      if (res.result) {
        setData(res.tables);
      }
    });
  }

  function setTableCategory(table_category_id, data) {
    setEditLoading(true);
    post("api/cafe/set_table_category", {
      cafe_id: getCafe(),
      data: { ...data, table_category_id },
    }).then((res) => {
      getData();
      if (res.result) {
        success();
      } else {
        if (res.error) {
          error(intl.formatMessage({ id: res.error }));
        } else {
          error();
        }
      }
    });
  }

  if (loading) {
    return <Spin className="gx-loader-container" />;
  }

  function editRender(table_category_id = 0, name = "") {
    return (
      <div key={table_category_id} className="gx-flex-row gx-ml-4">
        <Form
          layout="inline"
          name={table_category_id}
          initialValues={{ name }}
          onFinish={(data) => {
            setTableCategory(table_category_id, data);
          }}
        >
          <Form.Item
            name={"name"}
            rules={[
              { required: true, message: <IntlMessages id="gecersizbilgi" /> },
            ]}
          >
            <Input
              placeholder={intl.formatMessage({ id: "kategoriismi" })}
              maxLength={100}
            />
          </Form.Item>

          <Form.Item className=" gx-text-right gx-flex-row">
            <Button
              loading={edit_loading}
              className="gx-mr-2"
              type="primary"
              htmlType="submit"
            >
              <IntlMessages id="kaydet" />
            </Button>
          </Form.Item>
          {table_category_id ? (
            <Popconfirm
              title={<IntlMessages id="kategorisildesc" />}
              onConfirm={() => {
                setTableCategory(table_category_id, { status: 0 });
              }}
            >
              <Button loading={edit_loading} className="gx-mr-2" danger>
                <IntlMessages id="kategorisil" />
              </Button>
            </Popconfirm>
          ) : null}
        </Form>
      </div>
    );
  }

  return (
    <Card title={<IntlMessages id="masalar" />} style={{ padding: 20 }}>
      <Collapse
        accordion
        ghost
        defaultActiveKey={[Array.isArray(data) && data.length ? "1" : "0"]}
      >
        {data.map(({ table_category_id, name, tables }, index) => {
          return (
            <Collapse.Panel header={name} key={index + 1}>
              {editRender(table_category_id, name)}
              <Divider dashed orientation="left">
                <IntlMessages id="masalar" />
              </Divider>
              <div className="gx-flex-row gx-ml-4">
                {Array.isArray(tables)
                  ? tables.map(({ table_id, table_num }) => {
                      return (
                        <Button
                          size="large"
                          key={table_id}
                          onClick={() => {
                            Store.setModal({
                              center: true,
                              title:
                                intl.formatMessage({ id: "masa" }) +
                                " " +
                                table_num +
                                " " +
                                intl.formatMessage({ id: "duzenle" }),
                              child: (
                                <EditTable
                                  table_id={table_id}
                                  table_num={table_num}
                                  table_category_id={table_category_id}
                                  onChange={() => {
                                    getData();
                                  }}
                                />
                              ),
                            });
                          }}
                        >
                          {table_num}
                        </Button>
                      );
                    })
                  : null}
                <Button
                  icon={<PlusOutlined />}
                  size="large"
                  onClick={() => {
                    Store.setModal({
                      center: true,
                      title: <IntlMessages id="masaekle" />,
                      child: (
                        <EditTable
                          table_num={""}
                          table_category_id={table_category_id}
                          onChange={() => {
                            getData();
                          }}
                        />
                      ),
                    });
                  }}
                />
              </div>
            </Collapse.Panel>
          );
        })}
        <Collapse.Panel header={<IntlMessages id="ekle" />} key={0}>
          {editRender()}
        </Collapse.Panel>
      </Collapse>
    </Card>
  );
};

export default observer(Tables);
