import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { post } from "../../networking/Server";
import { Store } from "../../networking/stores/Store";
import { getCafe, getCafes } from "../../functions/getCafe";
import {
  Avatar,
  Button,
  Card,
  Form,
  Input,
  Popconfirm,
  Row,
  Col,
  Select,
  InputNumber,
} from "antd";
import IntlMessages from "../../util/IntlMessages";
import { priceText } from "../../functions/priceText";
import { EditFilled } from "@ant-design/icons";
import LoyaltyAdd from "../Loyaltys/add";
import DataTable from "../../functions/dataTable";
import { toParse } from "../../functions/json";
import { error, success } from "../../functions/toast";
import {
  Circle,
  GoogleMap,
  InfoWindow,
  withGoogleMap,
} from "react-google-maps";
import { useIntl } from "react-intl";
import { lang } from "../../functions/language";
import ProductAdd from "../Products/add";
import AddressAdd from "./add";

const CGoogleMap = withGoogleMap((props) => (
  <GoogleMap
    defaultZoom={15}
    defaultCenter={{ lat: 47.646935, lng: -122.303763 }}
  />
));

const OrderAddress = ({ history }) => {
  const [data, setData] = useState([]);

  const [rm_loading, setRMLoading] = useState(0);
  const [loading, setLoading] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    getAddress();
  }, []);

  function getAddress() {
    Store.setLoading(true);
    post("api/cafe/get_address", { cafe_id: getCafe() }).then((res) => {
      Store.setLoading(false);
      setRMLoading(0);

      if (res.result) {
        setData(res.address);
      }
    });
  }

  function onAdd(values) {
    setLoading(true);
    post("api/cafe/add_address", { cafe_id: getCafe(), ...values }).then(
      (res) => {
        setLoading(false);
        if (res.result) {
          success();
          getAddress();
        } else {
          if (res.error) {
            error(intl.formatMessage({ id: res.error }));
          } else {
            error();
          }
        }
      }
    );
  }

  function onRemove(cafe_address_id) {
    setRMLoading(cafe_address_id);
    post("api/cafe/remove_address", {
      cafe_id: getCafe(),
      cafe_address_id,
    }).then((res) => {
      if (res.result) {
        success();
        getAddress();
      } else {
        setRMLoading(0);
        if (res.error) {
          error(intl.formatMessage({ id: res.error }));
        } else {
          error();
        }
      }
    });
  }

  const columns = [
    {
      name: "district",
      label: lang(intl, "ulke"),
      options: {
        customBodyRender: ({ city }) => city?.country?.name,
      },
    },
    {
      name: "district",
      label: lang(intl, "sehir"),
      options: {
        customBodyRender: ({ city }) => city?.name,
      },
    },
    {
      name: "district",
      label: lang(intl, "ilce"),
      options: {
        customBodyRender: ({ name }) => name,
      },
    },
    {
      name: "min_price",
      label: lang(intl, "minimumtutar"),
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value) => priceText(value),
      },
    },
    {
      name: "minute",
      label: lang(intl, "yaklasiksure"),
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value) => {
          try {
            value = toParse(value);
            return (
              value[0] +
              " - " +
              value[1] +
              " " +
              intl.formatMessage({ id: "dakika" })
            );
          } catch (e) {}
          return "";
        },
      },
    },
    {
      name: "price",
      label: lang(intl, "servisucreti"),
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value) => priceText(value),
      },
    },
    {
      name: "cafe_address_id",
      label: " ",
      options: {
        sort: false,
        filter: false,
        searchable: false,
        customBodyRender: (cafe_address_id) => {
          return (
            <div>
              <Button
                type="primary"
                shape="circle"
                icon={<EditFilled />}
                onClick={() => {
                  Store.setModal({
                    title: "Güncelle",
                    child: (
                      <AddressAdd
                        cafe_address_id={cafe_address_id}
                        onChange={() => {
                          getAddress();
                        }}
                      />
                    ),
                  });
                }}
              />
            </div>
          );
        },
      },
    },
  ];

  return (
    <div>
      {/*<Card title={"Kurye Takip"}>
        <CGoogleMap
          loadingElement={<div style={{height: `100%`}}/>}
          containerElement={<div style={{height: `550px`}}/>}
          mapElement={<div style={{height: `100%`}}/>}
        />
      </Card>*/}
      <Button
        type="primary"
        onClick={() => {
          Store.setModal({
            title: <IntlMessages id={"ekle"} />,
            child: (
              <AddressAdd
                onChange={() => {
                  getAddress();
                }}
              />
            ),
          });
        }}
      >
        <IntlMessages id={"adresekle"} />
      </Button>
      <DataTable
        title={<IntlMessages id="adresler" />}
        data={data}
        columns={columns}
      />
    </div>
  );
};

export default observer(OrderAddress);
