import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { getUser, post } from "../../networking/Server";
import { getCafe, getCafes } from "../../functions/getCafe";
import {
  Input,
  Tag,
  InputNumber,
  Form,
  Spin,
  Button,
  Select,
  Divider,
  Popconfirm,
  Popover,
  Switch,
} from "antd";
import { Store } from "../../networking/stores/Store";
import Options from "../../components/Options";
import ImagePicker from "../../components/ImagePicker";
import { error, success } from "../../functions/toast";
import {
  Authorities,
  getAuthorities,
  getStaffTypeName,
  StaffTypes,
} from "../../constants/Config";
import IntlMessages from "../../util/IntlMessages";
import { useIntl } from "react-intl";
import { MStore } from "../../networking/stores/MainStore";

const StaffEdit = ({ user_id = 0, onChange = () => {} }) => {
  const intl = useIntl();
  const [data, setData] = useState({});
  const [staff_types, setStaffTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  let timer = null;

  const [loading_username, setLoadingUserName] = useState(false);
  const [username_ok, setUserNameOk] = useState(0);

  useEffect(() => {
    if (user_id) {
      getStaff(user_id);
    }
    getStaffTypes();
  }, []);

  function getStaff(user_id, set = false) {
    setLoading(true);
    post("api/staffs/get_one", { cafe_id: getCafe(), user_id }).then((res) => {
      setLoading(false);
      if (res.result) {
        if (set) {
          success();
          onChange(res.user);
          Store.setModal({});
        }
        try {
          res.user["authorities"] = JSON.parse(res.user["authorities"]);
          if (!res.user["authorities"]) throw "";
        } catch (e) {
          if (res.user) res.user["authorities"] = [];
        }
        setData(res.user);
      }
    });
  }

  function getStaffTypes() {
    post("api/staffs/get_staff_types", { cafe_id: getCafe() }).then((res) => {
      if (res.result) {
        setStaffTypes(res.staff_types);
      }
    });
  }

  function controlEmail(email) {
    setLoadingUserName(true);
    post("api/staffs/control_email", { email }).then((res) => {
      setLoadingUserName(false);
      if (res.result) {
        setUserNameOk(1);
      } else {
        setUserNameOk(2);
      }
    });
  }

  function writting(email) {
    if (!email) {
      setUserNameOk(0);
      setLoadingUserName(false);
    }
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      controlEmail(email);
    }, 500);
  }

  function onSet(values) {
    if (!user_id && username_ok != 2) {
      return;
    }

    try {
      values["authorities"] = JSON.stringify(values["authorities"]);
    } catch (e) {
      values["authorities"] = [];
    }
    values["email"] = getCafe() + "-" + values["email"];

    values = { ...data, ...values };
    setLoading(true);
    post("api/staffs/set", { cafe_id: getCafe(), data: values }).then((res) => {
      if (res.result) {
        getStaff(res.user_id, true);
      } else {
        setLoading(false);
        if (res.error) {
          error(intl.formatMessage({ id: res.error }));
        } else {
          error();
        }
      }
    });
  }

  function getAuthorityName(id) {
    try {
      return Authorities.filter((e) => e.id == id)[0]["type"];
    } catch (e) {}

    return "*";
  }

  if (!data["user_id"] && user_id) {
    return <Spin className="gx-loader-container" />;
  }

  let staff_type_id = "";
  try {
    staff_type_id = data["staffs"][0]["staff_type_id"];
  } catch (e) {}

  let authorities = [];
  try {
    authorities = getUser()["authorities"];
    if (!Array.isArray(authorities)) authorities = [];
  } catch (e) {}

  const disabled = MStore.user["user_id"] == user_id;

  return (
    <div>
      {!user_id ? (
        <p style={{ paddingVertical: 30 }}>
          *<IntlMessages id="personelekledesc" />
        </p>
      ) : null}

      <ImagePicker
        style={{ marginLeft: 20 }}
        onChange={(images) => {
          setData({ ...data, image: images[0] });
        }}
        images={user_id && data["image"] ? [data["image"]] : []}
        multiple
        accept={".png, .jpg, .jpeg"}
        maxCount={1}
        disabled={disabled}
      />
      <Form
        style={{ margin: 20 }}
        layout="vertical"
        name="validate_other"
        initialValues={data}
        onFinish={onSet}
      >
        <Form.Item
          name={"username"}
          label={<IntlMessages id="isimsoyisim" />}
          rules={[
            { required: true, message: <IntlMessages id={"gecersizbilgi"} /> },
          ]}
        >
          <Input maxLength={100} disabled={disabled} />
        </Form.Item>
        <Form.Item
          hasFeedback
          validateStatus={
            loading_username
              ? "validating"
              : username_ok == 1
              ? "error"
              : username_ok == 2
              ? "success"
              : ""
          }
          help={
            username_ok == 1 ? <IntlMessages id="kullaniciadizatenvar" /> : ""
          }
          name={"email"}
          label={<IntlMessages id={"kullaniciadi"} />}
          rules={[
            { required: true, message: <IntlMessages id={"gecersizbilgi"} /> },
          ]}
        >
          <Input
            addonBefore={user_id ? "" : getCafe() + "-"}
            disabled={user_id || disabled}
            maxLength={100}
            onChange={(target) => {
              writting(getCafe() + "-" + target.target.value);
            }}
          />
        </Form.Item>

        <Form.Item
          name={"staff_type_id"}
          label={<IntlMessages id={"gorev"} />}
          initialValue={staff_type_id}
          rules={[
            { required: true, message: <IntlMessages id={"gecersizbilgi"} /> },
          ]}
        >
          <Select
            loading={!Array.isArray(staff_types) || !staff_types.length}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            disabled={disabled}
          >
            {staff_types.map((id) => {
              return (
                <Select.Option key={id} value={id}>
                  <IntlMessages id={getStaffTypeName(id)} />
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name={"authorities"}
          label={<IntlMessages id={"yetkiler"} />}
          rules={[{ required: false }]}
        >
          <Select
            mode="multiple"
            tagRender={({ label }) => <Tag color="warning">{label}</Tag>}
            allowClear
            showArrow
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            disabled={disabled}
          >
            {authorities.map((id) => {
              return (
                <Select.Option key={id} value={id}>
                  <IntlMessages id={getAuthorityName(id)} />{" "}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item className=" gx-text-right gx-flex-row">
          {data["password"] ? (
            <Popconfirm
              title={<IntlMessages id="sifresifirladesc" />}
              onConfirm={() => {
                onSet({ ...data, password: "", staff_type_id });
              }}
            >
              <Button
                disabled={disabled}
                loading={loading}
                className="gx-mr-2"
                warning
              >
                <IntlMessages id="sifresifirla" />
              </Button>
            </Popconfirm>
          ) : null}
          {user_id ? (
            <Popconfirm
              title={<IntlMessages id="sifresifirlawarn" />}
              onConfirm={() => {
                onSet({
                  ...data,
                  email: data["email"] + "||",
                  status: 0,
                  staff_type_id,
                });
              }}
            >
              <Button
                disabled={disabled}
                loading={loading}
                className="gx-mr-2"
                danger
              >
                <IntlMessages id="sil" />
              </Button>
            </Popconfirm>
          ) : null}
          <Button
            disabled={disabled}
            loading={loading}
            className="gx-mr-2"
            type="primary"
            htmlType="submit"
          >
            <IntlMessages id="kaydet" />
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(StaffEdit);
