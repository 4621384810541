import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { post } from "../../networking/Server";
import { Store } from "../../networking/stores/Store";
import { getCafe } from "../../functions/getCafe";
import {
  Card,
  List,
  Button,
  Row,
  Col,
  Input,
  Spin,
  Form,
  Switch,
  InputNumber,
  Popover,
} from "antd";
import DataTable from "../../functions/dataTable";
import EditStock from "./edit";
import { priceText } from "../../functions/priceText";
import { getStockTypeName, StockTypes } from "../../constants/Config";
import { error, success } from "../../functions/toast";
import ProductStocks from "./product_stocks";
import IntlMessages from "../../util/IntlMessages";
import { useIntl } from "react-intl";
import { lang, languageText } from "../../functions/language";

const Stock = ({ history }) => {
  const intl = useIntl();
  const [stocks, setStocks] = useState([]);
  const [stock_loading, setStockLoading] = useState(false);
  const [settings, setSettings] = useState({ stock_limit: 20 });
  const [setting_loading, setSettingLoading] = useState(false);

  useEffect(() => {
    getStocks();
    getSettings();
  }, []);

  function getStocks() {
    setStockLoading(true);
    post("api/stocks/get", { cafe_id: getCafe() }).then((res) => {
      if (res.result) {
        setStocks(res.stocks);
      }
      setStockLoading(false);
    });
  }
  function getSettings() {
    post("api/settings/get", { cafe_id: getCafe() }).then((res) => {
      setSettingLoading(false);
      if (res.result && res.setting) {
        setSettings(res.setting);
      } else {
        setSettings({ ...settings, setting_id: -1 });
      }
    });
  }

  function setSetting(values) {
    setSettingLoading(true);
    post("api/settings/set", {
      cafe_id: getCafe(),
      data: { ...settings, ...values },
    }).then((res) => {
      getSettings();
      if (res.result) {
        success();
      } else {
        if (res.error) {
          error(intl.formatMessage({ id: res.error }));
        } else {
          error();
        }
      }
    });
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const columns = [
    {
      name: "name",
      label: lang(intl, "urunadi"),
      options: {
        filter: false,
        customBodyRender: (e) => languageText(e),
      },
    },
    {
      name: "added_stock",
      label: lang(intl, "stok"),
      options: {
        filter: false,
      },
    },
    {
      name: "stock",
      label: lang(intl, "kalan"),
      options: {
        filter: false,
      },
    },
    {
      name: "rate",
      label: lang(intl, "yuzdelik"),
      options: {
        filter: false,
      },
    },
    {
      name: "stock_type",
      label: " ",
      options: {
        searchable: false,
        sort: false,
        customBodyRender: (value) => {
          try {
            value = <IntlMessages id={getStockTypeName(value)} />;
          } catch (e) {}
          return value;
        },
      },
    },
  ];

  function calcRate(data) {
    try {
      data = data
        .map((item) => {
          const { added_stock, stock } = item;
          return {
            ...item,
            rate: "%" + parseFloat((stock * 100) / added_stock).toFixed(0),
          };
        })
        .sort((a, b) => (a.stock > b.stock ? 1 : -1));
    } catch (e) {}
    return data;
  }

  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Button
            type="primary"
            onClick={() => {
              Store.setModal({
                center: true,
                title: <IntlMessages id={"stokguncelle"} />,
                child: (
                  <EditStock
                    onChange={() => {
                      getStocks();
                    }}
                  />
                ),
              });
            }}
          >
            <IntlMessages id={"stokguncelle"} />
          </Button>
          <DataTable
            loading={stock_loading}
            title={<IntlMessages id={"stoklar"} />}
            data={calcRate(stocks)}
            columns={columns}
            options={{
              setRowProps: (row) => {
                let percent = row[3];
                let percent2 = settings["stock_limit"];
                percent = percent.replace("%", "");

                if (percent <= percent2)
                  return { style: { background: "#B71C1C50" } };
                return {};
              },
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Card
            style={{ marginTop: 50 }}
            title={<IntlMessages id={"stokayarlari"} />}
          >
            {settings["setting_id"] ? (
              <Form
                style={{ marginTop: 20 }}
                {...layout}
                name="validate_other"
                initialValues={settings}
                onFinish={setSetting}
              >
                <Form.Item
                  {...tailLayout}
                  label={<IntlMessages id={"stokbildirimleri"} />}
                  valuePropName={"checked"}
                  name={"stock_notification"}
                  rules={[{ required: false }]}
                >
                  <Switch checkedChildren={<IntlMessages id={"aktif"} />} />
                </Form.Item>

                <Popover
                  placement="bottom"
                  title={intl
                    .formatMessage({
                      id: "stokoranialtinadustugundebildirimgonder",
                    })
                    .replace("$$", "%" + settings["stock_limit"] ?? 20)}
                >
                  <Form.Item
                    {...tailLayout}
                    name={"stock_limit"}
                    label={<IntlMessages id={"stoksiniri"} />}
                    rules={[{ required: false }]}
                  >
                    <InputNumber
                      min={0}
                      max={90}
                      formatter={(value) => `${value}%`}
                      parser={(value) => value.replace("%", "")}
                      onChange={(value) => {
                        settings["stock_limit"] = value;
                        setSettings({ ...settings });
                      }}
                    />
                  </Form.Item>
                </Popover>

                <Form.Item
                  style={{
                    marginTop: 50,
                    position: "absolute",
                    bottom: 0,
                    right: 10,
                  }}
                >
                  <Button
                    loading={setting_loading}
                    className="gx-mr-2"
                    type="primary"
                    htmlType="submit"
                  >
                    <IntlMessages id={"kaydet"} />
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <Spin className="gx-loader-container" />
            )}
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: 40 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <ProductStocks />
        </Col>
      </Row>
    </div>
  );
};

export default observer(Stock);
