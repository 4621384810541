import antRu from "antd/lib/locale-provider/ru_RU";
import deMessages from "../locales/ru_RU.json";

const RuLang = {
  messages: {
    ...deMessages,
  },
  antd: antRu,
  locale: "ru-RU",
};
export default RuLang;
