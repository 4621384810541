import React, {useState} from "react";
import {Button, Spin} from "antd";
import {OrderTypes} from "../constants/Config";
import {Store} from "../networking/stores/Store";
import EditTable from "../routes/Tables/edit";
import {l_moment} from "../functions/l_moment";
const OrderView = ({order_type_id,data})=>{
  switch (order_type_id) {
    case OrderTypes.MasaSiparis.id:
      return OrderTable(data)
    case OrderTypes.QRSiparis.id:
      return OrderQR(data)
    case OrderTypes.AdreseSiparis.id:
      return OrderAddress(data)
    case OrderTypes.GelAl.id:
      return OrderCameTake(data)
  }

  return null
}

function OrderTable({order_tables}) {

  try {order_tables=order_tables[0]['table'];}catch (e) {order_tables={}}
  const {table_category:{name},table_num} = order_tables;

  return(
    <div >
      <h3>{name}</h3>
      <Button
        size="large"
      >
        {table_num}
      </Button>
    </div>
  )
}


function OrderQR({order_tables}) {

  try {order_tables=order_tables[0]['table'];}catch (e) {order_tables={}}
  if(!order_tables)
    order_tables={}

  const {table_category: {name},table_num} = order_tables;

  return(
    <div >
      <h3>{name}</h3>
      <Button
        size="large"
      >
        {table_num}
      </Button>
    </div>
  )
}

function OrderAddress({order_addresses}) {
  try {order_addresses=order_addresses[0]['customer_address'];}catch (e) {order_addresses={}}

  if(!order_addresses)
    order_addresses={}

  const {address,description,customer,city,district} = order_addresses;

  return(
    <div >
      <h3>{city?.name} / {district?.name}</h3>
      <div>{address}</div>
      <div>{description}</div>
      <br/>
      <h4>{customer?.name} {customer?.surname}</h4>
      <div>{customer?.phone}</div>
      <div>{customer?.email}</div>
    </div>
  )
}

function OrderCameTake({order_cametakes}) {
  try {order_cametakes=order_cametakes[0];}catch (e) {}
  if(!order_cametakes)
    order_cametakes={}
  const {date} = order_cametakes;

  return(
    <div >
      <h4>Gel Al Zamanı</h4>
      <p className="gx-text-grey gx-fs-md gx-mb-2">{l_moment(date).format("DD.MM.YYYY HH:mm")}</p>
    </div>
  )
}



export default OrderView

