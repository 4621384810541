import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { getURL } from "../networking/Server";
import IntlMessages from "../util/IntlMessages";
import ImgCrop from "antd-img-crop";

const ImagePicker = ({
  title = "resimler",
  disabled = false,
  style = { marginBottom: 15 },
  onChange = () => {},
  images = [],
  multiple = false,
  maxCount = 100,
  accept = ".png, .jpg, .jpeg",
  listType = "picture-card",
  crop,
  required = false,
}) => {
  images = clearData(images);
  const [fileList, setFileList] = useState(convertFileList(images));
  const [preview, setPreview] = useState({});

  function convertFileList(images) {
    return images.map((url, index) => ({ url, uid: index.toString() }));
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreview({
      image: file.url || file.preview,
      title: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  const _onChange = (info) => {
    let fileList = [...info.fileList];
    let urls = [];

    fileList.map((file, index) => {
      if (file.response) {
        file.url = file.response.path;
        urls.push(file.response.path);
      } else {
        urls.push(images[index]);
      }
      return file;
    });

    onChange(urls);

    /* if (Array.isArray(urls) && urls.length) {
      setTimeout(() => {
        setFileList([...convertFileList(urls)]);
      }, 300);
    } */

    setFileList([...info.fileList]);
  };

  /*useEffect(() => {
    if (
      JSON.stringify(fileList) !=
      JSON.stringify(convertFileList(clearData(images)))
    ) {
      setFileList(convertFileList(clearData(images)));
    }
  }, [images]);*/

  function clearData(data) {
    try {
      return data.filter((e) => !!e);
    } catch {}
    return [];
  }

  function uploadRender({
    disabled,
    accept,
    multiple,
    listType,
    fileList,
    handlePreview,
    _onChange,
    maxCount,
  }) {
    return (
      <Upload
        disabled={disabled}
        name={"file"}
        accept={accept}
        action={getURL() + "api/upload_file/set"}
        headers={{
          "x-access-token": "Bearer " + localStorage.getItem("token"),
        }}
        multiple={multiple}
        listType={listType}
        fileList={fileList}
        onPreview={handlePreview}
        onChange={_onChange}
      >
        {maxCount > fileList.length && !disabled ? (
          <div>
            <PlusOutlined />
            <IntlMessages style={{ marginTop: 6 }} id={"yukle"} />
          </div>
        ) : null}
      </Upload>
    );
  }

  return (
    <div style={style}>
      {title ? (
        <label style={{ marginBottom: 8, display: "block" }}>
          {required ? (
            <span style={{ color: "red", marginRight: 4 }}>*</span>
          ) : (
            <></>
          )}
          <IntlMessages
            style={{ fontSize: 15, fontWeight: "bold", marginBottom: 10 }}
            id={title}
          />
        </label>
      ) : null}

      {crop ? (
        <ImgCrop {...crop} modalTitle={<IntlMessages id="gorseliduzenle" />}>
          {uploadRender({
            disabled,
            accept,
            multiple,
            listType,
            fileList,
            handlePreview,
            _onChange,
            maxCount,
          })}
        </ImgCrop>
      ) : (
        uploadRender({
          disabled,
          accept,
          multiple,
          listType,
          fileList,
          handlePreview,
          _onChange,
          maxCount,
        })
      )}

      <Modal
        visible={preview.image}
        title={preview.title}
        footer={null}
        onCancel={() => {
          setPreview({});
        }}
      >
        <img alt="example" style={{ width: "100%" }} src={preview.image} />
      </Modal>
    </div>
  );
};
export default observer(ImagePicker);
