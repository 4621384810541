import React, { useState } from "react";
import { DatePicker, Select, Spin } from "antd";
import IntlMessages from "../util/IntlMessages";
const StaticticsFilter = ({ setDate, loading, date }) => {
  return (
    <div>
      <div className="gx-m-1">
        <IntlMessages id="zamanaraligi" />
      </div>

      <div className="gx-flex-row">
        <DatePicker.RangePicker
          {...(loading
            ? { suffixIcon: <Spin className="gx-m-0 gx-p-0" size="small" /> }
            : {})}
          value={[date["start"], date["end"]]}
          onChange={(val) => {
            console.warn(val);
            setDate({
              ...date,
              start: val[0].set({
                hour: 5,
                minute: 0,
                second: 0,
                millisecond: 0,
              }),
              end: val[1]
                .set({
                  hour: 4,
                  minute: 59,
                  second: 59,
                  millisecond: 0,
                })
                .add(1, "days"),
            });
          }}
        />
        <Select
          value={date["period"]}
          className="gx-select-lg"
          defaultValue="WEEK"
          onChange={(period) => {
            setDate({ ...date, period });
          }}
        >
          <Select.Option value="DAY">
            <IntlMessages id="gunluk" />
          </Select.Option>
          <Select.Option value="WEEK">
            <IntlMessages id="haftalik" />
          </Select.Option>
          <Select.Option value="MONTH">
            <IntlMessages id="aylik" />
          </Select.Option>
          <Select.Option value="YEAR">
            <IntlMessages id="yillik" />
          </Select.Option>
        </Select>
      </div>
    </div>
  );
};
export default StaticticsFilter;
