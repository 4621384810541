import React, { useEffect, useState } from "react";

import IntlMessages from "util/IntlMessages";
import { getUser, post } from "../../networking/Server";
import { observer } from "mobx-react-lite";
import IconWithTextCard from "../../components/Metrics/IconWithTextCard";
import { Row, Col, DatePicker, Select, Card, Avatar, Spin } from "antd";
import CartPie from "../../components/Charts/CartPie";
import CardLine from "../../components/Charts/CartLine";
import CardBar from "../../components/Charts/CartBar";
import LinesEllipsis from "react-lines-ellipsis";
import { getOrderTypeName, getPaymentTypeName } from "../../constants/Config";
import { getCafe } from "../../functions/getCafe";
import { l_moment } from "../../functions/l_moment";
import { toParse } from "../../functions/json";
import StaticticsFilter from "../../components/StaticticsFilter";
import Loading from "../../components/Loading";
import { useIntl } from "react-intl";
import { Store } from "../../networking/stores/Store";
import { MStore } from "../../networking/stores/MainStore";
import { lang, languageText } from "../../functions/language";

const Home = () => {
  const [date, setDate] = useState({
    start: l_moment().set({
      hour: 5,
      minute: 0,
      second: 0,
      millisecond: 0,
    }),
    end: l_moment()
      .set({
        hour: 4,
        minute: 59,
        second: 59,
        millisecond: 0,
      })
      .add(1, "days"),
    period: "DAY",
  });
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    counts: {},
    order_types: null,
    products: null,
    payments: null,
    products_5: null,
  });

  const intl = useIntl();

  useEffect(() => {
    getData();
  }, [date]);

  function getData() {
    setLoading(true);
    post("api/statistics/main", { date, _cafe_id: getCafe() }).then((res) => {
      setLoading(false);

      if (res.result) {
        setData(res.data);
      }
    });
  }

  const currency = getCafe("currency");

  const { counts, payment_types, products, payments, products_5 } = data;

  return (
    <div>
      <div className="ant-row-flex">
        <div>
          <h1>
            <IntlMessages id="gostergepaneli" />
          </h1>
          <p>
            <IntlMessages id="gostergepanelidesc" />
          </p>
        </div>
        <div className="gx-ml-auto gx-p-2 gx-card">
          <StaticticsFilter date={date} setDate={setDate} loading={loading} />
        </div>
      </div>
      <Row>
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <IconWithTextCard
            icon="diamond"
            title={counts["product"]}
            subTitle={<IntlMessages id="urun" />}
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <IconWithTextCard
            icon="tasks"
            title={counts["order"]}
            subTitle={<IntlMessages id="siparis" />}
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <IconWithTextCard
            icon="company"
            title={counts["business"]}
            subTitle={<IntlMessages id="isletme" />}
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <IconWithTextCard
            icon="team"
            title={counts["staff"]}
            subTitle={<IntlMessages id="calisan" />}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={12} lg={12} md={24} sm={24} xs={24}>
          <Card
            className="gx-card"
            title={
              getCafe("name") + " " + intl.formatMessage({ id: "siparisozeti" })
            }
          >
            <CartPie
              height={330}
              data={payment_types}
              unit={currency}
              format={({ name, value }) => {
                return {
                  name: lang(intl, getPaymentTypeName(name)),
                  value: Math.abs(parseFloat(value).toFixed(1)),
                };
              }}
              total_values={[1, 2, 4]}
              header={({ total = 0 }) => (
                <div
                  className="gx-m-1 gx-p-2 gx-flex-row gx-align-items-center gx-rounded-base"
                  style={{ backgroundColor: "#e7faec" }}
                >
                  <div
                    className="gx-flex-row gx-justify-content-center gx-align-items-center gx-bg-primary gx-text-white gx-fs-xl gx-font-weight-bold gx-fs-xl gx-rounded-base"
                    style={{ padding: "7px 20px 7px 20px" }}
                  >
                    {total + currency}
                  </div>
                  <div className="gx-fs-lg gx-ml-2 gx-font-weight-semi-bold">
                    <IntlMessages id="toplamgelir" />
                  </div>
                  <i className="icon icon-circle gx-fs-lg gx-m-2 gx-text-primary" />
                </div>
              )}
            />
          </Card>
        </Col>
        <Col xl={12} lg={12} md={24} sm={24} xs={24}>
          <Card
            className="gx-card"
            title={<IntlMessages id="urunsatisozeti" />}
          >
            <CardBar
              height={330}
              data={products}
              format={({ x, y }) => {
                return { y: languageText(y), x: parseFloat(x).toFixed(1) };
              }}
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card className="gx-card" title={<IntlMessages id="gelirozeti" />}>
            <CardLine
              data={payments}
              height={300}
              format={({ x, y }) => {
                return {
                  order: l_moment(y).format("YYYY-MM-DD HH:mm:ss"),
                  y: l_moment(y).format("MMM Do"),
                  x: parseFloat(x).toFixed(1),
                };
              }}
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card
            style={{ minHeight: 300 }}
            className="gx-card"
            title={<IntlMessages id="encoksatan5urun" />}
          >
            {Array.isArray(products_5) ? (
              !products_5.length ? (
                <div
                  style={{ height: "100%", width: "100%" }}
                  className="gx-flex-row gx-justify-content-center gx-align-items-center"
                >
                  <IntlMessages id="noistatis" />
                </div>
              ) : (
                <Row>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    {products_5.map(({ product, order, value }) => {
                      let image = "";
                      try {
                        image = toParse(product, "images")[0];
                      } catch (e) {}
                      return (
                        <div>
                          <div className="gx-media gx-task-list-item gx-flex-nowrap">
                            <Avatar
                              className="gx-mr-3 gx-size-36"
                              src={image}
                            />
                            <div className="gx-media-body gx-task-item-content">
                              <div className="gx-task-item-content-left">
                                <h5 className="gx-text-truncate gx-task-item-title">
                                  {languageText(product?.name)}
                                </h5>
                                <p className="gx-text-grey gx-fs-sm gx-mb-0">
                                  <span
                                    style={{ maxLines: 1 }}
                                    className="gx-link"
                                  >
                                    {order?.cafe?.name}
                                  </span>
                                  <LinesEllipsis
                                    text={languageText(product?.description)}
                                    maxLine="1"
                                    ellipsis="..."
                                    trimRight
                                    basedOn="letters"
                                  />
                                </p>
                              </div>
                              <div className="gx-task-item-content-right">
                                {value} <IntlMessages id="adet" />
                              </div>
                            </div>
                          </div>
                          <div className="gx-task-list-item gx-d-block gx-d-sm-none">
                            <h5 className="gx-text-primary gx-mb-0 gx-pointer" />
                          </div>
                        </div>
                      );
                    })}
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <CartPie
                      hideInfo
                      height={300}
                      data={products_5}
                      unit={<IntlMessages id="adet" />}
                      format={({ name, value }) => {
                        return {
                          name: languageText(name),
                          value,
                        };
                      }}
                    />
                  </Col>
                </Row>
              )
            ) : (
              <Loading height={300} />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default observer(Home);
