const languageData = [
  {
    languageId: "turkish",
    locale: "tr",
    lang: "en-TR",
    name: "Türkçe",
    icon: "tr",
  },
  {
    languageId: "english",
    locale: "en",
    lang: "en-US",
    name: "English",
    icon: "us",
  },
  {
    languageId: "germany",
    locale: "de",
    lang: "de-DE",
    name: "German",
    icon: "de",
  },
  {
    languageId: "china",
    locale: "zh-cn",
    name: "China",
    icon: "cn",
  },
  {
    languageId: "spanish",
    locale: "es",
    name: "Spanish",
    icon: "es",
  },
  {
    languageId: "french",
    locale: "fr",
    name: "French",
    icon: "fr",
  },
  {
    languageId: "italian",
    locale: "it",
    name: "Italian",
    icon: "it",
  },
  {
    languageId: "arabic",
    locale: "ar",
    name: "Arabic",
    icon: "ar",
  },
  {
    languageId: "russian",
    locale: "ru",
    name: "Russian",
    icon: "ru",
  },
];
export default languageData;
