import React, { useEffect, useRef, useState } from "react";

import { observer } from "mobx-react-lite";
import { post } from "../../networking/Server";
import { getCafe, } from "../../functions/getCafe";
import {
  Tag, Avatar, Tree, message, Button, Popover,
} from "antd";
import { useIntl } from "react-intl";
import { toJSON, toParse } from "../../functions/json";
import {
  lang,
  languageText,
} from "../../functions/language";
import {DownOutlined, DragOutlined} from "@ant-design/icons";
import {onDrop} from "../../functions/treeDrop";
import Loading from "../../components/Loading";
import {error} from "../../functions/toast";
import {Store} from "../../networking/stores/Store";
import IntlMessages from "../../util/IntlMessages";

const ChangeOrder = ({
  onChange = () => {}
}) => {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const intl = useIntl();


  useEffect(() => {
    getProducts();
  }, []);

  function getProducts(hide=()=>{}) {
    setLoading(true)
    post("api/products/get-order", { cafe_id: getCafe() }).then(
      (res) => {
        hide()
        setLoading(false)
        if (res.result && res.products) {
          convertCategories(res.products)
        }
      }
    );
  }

  function convertCategories(categories) {
    let _cat = [];
    try {
      _cat = JSON.parse(
        JSON.stringify(categories)
          .replaceAll("product_id", "key")
          .replaceAll("name", "title")
          .replaceAll("images", "image")
      );


      _cat.map((item, index) => {
        _cat[index] = item;
      });
    } catch (e) {}
    setData(_cat);
  }

  function setProduct(products,auto_order=null) {
    const hide = message.loading(lang(intl,"lutfenbekleyin")+"...", 0);

    products = JSON.parse(
      JSON.stringify(products)
        .replaceAll("key", "product_id")
        .replaceAll("title", "name")
    );
    setLoading(true);
    post("api/products/set-product-order", { cafe_id: getCafe(),products,auto_order }).then((res) => {
      getProducts(hide);
      if (res.result) {

        onChange();
      } else {
        if (res.error) {
          error(intl.formatMessage({ id: res.error }));
        } else {
          error();
        }
        setLoading(false);
      }
    });
  }

  if (!Array.isArray(data) || !data.length) {
    return <Loading />;
  }

  return (
    <div>
      <div style={{padding:30}}>
        <Popover
          content={<div>
            <Button
              onClick={() => {
                setProduct([],["name","desc"])
              }}
            >
              <IntlMessages id={"buyuktenkucuge"} />
            </Button>
            <Button
              onClick={() => {
                setProduct([],["name","asc"])

              }}
            >
              <IntlMessages id={"kucuktenbuyuge"} />
            </Button>
          </div>}
          trigger="click"
        >
          <Button
            type="primary"
          >
            <IntlMessages id={"isimegoresirala"} />
          </Button>
        </Popover>
        <Popover
          content={<div>
            <Button
              onClick={() => {
                setProduct([],["product_id","asc"])
              }}
            >
              <IntlMessages id={"buyuktenkucuge"} />
            </Button>
            <Button
              onClick={() => {
                setProduct([],["product_id","desc"])
              }}
            >
              <IntlMessages id={"kucuktenbuyuge"} />
            </Button>
          </div>}
          trigger="click"
        >
          <Button
            type="primary"
          >
            <IntlMessages id={"eklenmetarihinegoresirala"} />
          </Button>
        </Popover>

      </div>
      <Tree
        disabled={loading}
        selectable={false}
        style={{ fontSize: 15 }}
        className="draggable-tree"
        draggable
        blockNode
        switcherIcon={
          <DownOutlined style={{ fontSize: 15, marginTop: 14 }} />
        }
        treeData={data}
        onDrop={(info) => {
          let new_data = onDrop(info, data);
          setData([...new_data]);
          setProduct(new_data);
        }}
        titleRender={({key, title, image }) => {
          title = languageText(title);
          try {

            image = toParse(image)[0]
          }catch (e) {
            image = ""
          }

          return (
            <Tag
              style={{userSelect:'none',msUserSelect:'none', width:'90%', fontSize: 14, padding:5, margin: 5 }}
              visible
              icon={<Avatar size="large" style={{userSelect:'none',msUserSelect:'none',marginRight:10}} src={image}/>}
            >

              {(title ? title : "Bu dilde ürün yok")}
            </Tag>
          );
        }}
      />
    </div>
  );
};

export default observer(ChangeOrder);
