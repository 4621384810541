import React, { useEffect, useRef, useState } from "react";

import { observer } from "mobx-react-lite";
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Switch,
} from "antd";
import IntlMessages from "../../util/IntlMessages";
import MultiLanguage, {
  defaultMenuLanguage,
} from "../../components/MultiLanguage";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import ImagePicker from "../../components/ImagePicker";
import { getCafe } from "../../functions/getCafe";
import { encodingLangFormat, translate } from "../../functions/language";
import { useIntl } from "react-intl";
import { error } from "../../functions/toast";

const ProductAddOptions = ({
  onAdd,
  option,
  index,
  onRemove,
  onClose = () => {},
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lang_loading, setLangLoading] = useState(false);

  const intl = useIntl();
  const form = useRef();
  const currenct = getCafe("currency");
  const multi_keys = ["title"];

  const default_option = {
    multiple: false,
    require: true,
    options: [{ name: "", price: 0, selected: false }],
  };

  useEffect(() => {
    if (option) {
      option["options"] = option["options"].filter(
        (e) => e.name != "no-choose"
      );
      form.current.setFieldsValue(option);
    } else form.current.resetFields();
  }, [option]);

  function onSet(values) {
    if (!values["multiple"] && !values["require"]) {
      values["options"] = [
        { name: "no-choose", price: 0 },
        ...values["options"],
      ];
    }

    onAdd(values, index);
    onClose();
  }

  const d_lang = defaultMenuLanguage(intl.locale);

  return (
    <div>
      <Form
        ref={form}
        style={{ margin: 20 }}
        layout="vertical"
        name="validate_other"
        initialValues={default_option}
        onFinish={onSet}
        onValuesChange={(_, values) => {
          setData({ ...values });
        }}
        onFinishFailed={() => {
          error(
            "Lütfen tüm eksik alanları doldurunuz. Farklı diller içinde alanları kontrol etmeyi unutmayınız."
          );
        }}
      >
        <MultiLanguage
          loading={lang_loading}
          onLoad={(from, to) => {
            setLangLoading(true);

            translate(intl, data, to, multi_keys).then(async (e) => {
              let new_options = [];
              for (const opt of data["options"]) {
                new_options.push({
                  ...opt,
                  ...(await translate(intl, opt, to, ["name"])),
                });
              }

              e["options"] = new_options;

              setLangLoading(false);
              form.current.setFieldsValue(e);
            });
          }}
        >
          {(lang) => (
            <div>
              <Form.Item
                name={["title", lang]}
                label={"Başlık"}
                rules={
                  d_lang == lang
                    ? [
                        {
                          required: true,
                          message: <IntlMessages id={"gecersizbilgi"} />,
                        },
                      ]
                    : []
                }
              >
                <Input maxLength={100} />
              </Form.Item>
              <Form.Item
                name={"multiple"}
                label={"Çoklu Seçim"}
                rules={
                  d_lang == lang
                    ? [
                        {
                          required: true,
                          message: <IntlMessages id={"gecersizbilgi"} />,
                        },
                      ]
                    : []
                }
                valuePropName={"checked"}
              >
                <Switch
                  checkedChildren={<IntlMessages id={"evet"} />}
                  unCheckedChildren={<IntlMessages id={"hayir"} />}
                />
              </Form.Item>
              <Form.Item
                name={"require"}
                label={"Seçim Yapılması Zorunlu"}
                rules={
                  d_lang == lang
                    ? [
                        {
                          required: true,
                          message: <IntlMessages id={"gecersizbilgi"} />,
                        },
                      ]
                    : []
                }
                valuePropName={"checked"}
              >
                <Switch
                  checkedChildren={<IntlMessages id={"evet"} />}
                  unCheckedChildren={<IntlMessages id={"hayir"} />}
                />
              </Form.Item>

              <Divider
                style={{ marginTop: 40, marginBottom: 15 }}
                orientation="left"
              >
                Özellik Listesi
              </Divider>

              <Form.List name={"options"} label={"Seçenekler"}>
                {(fields, { add, remove }) => (
                  <div
                    style={{
                      paddingLeft: 15,
                    }}
                  >
                    <div className="gx-text-center gx-flex-row gx-justify-content-center">
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          add(default_option.options[0], 0);
                        }}
                      >
                        Ekle
                      </Button>
                    </div>
                    {fields.map((field, i) => (
                      <div>
                        {fields.length > 1 ? (
                          <div className="gx-text-center gx-flex-row gx-justify-content-end">
                            <Button
                              shape="circle"
                              type="ghost"
                              icon={<DeleteOutlined />}
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          </div>
                        ) : null}
                        <Form.Item
                          name={[field.name, "name", lang]}
                          label={"Özellik İsmi"}
                          rules={
                            d_lang == lang
                              ? [
                                  {
                                    required: true,
                                    message: (
                                      <IntlMessages id={"gecersizbilgi"} />
                                    ),
                                  },
                                ]
                              : []
                          }
                        >
                          <Input max={100} />
                        </Form.Item>
                        <Form.Item
                          style={{ marginTop: 0, paddingTop: 0 }}
                          name={[field.name, "price"]}
                          label={"Fiyat"}
                          rules={
                            d_lang == lang
                              ? [
                                  {
                                    required: true,
                                    message: (
                                      <IntlMessages id={"gecersizbilgi"} />
                                    ),
                                  },
                                ]
                              : []
                          }
                        >
                          <InputNumber
                            min={0}
                            formatter={(value) =>
                              `${currenct} ${value}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )
                            }
                            parser={(value) =>
                              value.replace(
                                new RegExp(currenct + "s?|(,*)", "g"),
                                ""
                              )
                            }
                          />
                        </Form.Item>

                        <Divider
                          dashed
                          style={{
                            borderTop: "3px solid rgba(0, 0, 0, 0.1)",
                            padding: 10,
                            paddingTop: 30,
                          }}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </Form.List>
            </div>
          )}
        </MultiLanguage>
        <Form.Item className=" gx-text-right gx-flex-row">
          {onRemove && option && index != undefined ? (
            <Popconfirm
              title={<IntlMessages id={"opsiyonsilmeuyari"} />}
              onConfirm={() => {
                onRemove(index);
                onClose();
              }}
            >
              <Button loading={loading} className="gx-mr-2" danger>
                <IntlMessages id={"sil"} />
              </Button>
            </Popconfirm>
          ) : null}
          <Button
            loading={loading}
            className="gx-mr-2"
            type="primary"
            htmlType="submit"
          >
            <IntlMessages id={"kaydet"} />
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(ProductAddOptions);
