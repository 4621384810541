import React, { useEffect, useState } from "react";
import "../../assets/category.css";
import { observer } from "mobx-react-lite";
import { post } from "../../networking/Server";
import { getCafe } from "../../functions/getCafe";
import {Spin, Form, Input, Tag, Popconfirm, Tree, Tabs, Avatar} from "antd";
import {
  PlusOutlined,
  DownOutlined,
  CloseOutlined,
  LineOutlined,
} from "@ant-design/icons";
import { onDrop } from "../../functions/treeDrop";
import { useIntl } from "react-intl";
import IntlMessages from "../../util/IntlMessages";
import { error } from "../../functions/toast";
import { menuLanguages } from "../../constants/Config";
import MultiLanguage, {
  defaultMenuLanguage,
} from "../../components/MultiLanguage";
import {
  decodingLangFormat,
  encodingLangFormat,
  languageText,
  translate,
} from "../../functions/language";
import {Store} from "../../networking/stores/Store";
import CategoryEdit from "./edit";

const Categories = ({ onChange }) => {
  const intl = useIntl();
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lang_loading, setLangLoading] = useState(false);

  const d_lang = defaultMenuLanguage(intl.locale);

  useEffect(() => {
    getCategories();
  }, []);

  function getCategories() {
    post("api/products/get_categories", { cafe_id: getCafe() }).then((res) => {
      setLoading(false);
      if (res.result) {
        convertCategories(res.categories);
      }
    });
  }
  function convertCategories(categories) {
    let _cat = [];
    try {
      _cat = JSON.parse(
        JSON.stringify(categories)
          .replaceAll("category_id", "key")
          .replaceAll("name", "title")
          .replaceAll("subcategory", "children")
          .replaceAll("image", "image")
      );


      _cat.map((item, index) => {
        let children = item["children"];
        if (Array.isArray(children)) {
          item["children"].push({
            disabled: true,
            key: item["key"] * -1,
            image: item["image"],
            title:
              item["title"] +
              " " +
              intl.formatMessage({ id: "altkategoriekle" }),
          });
        }
        _cat[index] = item;
      });
      _cat.push({
        className: "gx-pointer",
        disabled: true,
        key: 0,
        title: <IntlMessages id={"kategoriekle"} />,
      });
    } catch (e) {}
    setData(_cat);
  }

  function editCat(cat_id, new_cat) {
    let new_data = data;
    let index = data.findIndex(({ key }) => key == cat_id);
    if (index != -1) {
      new_data[index] = new_cat;
    } else {
      new_data = data.map((item) => {
        if (Array.isArray(item["children"])) {
          let c_index = item["children"].findIndex(({ key }) => key == cat_id);
          if (c_index != -1) {
            item["children"][c_index] = new_cat;
          }
        }
        return item;
      });
    }
    setData([...new_data]);
  }

  function setCat(categories) {
    categories = JSON.parse(
      JSON.stringify(categories)
        .replaceAll("key", "category_id")
        .replaceAll("title", "name")
        .replaceAll("children", "subcategory")
    );
    setLoading(true);
    post("api/products/set_categories", {
      cafe_id: getCafe(),
      categories,
    }).then((res) => {
      if (res.result) {
        getCategories();
        onChange();
      } else {
        getCategories();
        if (res.error) {
          error(intl.formatMessage({ id: res.error }));
        } else {
          error();
        }
        setLoading(false);
      }
    });
  }

  async function translateCategory(data, from, to) {
    let new_data = [];

    for (const item of data) {
      const { key, title, children } = item;
      if (key < 1) continue;
      let langs = decodingLangFormat({ title }, ["title"])["title"];
      langs = (await translate(intl, { title: langs }, to, ["title"]))["title"];

      let new_children = [];

      for (const item2 of children) {
        const { key, title } = item2;
        if (key < 1) continue;
        let langs = decodingLangFormat({ title }, ["title"])["title"];
        langs = (await translate(intl, { title: langs }, to, ["title"]))[
          "title"
        ];

        new_children.push({
          ...item2,
          title: encodingLangFormat({ langs }, ["langs"])["langs"],
        });
      }

      new_data.push({
        ...item,
        title: encodingLangFormat({ langs }, ["langs"])["langs"],
        children: new_children,
      });
    }

    return new_data;

    /*setLangLoading(true)
    translate(intl, { input }, to, ["input"]).then(
      (e) => {
        setLangLoading(false)
      }
    );*/
  }

  if (!Array.isArray(data) || !data.length) {
    return <Spin className="gx-loader-container" />;
  }
  return (
    <div>
      <Form>
        <div style={{ opacity: loading ? 1 : 0 }} className="gx-flex-row">
          <Spin />
          <IntlMessages id={"lutfenbekleyin"} className="gx-m-1" />
        </div>
        <MultiLanguage
          loading={lang_loading}
          onLoad={async (from, to) => {
            setLangLoading(true);
            let new_data = await translateCategory(data, from, to);
            setCat(new_data);
            setLangLoading(false);
          }}
        >
          {(lang) => (
            <Tree
              disabled={loading}
              selectable={false}
              style={{ fontSize: 15 }}
              className="draggable-tree"
              draggable
              blockNode
              switcherIcon={
                <DownOutlined style={{ fontSize: 15, marginTop: 14 }} />
              }
              treeData={data}
              onDrop={(info) => {
                let new_data = onDrop(info, data);
                setData([...new_data]);
                setCat(new_data);
              }}
              titleRender={({key, title, image, children }) => {
                title = languageText(title, lang);

                if (key < 1) {
                  return (
                    <Tag
                      onClick={() => {
                        Store.setModal2({
                          title: <IntlMessages id={"ekle"} />,
                          child: (
                            <CategoryEdit
                              category_id={null}
                              onChange={({image,name})=>{
                                setLoading(true);
                                editCat(key, {
                                  key,
                                  title:name,
                                  image,
                                  children,
                                  add: true,
                                });
                                setCat(data);
                              }}
                            />
                          ),
                        });
                      }}
                      style={{ fontSize: 14, padding: 8, margin: 5 }}
                      color="warning"
                      className="site-tag-plus"
                    >
                      <PlusOutlined /> {title}
                    </Tag>
                  );
                }

                return (
                  <Tag
                    onClick={() => {
                      Store.setModal2({
                        title: <IntlMessages id={"duzenle"} />,
                        child: (
                          <CategoryEdit
                            category_id={key}
                            onChange={({image,name,status})=>{
                              setLoading(true);
                              if(status==0){
                                editCat(key, {
                                  key,
                                  title:name,
                                  image,
                                  children,
                                  remove:true
                                });
                              }else{
                                editCat(key, {
                                  key,
                                  title:name,
                                  image,
                                  children
                                });
                              }


                              setCat(data);
                            }}
                          />
                        ),
                      });
                    }}
                    style={{width:'90%', fontSize: 14, padding:5, margin: 5 }}
                    visible
                    icon={<Avatar size="large" style={{marginRight:10}} src={image}/>}
                  >

                    {(title ? title : "Bu dilde kategori yok")}
                  </Tag>
                );
              }}
            />
          )}
        </MultiLanguage>
      </Form>
    </div>
  );
};

export default observer(Categories);
