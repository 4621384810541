import React, { useEffect, useRef, useState } from "react";

import { observer } from "mobx-react-lite";
import { getURL, post } from "../../networking/Server";
import { getCafe, getCafes } from "../../functions/getCafe";
import {
  Input,
  TreeSelect,
  InputNumber,
  Form,
  Spin,
  Button,
  Select,
  Checkbox,
  Popconfirm,
  Popover,
  Switch,
  Row,
  Col,
  Divider,
} from "antd";
import { Store } from "../../networking/stores/Store";
import Options from "../../components/Options";
import ImagePicker from "../../components/ImagePicker";
import { error, success } from "../../functions/toast";
import IntlMessages from "../../util/IntlMessages";
import { useIntl } from "react-intl";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { io } from "socket.io-client";

const config = {
  jsonp: false,
  reconnection: true,
  reconnectionDelay: 100,
  reconnectionAttempts: 100000,
  transports: ["websocket"],
};

let socket;

const PromoAdd = ({ promo_id = 0, onChange = () => {} }) => {
  const [data, setData] = useState({});
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const form = useRef();
  const intl = useIntl();

  useEffect(() => {
    if (promo_id) {
      getPromo(promo_id);

      socket = io(getURL(), {
        ...config,
        query: { token: localStorage.getItem("token") },
      });
    }
  }, []);

  function getPromo(promo_id, set = false) {
    post("api/promos/get_one", { cafe_id: getCafe(), promo_id }).then((res) => {
      setLoading(false);
      if (res.result) {
        setData(res.promo);
      }
    });
  }

  function onSet(values) {
    setLoading(true);
    post("api/promos/set", {
      cafe_id: getCafe(),
      data: { sliders: values.sliders, promo_id },
    }).then((res) => {
      if (res.result) {
        socket.emit("promo-reload", promo_id);

        success();
        onChange();
        Store.setModal({});
      } else {
        setLoading(false);
        if (res.error) {
          error(intl.formatMessage({ id: res.error }));
        } else {
          error();
        }
      }
    });
  }

  if (!data["promo_id"] && promo_id) {
    return <Spin className="gx-loader-container" />;
  }

  return (
    <div>
      <Form
        ref={form}
        {...{
          labelCol: {
            xs: { span: 24 },
            sm: { span: 12 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
          },
        }}
        style={{ margin: 20 }}
        layout="horizontal"
        name="validate_other"
        initialValues={data}
        onFinish={onSet}
      >
        <Form.List name={"sliders"}>
          {(fields, { add, remove }) => (
            <div style={{ width: "100%" }}>
              {fields.map((field, i) => (
                <div>
                  <div className="gx-text-center gx-flex-row gx-justify-content-end">
                    <Button
                      shape="circle"
                      type="ghost"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  </div>
                  <Form.Item
                    name={[field.name, "url"]}
                    label={<IntlMessages id={"resimler"} />}
                    valuePropName={"images"}
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id={"gecersizbilgi"} />,
                      },
                    ]}
                  >
                    <ImagePicker
                      title={""}
                      maxCount={4}
                      accept={".png, .jpg, .jpeg, .mp4, .avi"}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ marginTop: 0, paddingTop: 0 }}
                    name={[field.name, "delay"]}
                    label={<IntlMessages id={"beklemesuresi"} />}
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id={"gecersizbilgi"} />,
                      },
                    ]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>

                  <Divider
                    dashed
                    style={{
                      borderTop: "3px solid rgba(0, 0, 0, 0.1)",
                      padding: 10,
                      paddingTop: 30,
                    }}
                  />
                </div>
              ))}

              <div className="gx-text-center gx-flex-row gx-justify-content-center">
                <Button type="primary" icon={<PlusOutlined />} onClick={add}>
                  Ekle
                </Button>
              </div>
            </div>
          )}
        </Form.List>

        <Form.Item className=" gx-text-right gx-flex-row gx-justify-content-end">
          {promo_id ? (
            <Popconfirm
              title={<IntlMessages id={"urunsilmeuyari"} />}
              onConfirm={() => {
                onSet({ status: 0 });
              }}
            >
              <Button loading={loading} className="gx-mr-2" danger>
                <IntlMessages id={"sil"} />
              </Button>
            </Popconfirm>
          ) : null}
          <Button
            loading={loading}
            className="gx-mr-2"
            type="primary"
            htmlType="submit"
          >
            <IntlMessages id={"kaydet"} />
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(PromoAdd);
