import { message } from "antd";

export function success(title = "Başarılı") {
  message.success(title);
}
export function error(title = "Bir Sorun Oluştu") {
  message.error(title);
}
export function info(title = "") {
  message.info(title);
}
