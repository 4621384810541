import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { post } from "../../networking/Server";
import { Store } from "../../networking/stores/Store";
import { getCafe } from "../../functions/getCafe";
import { Button, Card, Carousel, Image, Row } from "antd";
import EditTable from "../Tables/edit";
import {
  FullscreenOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import IntlMessages from "../../util/IntlMessages";
import { useIntl } from "react-intl";
import { toJSON } from "../../functions/json";
import Categories from "../Categories";
import PromoAdd from "./add";

const Ads = ({ history }) => {
  const [data, setData] = useState([]);

  const intl = useIntl();

  useEffect(() => {
    getPromos();
  }, []);

  function getPromos() {
    Store.setLoading(true);
    post("api/promos/get", { cafe_id: getCafe() }).then((res) => {
      Store.setLoading(false);
      if (res.result) {
        setData(res.promos);
      }
    });
  }

  return (
    <div>
      <Card title={"Ekranlar"}>
        <div className="gx-flex-row gx-ml-4">
          {Array.isArray(data)
            ? data.map(({ promo_id, sliders }, index) => {
                return (
                  <div>
                    <Button
                      style={{ position: "absolute", zIndex: 1000 }}
                      type="primary"
                      icon={<EditOutlined />}
                      onClick={() => {
                        Store.setModal({
                          title: <IntlMessages id={"ekle"} />,
                          child: (
                            <PromoAdd
                              promo_id={promo_id}
                              onChange={() => {
                                getPromos();
                              }}
                            />
                          ),
                        });
                      }}
                    />
                    <div
                      onClick={() => {
                        sliders[0].url.map((e, i) => {
                          window.open(
                            "http://localhost:3000/promo/" + promo_id + "/" + i,
                            "_blank"
                          );
                        });
                      }}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Carousel
                        key={index}
                        style={{
                          margin: 15,
                          marginTop: 10,
                          width: 250,
                          height: 150,
                          cursor: "pointer",
                        }}
                        autoplay
                      >
                        {Array.isArray(sliders)
                          ? sliders.map(({ url }) => (
                              <div>
                                <Image
                                  preview={false}
                                  style={{ borderRadius: 8 }}
                                  width={250}
                                  height={150}
                                  src={url[0]}
                                />
                              </div>
                            ))
                          : null}
                      </Carousel>
                    </div>
                  </div>
                );
              })
            : null}
          <Button
            icon={<PlusOutlined style={{ fontSize: 30 }} />}
            size="large"
            style={{ margin: 15, marginTop: 10, width: 250, height: 150 }}
            onClick={() => {
              Store.setModal({
                title: <IntlMessages id={"ekle"} />,
                child: (
                  <PromoAdd
                    onChange={() => {
                      getPromos();
                    }}
                  />
                ),
              });
            }}
          />
        </div>
      </Card>
    </div>
  );
};

export default observer(Ads);
