import React from "react";

import { observer } from "mobx-react-lite";
import { getCafe } from "../../functions/getCafe";
import { Button, Card } from "antd";

const QRCode = require("qrcode.react");

const SurveyQR = () => {
  const downloadQR = (id) => {
    const canvas = document.getElementById(id);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = id + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const menu_qr_code =
    "https://qranket.cyberistanbul.com/surveys/" +
    window.btoa(getCafe("cafe_id"));

  return (
    <div>
      <Card title={"Anket QR Kod"}>
        <p>
          Bu qr kod ile müşterileriniz işletmenize ait anketi uygulama
          indirmeden görüntüleyebilir.
          <br /> Bu qr codu magnetlerde, kartlarınızda, afişlerde, masalarınızda
          veya reklamlarınızda kullanabilirsiniz.
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <QRCode
            id="menu-qr-code"
            size={300}
            level={"H"}
            value={menu_qr_code}
            includeMargin={true}
          />
          <div
            style={{
              margin: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{ margin: 0, padding: 0 }}
              type="link"
              onClick={() => {
                window.open(menu_qr_code, "_blank");
              }}
            >
              Ankete Git
            </Button>
            <Button
              style={{ margin: 0, padding: 0 }}
              type="link"
              onClick={() => {
                downloadQR("menu-qr-code");
              }}
            >
              İndir
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default observer(SurveyQR);
