import trLang from "./entries/tr-TR";
import enLang from "./entries/en-US";
import zhLang from "./entries/zh-Hans-CN";
import arLang from "./entries/ar_SA";
import itLang from "./entries/it_IT";
import esLang from "./entries/es_ES";
import frLang from "./entries/fr_FR";
import deLang from "./entries/de_DE";
import ruLang from "./entries/ru_RU";

const AppLocale = {
  tr: trLang,
  en: enLang,
  "zh-cn": zhLang,
  ar: arLang,
  it: itLang,
  es: esLang,
  fr: frLang,
  de: deLang,
  ru: ruLang,
};

export default AppLocale;
