import React, { useEffect, useState } from "react";
import { Avatar, Button, Layout, Popover, Select } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import { switchLanguage, toggleCollapsedSideNav } from "../../appRedux/actions";
import SearchBox from "../../components/SearchBox";
import UserInfo from "../../components/UserInfo";
import AppNotification from "../../components/AppNotification";
import MailNotification from "../../components/MailNotification";
import Auxiliary from "util/Auxiliary";
import { FullscreenOutlined } from "@ant-design/icons";

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import { getCafe, getCafes } from "../../functions/getCafe";
import { observer } from "mobx-react";
import { MStore } from "../../networking/stores/MainStore";

const { Header } = Layout;

const Topbar = ({ webview = false }) => {
  const { locale, navStyle } = useSelector(({ settings }) => settings);
  const { navCollapsed, width } = useSelector(({ common }) => common);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map((language) => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={() => {
              dispatch(switchLanguage(language));
              MStore.setLanguage(language);
              localStorage.setItem("language", JSON.stringify(language));
            }}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  const cafeMenu = () => (
    <CustomScrollbars
      style={{ minWidth: 250 }}
      className="gx-popover-lang-scroll"
    >
      <ul className="gx-sub-popover">
        {getCafes().map(({ image, name, cafe_id }) => (
          <li
            className="gx-flex-row  gx-align-items-center  gx-media gx-pointer"
            key={cafe_id}
            onClick={() => {
              try {
                MStore.setCafe(
                  getCafes().filter((e) => e.cafe_id == cafe_id)[0]
                );
                window.location.reload(false);
              } catch (e) {}
            }}
          >
            <Avatar src={image} className={""} alt="" />
            {name}
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  const updateSearchChatUser = (evt) => {
    setSearchText(evt.target.value);
  };
  let elem = document.documentElement;
  function openFullScreen() {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  }
  const user = MStore.user;
  return (
    <Header>
      {!webview &&
      (navStyle === NAV_STYLE_DRAWER ||
        ((navStyle === NAV_STYLE_FIXED ||
          navStyle === NAV_STYLE_MINI_SIDEBAR) &&
          width < TAB_SIZE)) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}

      {width >= TAB_SIZE ? (
        <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img alt="" width={25} src={require("assets/images/icon2.png")} />
            <div style={{ margin: 15 }}>Cyberİstanbul POS</div>
          </div>
        </Link>
      ) : null}

      <ul className="gx-header-notifications gx-ml-auto">
        <li className="gx-language">
          <Popover
            overlayClassName="gx-popover-horizantal"
            placement="bottomRight"
            content={cafeMenu()}
            trigger="click"
          >
            <span className="gx-pointer gx-flex-row gx-align-items-center">
              <Avatar src={getCafe("image")} className={""} alt="" />
              <span style={{ fontSize: 13 }} className="gx-pl-2 ">
                {getCafe("name")}
              </span>
              <i className="icon icon-chevron-down gx-pl-2" />
            </span>
          </Popover>
        </li>

        {!webview ? (
          <li className="gx-language">
            <Popover
              overlayClassName="gx-popover-horizantal"
              placement="bottomRight"
              content={languageMenu()}
              trigger="click"
            >
              <span className="gx-pointer gx-flex-row gx-align-items-center">
                <i className={`flag flag-24 flag-${MStore.language.icon}`} />
                <span className="gx-pl-2 gx-language-name">
                  {MStore.language.name}
                </span>
                <i className="icon icon-chevron-down gx-pl-2" />
              </span>
            </Popover>
          </li>
        ) : null}

        {!webview && width >= TAB_SIZE ? (
          <li style={{ marginTop: 10 }}>
            <Button
              type="primary"
              icon={<FullscreenOutlined />}
              onClick={openFullScreen}
            />
          </li>
        ) : null}
      </ul>
    </Header>
  );
};

export default observer(Topbar);
