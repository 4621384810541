import React, { useEffect, useState } from "react";

import { post } from "../../networking/Server";
import { observer } from "mobx-react-lite";
import { Avatar, Divider, Spin } from "antd";
import {
  getOrderStatusName,
  getOrderTypeName,
  getStaffTypeName,
} from "../../constants/Config";
import { priceText } from "../../functions/priceText";
import { l_moment } from "../../functions/l_moment";
import { toParse } from "../../functions/json";
import Options from "../../components/Options";
import OrderView from "../../components/OrderView";
import IntlMessages from "../../util/IntlMessages";
import { lang, languageText } from "../../functions/language";
import { useIntl } from "react-intl";

const OrderDetail = ({ order_id }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const intl = useIntl();

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    setLoading(true);
    post("api/statistics/order", { order_id }).then((res) => {
      setLoading(false);
      if (res.result) {
        setData(res.order);
      }
    });
  }

  if (!data["order_id"]) {
    return <Spin className="gx-loader-container" />;
  }

  let {
    cafe,
    price,
    order_type_id,
    orderAt,
    order_follows,
    order_products,
    customer,
    online_payment,
    service_payments,
  } = data;
  let last_order_follows = {};

  try {
    last_order_follows = order_follows[order_follows.length - 1];
  } catch (e) {
    last_order_follows = {};
  }
  if (!last_order_follows) last_order_follows = {};

  let service_price = 0;
  try {
    service_price = service_payments[0]["price"];
  } catch (e) {
    service_price = 0;
  }

  return (
    <div>
      <div className="gx-text-right">
        <div
          style={{
            fontWeight: "bold",
            color: getOrderStatusName(
              last_order_follows["order_status_id"],
              "color"
            ),
          }}
        >
          <IntlMessages
            id={getOrderStatusName(last_order_follows["order_status_id"])}
          />
        </div>
      </div>
      <div className="gx-flex-row gx-justify-content-between">
        <h2>{cafe["name"]}</h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <h2>{priceText(price, cafe["currency"])}</h2>
          {service_price ? (
            <h4>
              {lang(intl, "servisucreti")} +
              {priceText(service_price, cafe["currency"])}
            </h4>
          ) : null}
        </div>
        <h3 className="gx-text-primary">
          {online_payment ? lang(intl, "onlineodeme") : ""}
        </h3>
      </div>
      <p>{l_moment(orderAt).format("DD.MM.YYYY HH:mm")}</p>

      {customer ? (
        <div>
          <Divider className="gx-mt-5" dashed orientation="left">
            <IntlMessages id="musteri" />
          </Divider>
          <div className="gx-media gx-task-list-item gx-flex-nowrap">
            <Avatar className=" gx-size-36" src={customer?.image} />
            <div
              className="gx-media-body gx-task-item-content"
              style={{ width: "50%" }}
            >
              <div className="gx-task-item-content-left">
                <h5 className="gx-text-truncate gx-task-item-title">
                  {customer?.name + " " + customer?.surname}
                </h5>
                <p className="gx-text-grey gx-fs-sm gx-mb-2">
                  {customer?.email}
                </p>
              </div>
              <div className="gx-task-item-content-right">
                <div style={{ fontWeight: "bold", color: "red" }}>
                  {customer?.phone}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div>
        <Divider className="gx-mt-5" dashed orientation="left">
          <IntlMessages id={getOrderTypeName(order_type_id)} />
        </Divider>
        <OrderView order_type_id={order_type_id} data={data} />
      </div>

      <Divider className="gx-mt-5" dashed orientation="left">
        <IntlMessages id="urunler" />
      </Divider>
      <div>
        {Array.isArray(order_products)
          ? order_products.map(
              ({ product, count, options, price, note }, index) => {
                let { name, images, category } = product;
                name = languageText(name);
                images = toParse(images);
                images.length ? (images = images[0]) : (images = "");

                return (
                  <div
                    key={index}
                    className="gx-media gx-task-list-item gx-flex-nowrap"
                  >
                    <Avatar className="gx-size-36" src={images} />
                    <div className="gx-media-body gx-task-item-content">
                      <div className="gx-task-item-content-left gx-w-100">
                        <h5 className="gx-text-truncate gx-task-item-title">
                          {name} X {count}
                        </h5>
                        <p className="gx-text-grey gx-fs-sm gx-mb-2">
                          {languageText(category["name"])}
                        </p>
                        <p className="gx-flex-row gx-text-grey gx-fs-sm gx-mb-2">
                          <div className="gx-mr-1">
                            <IntlMessages id="opsiyonlar" />:
                          </div>
                          <Options disabled options={options} />
                        </p>
                        {note ? (
                          <p className="gx-flex-row gx-text-grey gx-fs-sm gx-mb-2">
                            <div className="gx-mr-1">
                              Not:{" "}
                              <span className="gx-text-secondary">{note}</span>
                            </div>
                          </p>
                        ) : null}
                      </div>
                      <div className="gx-task-item-content-right">
                        {priceText(price, cafe["currency"])}
                      </div>
                    </div>
                  </div>
                );
              }
            )
          : null}
      </div>

      <Divider className="gx-mt-5" dashed orientation="left">
        <IntlMessages id="islemyapanpersoneller" />
      </Divider>
      <div>
        {Array.isArray(order_follows)
          ? order_follows
              .reverse()
              .map(({ user, order_status_id, createdAt }, index) => {
                if (!user) user = { image: "", username: "MÜŞTERİ" };
                let { image, username, staffs } = user;
                let staff_type_id = 0;
                try {
                  staff_type_id = staffs[0]["staff_type_id"];
                } catch (e) {}

                return (
                  <div
                    key={index}
                    className="gx-media gx-task-list-item gx-flex-nowrap"
                  >
                    <Avatar className="gx-size-36" src={image} />
                    <div className="gx-media-body gx-task-item-content">
                      <div className="gx-task-item-content-left">
                        <h5 className="gx-text-truncate gx-task-item-title">
                          {username} (
                          {staff_type_id ? (
                            getStaffTypeName(staff_type_id)
                          ) : (
                            <IntlMessages id="qrsiparis" />
                          )}
                          )
                        </h5>
                        <p className="gx-text-grey gx-fs-sm gx-mb-2">
                          {l_moment(createdAt).format("DD.MM.YYYY HH:mm")}
                        </p>
                      </div>
                      <div className="gx-task-item-content-right">
                        <div
                          style={{
                            fontWeight: "bold",
                            color: getOrderStatusName(order_status_id, "color"),
                          }}
                        >
                          <IntlMessages
                            id={getOrderStatusName(order_status_id)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
          : null}
      </div>
    </div>
  );
};

export default observer(OrderDetail);
