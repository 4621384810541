import React from "react";
import { Store } from "../networking/stores/Store";

export const VERSION = "1.0.0";
export const API_VERSION = "1.0.0";

export const menuLanguages = [
  { lang: "en-TR", name: "turkce" },
  { lang: "en-US", name: "ingilizce" },
  { lang: "de-DE", name: "almanca" },
];

export function getAuthorities() {
  return Authorities.filter((e) => e.edit);
}

export const Authorities = [
  {
    id: [16],
    type: "istatistik",
    path: "dashboard",
    icon: "dasbhoard",
    nav: true,
    edit: true,
    sub: [
      {
        id: [16],
        type: "anket",
        path: "statics/survey",
        page: import("../routes/Statistics/survey"),
      },
    ],
  },
  {
    id: [4],
    type: "istatistik",
    path: "dashboard",
    icon: "dasbhoard",
    nav: true,
    edit: true,
    sub: [
      {
        id: [1, 2, 3, 5, 6, 7],
        type: "genel",
        path: "dashboard",
        page: import("../routes/Home"),
      },
      {
        id: 1,
        type: "siparis",
        path: "statics/orders",
        page: import("../routes/Statistics/orders"),
      },
      {
        id: [11, 16],
        type: "anket",
        path: "statics/survey",
        page: import("../routes/Statistics/survey"),
      },
      {
        id: 2,
        type: "stok",
        path: "statics/stock",
        page: import("../routes/Statistics/stock"),
      },
    ],
  },
  {
    id: 1,
    type: "urunler",
    path: "product/list",
    icon: "burger",
    nav: true,
    edit: true,
    page: import("../routes/Products"),
  },
  {
    id: 1,
    type: "urunekle",
    path: "product/add",
    icon: "burger",
    page: import("../routes/Products/add"),
  },
  {
    id: 1,
    type: "kategoriler",
    path: "categories/list",
    icon: "burger",
    page: import("../routes/Categories"),
  },

  {
    id: 2,
    type: "stok",
    path: "stock/list",
    icon: "inbox",
    nav: true,
    edit: true,
    page: import("../routes/Stock"),
  },

  {
    id: 3,
    type: "personeller",
    path: "staff/list",
    icon: "user",
    nav: true,
    edit: true,
    page: import("../routes/Staffs"),
  },
  {
    id: 3,
    type: "personeller",
    path: "staff/edit",
    icon: "user",
    page: import("../routes/Staffs/edit"),
  },

  {
    id: 5,
    type: "kafeler",
    path: "cafe/list",
    icon: "home",
    nav: true,
    edit: true,
    page: import("../routes/Cafe"),
  },

  {
    id: 6,
    type: "masalar",
    path: "table/list",
    icon: "table-general",
    nav: true,
    edit: true,
    page: import("../routes/Tables"),
  },
  {
    id: 6,
    type: "masalar",
    path: "table/edit",
    icon: "table-general",
    page: import("../routes/Tables/edit"),
  },

  {
    id: 7,
    type: "siparisler",
    path: "order/list",
    icon: "orders",
    nav: true,
    edit: true,
    page: import("../routes/Orders"),
  },

  {
    id: 8,
    type: "rezervasyonlar",
    path: "reservation/list",
    icon: "timepicker",
    nav: true,
    edit: true,
    page: import("../routes/OrderReservations"),
  },

  //{id:9, type:"gelal", path:"gelal/list", icon:"queries" ,nav:true, edit:true, page:import('../routes/OrderCameTake')},

  {
    id: 10,
    type: "adresesiparis",
    path: "address_order/list",
    icon: "map-drawing",
    nav: true,
    edit: true,
    page: import("../routes/OrderAddress"),
  },

  {
    id: 11,
    type: "anket",
    path: "survey/list",
    icon: "feedback",
    nav: true,
    edit: true,
    page: import("../routes/Surveys"),
  },

  {
    id: 12,
    type: "reklam",
    path: "reklam/list",
    icon: "diamond",
    nav: true,
    edit: true,
    page: import("../routes/Ads"),
  },
  {
    id: 13,
    type: "qruygulama",
    path: "qr_app/list",
    icon: "icon",
    nav: true,
    edit: true,
    page: import("../routes/OrderQR"),
  },
  {
    id: 13,
    type: "musteriler",
    path: "musteriler/list",
    icon: "user",
    nav: true,
    edit: true,
    page: import("../routes/Customers"),
  },
  {
    id: [13, 14],
    type: "kampanyalar",
    path: "campaigns/list",
    icon: "alert",
    nav: true,
    edit: true,
    page: import("../routes/Campaigns"),
  },
  {
    id: 13,
    type: "sadakat",
    path: "product/loyalty",
    icon: "star",
    nav: true,
    edit: true,
    page: import("../routes/Loyaltys"),
  },

  {
    id: 14,
    type: "qrwebayarlar",
    path: "qrmenu/basic",
    icon: "setting",
    nav: true,
    edit: true,
    page: import("../routes/QrWeb"),
  },
  {
    id: 15,
    type: "dijitalmenuayarlar",
    path: "dijitalmenu/basic",
    icon: "setting",
    nav: true,
    edit: true,
    page: import("../routes/DijitalMenu"),
  },
  {
    id: 18,
    type: "aracyonetimi",
    path: "vale",
    icon: "chart-composed",
    nav: true,
    edit: true,
    page: import("../routes/Vale"),
  },
  {
    id: 19,
    type: "bildirimler",
    path: "notifications",
    icon: "map-event-listener",
    nav: true,
    edit: true,
    page: import("../routes/Notifications"),
  },
];

export const StaffTypes = {
  Kasiyer: { id: 1, value: "kasiyer" },
  Garson: { id: 2, value: "garson" },
  Mutfak: { id: 3, value: "mutfak" },
  Menu: { id: 4, value: "menu" },
  LuksMenu: { id: 5, value: "luksmenu" },
  Market: { id: 6, value: "market" },
  Kurye: { id: 7, value: "kurye" },
  Vale: { id: 8, value: "vale" },
};
export const OrderStatuses = {
  YeniSiparis: { id: 1, value: "yenisiparis", color: "#FBC02D" },
  Hazirlaniyor: { id: 2, value: "hazirlaniyor", color: "#F57C00" },
  Hazir: { id: 3, value: "hazir", color: "#009688" },
  Tamamlandi: { id: 4, value: "tamamlandi", color: "#1E88E5" },
  Odendi: { id: 5, value: "odendi", color: "#4CAF50" },
  Iptal: { id: 6, value: "iptal", color: "#E64A19" },
  Iade: { id: 7, value: "iade", color: "#E64A19" },
  Yolda: { id: 8, value: "yolda", color: "#4CAF50" },
};
export const OrderTypes = {
  Siparis: { id: 1, value: "siparis" },
  MasaSiparis: { id: 2, value: "masasiparisi" },
  QRSiparis: { id: 3, value: "qrsiparis" },
  GelAl: { id: 4, value: "gelal" },
  Rezervasyon: { id: 5, value: "rezervasyon" },
  YemekliRezervasyon: { id: 6, value: "yemeklirezervasyon" },
  AdreseSiparis: { id: 7, value: "adresesiparis" },
  YemekSepeti: { id: 8, value: "yemeksepeti" },
  GetirYemek: { id: 9, value: "getiryemek" },
};
export const PaymentTypes = {
  Nakit: { id: 1, value: "nakit" },
  KrediKarti: { id: 2, value: "kredikarti" },
  Indirim: { id: 3, value: "indirim" },
  ParaUstu: { id: 4, value: "paraustu" },
  Iptal: { id: 5, value: "iptal" },
};
export const StockTypes = {
  Porsiyon: { id: 1, value: "porsiyon" },
  Adet: { id: 2, value: "adet" },
  Gram: { id: 3, value: "gram" },
  Kilo: { id: 4, value: "kilo" },
};

export function getStockTypeName(id) {
  let res = " ";
  try {
    res = Object.values(StockTypes).filter((e) => e.id == id)[0]["value"];
  } catch (e) {}
  return res;
}
export function getPaymentTypeName(id) {
  let res = " ";
  try {
    res = Object.values(PaymentTypes).filter((e) => e.id == id)[0]["value"];
  } catch (e) {}
  return res;
}
export function getOrderTypeName(id, key = "value") {
  let res = " ";
  try {
    res = Object.values(OrderTypes).filter((e) => e.id == id)[0][key];
  } catch (e) {}
  return res;
}
export function getOrderStatusName(id, key = "value") {
  let res = " ";
  try {
    res = Object.values(OrderStatuses).filter((e) => e.id == id)[0][key];
  } catch (e) {}
  return res;
}
export function getStaffTypeName(id, key = "value") {
  let res = " ";
  try {
    res = Object.values(StaffTypes).filter((e) => e.id == id)[0][key];
  } catch (e) {}
  return res;
}
