import firebase from "firebase";

import { post } from "../networking/Server";
import { info, success } from "../functions/toast";

const firebaseConfig = {
  apiKey: "AIzaSyA4EIei2ZnGrZyWvbJpSWDH2ApUjpy-PzQ",
  authDomain: "cafepanel.firebaseapp.com",
  projectId: "cafepanel",
  storageBucket: "cafepanel.appspot.com",
  messagingSenderId: "45211538555",
  appId: "1:45211538555:web:54daf493f025d24c877ede",
  measurementId: "G-KWXPG4WQ5P"
};

firebase.initializeApp(firebaseConfig);

export function getNotificationToken() {
  if (!firebase.messaging.isSupported()) return;

  const messaging = firebase.messaging();

  messaging
    .requestPermission()
    .then(() => {
      return messaging.getToken();
    })
    .then((token) => {
      console.warn("token:*****",token)
      post("api/user/set-token", { notfy_token:token }, { alert: false }).then(() => {});
    })
    .catch((err) => {
      console.error(err);
    });

  messaging.onMessage((payload) => {
    console.warn(payload)
    try {
      info(payload.notification.title, payload.notification.body);
    } catch (e) {}
  });
}

export default firebase;
