import React, { useEffect, useState } from "react";
import { Button, Form, Input, message } from "antd";
import IntlMessages from "util/IntlMessages";
import { MailFilled, UserOutlined } from "@ant-design/icons";
import { post } from "../../networking/Server";
import { useIntl } from "react-intl";
import { success, error } from "../../functions/toast";

const CreateStaff = ({ history }) => {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  const onLogin = (values) => {
    setLoading(true);
    post("staff_register", values, false).then((res) => {
      setLoading(false);

      if (res.result) {
        success();
        history.push("/")
        window.open(res.url,"_blank")
      } else {
        error(intl.formatMessage({ id: res.error }));
      }
    });
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content gx-align-items-center gx-align-self-center">
            <div className="gx-app-logo-wid">
              <div
                style={{
                  height: 120,
                  width: 120,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 1000,
                  backgroundColor: "#f0f0f0",
                }}
              >
                <img
                  height={80}
                  width={80}
                  alt="example"
                  src={require("assets/images/ci-logo2.png")}
                />
              </div>
            </div>
          </div>
          <div className="gx-app-login-content">
            <h1
              className="gx-mt-4 gx-text-center"
              style={{ fontWeight: 500 }}
            >
              CyberPos <IntlMessages id="personelekle" />
            </h1>
            <p className="gx-mb-5 gx-text-center">
              <IntlMessages id="personelolusturmadesc" />
            </p>
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onLogin}
              className="gx-signin-form gx-form-row0 gx-text-center"
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "gecersizbilgi" }),
                  },
                ]}
                name="email"
              >
                <Input
                  allowClear
                  bordered={false}
                  prefix={<UserOutlined className="gx-text-secondary" />}
                  style={{ backgroundColor: "#f5f5f5", borderRadius: 20 }}
                  placeholder={intl.formatMessage({ id: "kullaniciadi" })}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  loading={loading}
                  style={{ width: "100%", borderRadius: 20 }}
                  type="primary"
                  className="gx-mb-0"
                  htmlType="submit"
                >
                  <IntlMessages id="devamet" />
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateStaff;
