import React, {useEffect, useState} from "react";
import {Button, Checkbox, Form, Input, message} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import {MailFilled, LockFilled} from "@ant-design/icons";
import {post} from "../../networking/Server";
import {useIntl} from "react-intl";
import {userSignIn} from "../../appRedux/actions";
import {success,error} from "../../functions/toast";
import ReactCountdownClock from "react-countdown-clock";
import {l_moment} from "../../functions/l_moment";



const Password = ({history,match:{params:{token}}}) => {


  const intl = useIntl();

  const [loading,setLoading] = useState(true)
  const [second,setSecond] = useState(0)


  useEffect(() => {
    controlToken()
  }, []);

  function controlToken() {
    post("control_token",{token}).then((res)=>{
      if(res.result){
        setLoading(false)
        setSecond(res.data['second'])
      }else{
        error(intl.formatMessage({id:res.error}))
        history.push('/');
      }
    })
  }

  function onChange({password,repassword}) {


    if(!password.trim() || password.trim()!=repassword.trim()){
      error(intl.formatMessage({id:"sifreuyusmuyor"}))
      return;
    }
    setLoading(true)
    post("change_password",{password,token}).then((res)=>{
      setLoading(false)
      if(res.result){
        success()
        localStorage.removeItem("token");
        localStorage.removeItem("lock")
        window.location.href = "/signin";
      }else{
        error()
      }
    })
  }


  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content gx-align-items-center gx-align-self-center">
            <div className="gx-app-logo-wid">
              <ReactCountdownClock
                seconds={second<0?0:second}
                color={"#31c149"}
                alpha={.9}
                size={120}
                onComplete={()=>{
                  if(!second || second<0)
                    return ;
                  history.push('/');
                  error("Zaman Aşımına Uğradı")
                }}
              />
            </div>
          </div>
          <div className="gx-app-login-content">
            <h1 className="gx-mb-5 gx-mt-4 gx-text-center" style={{fontWeight:500}}>CyberPos <IntlMessages id="sifrebelirleme"/></h1>
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onChange}
              className="gx-signin-form gx-form-row0 gx-text-center"
            >
              <Form.Item
                rules= {[{
                  required: true,
                  pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                  message: intl.formatMessage({id:"uygunsuzsifre"})},
                  ]}  name="password">
                <Input type="password" allowClear bordered={false} prefix={<LockFilled className="gx-text-secondary"/>} style={{backgroundColor:'#f5f5f5', borderRadius:20}} placeholder={intl.formatMessage({id:"sifre"})}/>
              </Form.Item>
              <Form.Item
                rules= {[{required: true, message: intl.formatMessage({id:"gecersizbilgi"})}]}  name="repassword">
                <Input type="password" allowClear bordered={false} prefix={<LockFilled className="gx-text-secondary"/>} style={{backgroundColor:'#f5f5f5', borderRadius:20}} placeholder={intl.formatMessage({id:"sifretekrar"})}/>
              </Form.Item>

              <Form.Item>
                <Button loading={loading} style={{width:'100%',borderRadius:20}} type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="olustur"/>
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Password;
