import React, { useEffect, useState } from "react";

import IntlMessages from "util/IntlMessages";
import { getUser, post } from "../../networking/Server";
import { Store } from "../../networking/stores/Store";
import { observer } from "mobx-react-lite";
import { MStore } from "../../networking/stores/MainStore";
import IconWithTextCard from "../../components/Metrics/IconWithTextCard";
import {
  Row,
  Col,
  DatePicker,
  Select,
  Card,
  Avatar,
  Spin,
  Tag,
  Button,
} from "antd";
import CartPie from "../../components/Charts/CartPie";
import CardLine from "../../components/Charts/CartLine";
import { getCafe } from "../../functions/getCafe";
import { l_moment } from "../../functions/l_moment";
import StaticticsFilter from "../../components/StaticticsFilter";
import DataTable from "../../functions/dataTable";
import { EyeOutlined } from "@ant-design/icons";
import OrderDetail from "./order_detail";
import { useIntl } from "react-intl";
import { lang } from "../../functions/language";

const SurveyStatistics = () => {
  const [date, setDate] = useState({
    start: l_moment().set({
      hour: 5,
      minute: 0,
      second: 0,
      millisecond: 0,
    }),
    end: l_moment()
      .set({
        hour: 4,
        minute: 59,
        second: 59,
        millisecond: 0,
      })
      .add(1, "days"),
    period: "DAY",
  });
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({ orders: [] });

  const intl = useIntl();

  useEffect(() => {
    getData();
  }, [date]);

  function getData() {
    setLoading(true);
    post("api/statistics/rates", { date, _cafe_id: getCafe() }).then((res) => {
      setLoading(false);
      if (res.result) {
        setData(res.data);
      }
    });
  }

  const { feedbacks, answers, answer_status, answer_counts } = data;

  const columns = [
    {
      name: "rate_answer_id",
      label: "ID",
      options: {
        filter: false,
      },
    },
    {
      name: "rate",
      label: lang(intl, "soru"),
      options: {
        filter: true,
        searchable: false,
        customBodyRender: ({ question }) => question,
      },
    },
    {
      name: "rate_option",
      label: lang(intl, "cevap"),
      options: {
        filter: true,
        searchable: false,
        customBodyRender: ({ option }) => option,
      },
    },
    {
      name: "rate",
      label: lang(intl, "ankettipi"),
      options: {
        filter: true,
        searchable: false,
        customBodyRender: ({ rate_type }) =>
          rate_type == 1 ? (
            <IntlMessages id={"emojianket"} />
          ) : rate_type == 2 ? (
            <IntlMessages id={"seceneklianket"} />
          ) : (
            ""
          ),
      },
    },
    {
      name: "createdAt",
      label: lang(intl, "anketzamani"),
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value) => l_moment(value).format("DD/MM/YYYY HH:mm"),
      },
    },
    {
      name: "order_id",
      label: " ",
      options: {
        sort: false,
        filter: false,
        searchable: false,
        customBodyRender: (order_id) => {
          if (!order_id) return;
          return (
            <div>
              <Button
                type="primary"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => {
                  Store.setModal({
                    title: "Detay",
                    child: <OrderDetail order_id={order_id} />,
                  });
                }}
              />
            </div>
          );
        },
      },
    },
  ];

  const feedback_columns = [
    {
      name: "feedback_id",
      label: "ID",
      options: {
        filter: false,
      },
    },
    {
      name: "text",
      label: lang(intl, "geridonus"),
      options: {
        filter: false,
        searchable: true,
      },
    },
    {
      name: "createdAt",
      label: lang(intl, "anketzamani"),
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value) => l_moment(value).format("DD/MM/YYYY HH:mm"),
      },
    },
  ];

  return (
    <div>
      <div className="ant-row-flex">
        <div>
          <h1>
            <span
              className="gx-p-1 gx-text-white gx-bg-primary"
              style={{ borderRadius: 10 }}
            >
              {getCafe("name")}
            </span>{" "}
            <IntlMessages id={"anketistatistikleri"} />
          </h1>
          <p>
            <IntlMessages id={"anketistatistikleridesc"} />
          </p>
        </div>
        <div className="gx-ml-auto gx-p-2 gx-card">
          <StaticticsFilter date={date} setDate={setDate} loading={loading} />
        </div>
      </div>

      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card
            style={{ height: 460 }}
            className="gx-card"
            title={<IntlMessages id={"emojilianketozeti"} />}
          >
            <CardLine
              removeY
              area
              data={answer_counts}
              height={370}
              format={({ x, y }) => {
                return {
                  order: l_moment(y).format("YYYY-MM-DD HH:mm:ss"),
                  y: l_moment(y).format("MMM Do"),
                  x: parseInt(x),
                };
              }}
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card
            className="gx-card"
            style={{ paddingBottom: 50 }}
            title={<IntlMessages id={"secmelianketozeti"} />}
          >
            <CartPie
              height={330}
              data={answer_status}
              unit={intl.formatMessage({ id: "kisi" })}
              header={({ total = 0 }) => (
                <div
                  className="gx-m-1 gx-p-2 gx-flex-row gx-align-items-center gx-rounded-base"
                  style={{ backgroundColor: "#e7faec" }}
                >
                  <div
                    className="gx-flex-row gx-justify-content-center gx-align-items-center gx-bg-primary gx-text-white gx-fs-xl gx-font-weight-bold gx-fs-xl gx-rounded-base"
                    style={{ padding: "7px 20px 7px 20px" }}
                  >
                    {total}
                  </div>
                  <div className="gx-fs-lg gx-ml-2 gx-font-weight-semi-bold">
                    {<IntlMessages id={"toplamoylama"} />}
                  </div>
                  <i className="icon icon-circle gx-fs-lg gx-m-2 gx-text-primary" />
                </div>
              )}
            />
          </Card>
        </Col>
      </Row>

      <Row className="gx-mb-5">
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <DataTable
            loading={loading}
            title={<IntlMessages id={"geridonusler"} />}
            data={feedbacks}
            columns={feedback_columns}
          />
        </Col>
      </Row>

      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <DataTable
            loading={loading}
            title={<IntlMessages id={"cevaplar"} />}
            data={answers}
            columns={columns}
          />
        </Col>
      </Row>
    </div>
  );
};

export default observer(SurveyStatistics);
