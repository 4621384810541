import React, { useState } from "react";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { createColor } from "../../functions/createColor";
import { Spin } from "antd";
import IntlMessages from "../../util/IntlMessages";

const CardBar = ({ data, height, format = (e) => e }) => {
  const [disableds, setDisableds] = useState([]);

  function onDisable(key) {
    let index = disableds.findIndex((e) => e == key);
    if (index == -1) {
      disableds.push(key);
    } else {
      disableds.splice(index, 1);
    }

    setDisableds([...disableds]);
  }

  function createData(_data) {
    const dates = [];
    const data = [];

    try {
      _data = _data.map((item) => {
        let data = item.data;
        data = data.map((item2) => {
          return format(item2);
        });
        return { ...item, data };
      });

      _data.forEach((e) => {
        e.data.forEach((k) => {
          if (!dates.includes(k.y)) {
            dates.push(k.y);
          }
        });
      });

      dates.forEach((e) => {
        let _keys = {};
        _data.forEach((k) => {
          let findedData = k.data.find((t) => format(t).y === e);
          if (findedData) {
            _keys[k.name] = findedData.x;
            _keys["y"] = findedData.y;
            _keys = { ..._keys, ...findedData };
          } else {
            //_keys[k.name] = 0;
          }
        });
        data.push(_keys);
      });

      data.sort((a, b) => {
        a = a.order ? a.order : a.y;
        b = b.order ? b.order : b.y;

        return a > b ? 1 : -1;
      });
    } catch (e) {}

    return data;
  }

  if (!Array.isArray(data)) {
    return (
      <div
        style={{ height, width: "100%" }}
        className="gx-flex-row gx-justify-content-center gx-align-items-center"
      >
        <Spin />
      </div>
    );
  }

  let new_data = createData(data);

  if (!new_data.length) {
    return (
      <div
        style={{ height, width: "100%" }}
        className="gx-flex-row gx-justify-content-center gx-align-items-center"
      >
        <IntlMessages id="noistatis" />
      </div>
    );
  }

  return (
    <div>
      <ResponsiveContainer width="100%" height={height}>
        <BarChart
          data={new_data}
          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
        >
          <XAxis dataKey="y" />
          {/* <YAxis/>*/}
          <Tooltip separator={": "} />
          <Legend
            align="left"
            iconType="rect"
            onClick={({ dataKey }) => {
              onDisable(dataKey);
            }}
          />
          {data.map(({ name, unit, color }) => {
            let color1 = createColor(name),
              color2 = createColor(name);
            if (color) {
              color1 = color;
              color2 = color;
            }
            return (
              <Bar
                hide={disableds.includes(name)}
                connectNulls
                color={"red"}
                unit={unit}
                type="monotone"
                dataKey={name}
                fillOpacity={1}
                stroke={color1}
                fill={color2}
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CardBar;
