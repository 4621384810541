import { Store } from "../networking/stores/Store";
import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Checkbox, Drawer, Modal, Select } from "antd";
import { priceText } from "../functions/priceText";
import { getCafe } from "../functions/getCafe";
import IntlMessages from "../util/IntlMessages";
import { useIntl } from "react-intl";
import { languageText } from "../functions/language";
import ProductAddOptions from "../routes/Products/add_options";
import { EditFilled } from "@ant-design/icons";
import ProductAdd from "../routes/Products/add";

export function getSelected(data, key = "name") {
  let selected;
  try {
    let options = data["options"];
    selected = options
      .filter((e) => !!e.selected)
      .map((e) => (key ? e[key] : e));

    if (!data.multiple) {
      selected = selected[0];
    }
  } catch (e) {}

  try {
    if (!selected && data.multiple) {
      selected = [];
    } else if (!selected && !data.multiple) {
      selected = "";
    }
  } catch (e) {
    selected = "";
  }

  return selected;
}

const Options = ({ options, onAdd, onRemove, disabled }) => {
  const [modal, setModal] = useState(false);

  const intl = useIntl();
  const currency = getCafe("currency");

  if (!Array.isArray(options)) options = [];

  if (disabled) {
    return (
      <div>
        {options.map(({ title, options, multiple }, index) => {
          title = languageText(title);

          let selecteds = options
            .filter((e) => !!e.selected)
            .map((e) => {
              let name = languageText(e.name);
              if (name == "no-choose") name = "Seçim Yapmak İstemiyorum";

              return (
                languageText(name) +
                (e.price ? " (" + priceText(e.price, currency) + ")" : "")
              );
            })
            .join(", ");

          return (
            <div style={{ padding: 5 }}>
              <div
                className="gx-text-primary"
                style={{
                  fontSize: 12,
                }}
              >
                {title}:<br />
                <div style={{ color: "black" }}>{selecteds}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div
      style={{ border: "1px solid #50505020", padding: 20, borderRadius: 10 }}
    >
      <Drawer
        title="Opsiyonlar"
        width={500}
        closable={false}
        onClose={() => {
          setModal(false);
        }}
        visible={modal}
      >
        <ProductAddOptions
          key={Math.random()}
          option={modal != true ? modal?.option : false}
          index={modal != true ? modal?.index : undefined}
          onAdd={onAdd}
          onRemove={onRemove}
          onClose={() => {
            setModal(false);
          }}
        />
      </Drawer>

      {options.map((data, index) => {
        const { title, multiple, require, options } = data;
        return (
          <div style={{ margin: "25px 0 0 0" }}>
            <div style={{ fontWeight: "bold", margin: 5 }}>
              {languageText(title)}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {multiple ? (
                <Checkbox.Group
                  style={{ margin: 10 }}
                  defaultValue={getSelected({ options, multiple })}
                >
                  {options.map(({ name, price }) => (
                    <Checkbox
                      value={JSON.stringify(name)}
                      style={{ margin: 5 }}
                    >
                      {languageText(name) +
                        (price ? " +" + priceText(price, currency) : "")}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              ) : (
                <Select
                  placeholder={languageText(title)}
                  style={{ width: 300, margin: 5 }}
                  defaultValue={getSelected({ options, multiple })}
                >
                  {options.map(({ name, price }) => {
                    let _name = languageText(name);
                    if (name == "no-choose") _name = "Seçim Yapmak İstemiyorum";

                    return (
                      <Select.Option value={JSON.stringify(name)}>
                        {_name +
                          (price ? " +" + priceText(price, currency) : "")}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}

              {onAdd ? (
                <Button
                  style={{ margin: 0, padding: 0 }}
                  type="primary"
                  shape="circle"
                  icon={<EditFilled />}
                  onClick={() => {
                    setModal({ option: data, index });
                  }}
                />
              ) : null}
            </div>
          </div>
        );
      })}
      {onAdd ? (
        <Button
          style={{ marginTop: 30 }}
          type="primary"
          onClick={() => {
            setModal(true);
          }}
        >
          Opsiyon Ekle
        </Button>
      ) : null}
    </div>
  );
};
export default observer(Options);

/*
{
        options.map(({name,selected,price},index)=>{
          return(
            <Popconfirm
              disabled={disabled}
              key={index}
              title={<IntlMessages id={"opsiyonsiluyari"}/>}
              onConfirm={()=>{
                if(!disabled)
                  onRemove(index)
              }}
              okText={<IntlMessages id={"evet"}/>}
              cancelText={<IntlMessages id={"hayir"}/>}
            >
              <Button
                size="small"
                style={{fontSize:10}}
                className={`gx-rounded-xxl gx-text-${selected?"white":"primary"}`}
                type={selected?"primary":"default"}
              >
                {name+" "+(price?priceText(price):"")}
              </Button>
            </Popconfirm>

          )
        })
      }
      {
        !disabled?
          <Button
            size="small"
            type="primary" shape="circle" icon={<i className={`icon icon-${!add?"add":"divider"}`}/>}
            onClick={()=>{
              setAdd(!add)
            }}
          />:null
      }

      {
        add && !disabled?
          <Form
            style={{width:'100%',transition:"height 3s"}}
            onFinish={({name,price,selected})=>{
              price=price??0
              selected=selected??false

              onAdd({name,price,selected})
              setAdd(false)
            }}
          >
            <Form.Item style={{margin:0}} name={"name"} rules={[{ required: true, message: <IntlMessages id={"gecersizbilgi"}/> }]} >
              <Input
                maxLength={100}
                placeholder={intl.formatMessage({id:"opsiyonismi"})}
                size="small"
              />
            </Form.Item>
            <Form.Item style={{margin:0}} name={"price"} rules={[{ required: false}]} >
              <InputNumber
                defaultValue={0}
                placeholder={<IntlMessages id={"fiyat"}/>}
                min={0}
                formatter={value => `${currency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(new RegExp(currency+"\s?|(,*)","g"), '')}
                size="small"
              />
            </Form.Item>

            <Form.Item style={{margin:0}} label={<IntlMessages id={"opsiyondurumu"}/>} name={"selected"} rules={[{ required: false}]} >
              <Popover
                title={<IntlMessages id={"bilgi"}/>}
                content={<IntlMessages id={"opsiyonbilgi"}/>}
              >
                <Switch
                  style={{margin:0}}
                  checkedChildren={<IntlMessages id={"secili"}/>}
                />
              </Popover>
            </Form.Item>

            <Form.Item className="gx-text-right" style={{margin:0}} >
              <Button
                size="small"
                type="primary"
                htmlType="submit"
              >
                <IntlMessages id={"ekle"}/>
              </Button>
            </Form.Item>
          </Form>:null
      }

*/
