import React, { useEffect, useState } from "react";
import { Avatar, Button, Form, Input, message } from "antd";
import IntlMessages from "util/IntlMessages";
import { getUser, post } from "../../networking/Server";
import { useIntl } from "react-intl";
import { userSignIn } from "../../appRedux/actions";
import { useDispatch, useSelector } from "react-redux";
import { LockFilled } from "@ant-design/icons";
import { MStore } from "../../networking/stores/MainStore";
import { observer } from "mobx-react";

const FormItem = Form.Item;

const LockScreen = ({ history }) => {
  const intl = useIntl();
  const dispact = useDispatch();
  const token = useSelector(({ auth }) => auth.token);

  const [loading, setLoading] = useState(false);

  let user = MStore.user;

  const onLogin = (values) => {
    values["email"] = user?.email;

    setLoading(true);
    post("login", values).then((res) => {
      setLoading(false);

      if (res.result) {
        localStorage.removeItem("lock");
        message.success(intl.formatMessage({ id: "basarili" }));
        dispact(userSignIn(res.token, res.user));
      } else {
        message.error(intl.formatMessage({ id: res.error }));
      }
    });
  };

  useEffect(() => {
    if (token !== null) {
      history.push("/");
    }
  }, [token, history]);

  return (
    <div className="gx-login-container">
      <div className="gx-login-content gx-text-center">
        <div className="gx-login-header">
          <img
            className="gx-size-120"
            src={require("../../assets/images/ci-logo2.png")}
          />
        </div>
        <div className="gx-mb-4">
          <h3>{user?.email}</h3>
          <p>
            <IntlMessages id="lock_uyarı" />
          </p>
        </div>

        {user?.user_id ? (
          <Form
            initialValues={{ remember: true }}
            name="basic"
            onFinish={onLogin}
            className="gx-signin-form gx-form-row0"
          >
            <Form.Item
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "gecersizbilgi" }),
                },
              ]}
              name="password"
            >
              <Input
                type="password"
                allowClear
                bordered={false}
                prefix={<LockFilled className="gx-text-secondary" />}
                style={{ backgroundColor: "#f5f5f5", borderRadius: 20 }}
                placeholder={intl.formatMessage({ id: "sifre" })}
              />
            </Form.Item>

            <FormItem>
              <Button loading={loading} type="primary" htmlType="submit">
                <IntlMessages id="girisyap"></IntlMessages>
              </Button>
            </FormItem>
          </Form>
        ) : (
          <FormItem>
            <Button
              loading={loading}
              type="primary"
              onClick={() => {
                history.push("/");
              }}
            >
              <IntlMessages id="girisyap"></IntlMessages>
            </Button>
          </FormItem>
        )}
      </div>
    </div>
  );
};

export default observer(LockScreen);
