import React, { useEffect, useState } from "react";
import { Steps, Card, Button, Input, message, Layout } from "antd";
import { getUser, post } from "../../networking/Server";
import { useIntl } from "react-intl";
import Cafe from "../Cafe";
import CafeAdd from "../Cafe/add";
import CustomModal from "../../components/CustomModal/CustomModal";
import Topbar from "../../containers/Topbar";
import Tables from "../Tables";
import { Person } from "@material-ui/icons";
import Staffs from "../Staffs";
import ProductList from "../Products";
import { getCafe } from "../../functions/getCafe";
import { MStore } from "../../networking/stores/MainStore";
import { observer } from "mobx-react";
import { userSignIn } from "../../appRedux/actions";
import { error } from "../../functions/toast";
import { useDispatch } from "react-redux";
import { Store } from "../../networking/stores/Store";
import IntlMessages from "util/IntlMessages";

const Setup = ({ history }) => {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);

  const dispact = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history.push("/");
      return;
    }
    onLogin();
  }, []);

  const next = () => {
    setPage(page + 1);
  };

  const prev = () => {
    setPage(page - 1);
  };

  const cafe = getUser()["cafe"];
  let is_cafe = false;
  if (Array.isArray(cafe) && cafe.length) {
    is_cafe = true;
  }

  const onLogin = () => {
    Store.setLoading(true);
    post("api/auth/get", {}, false).then((res) => {
      Store.setLoading(false);
      if (res.result) {
        dispact(userSignIn(res.token, res.user));
      } else {
        history.push("/");
        error();
      }
    });
  };

  return (
    <Layout className="gx-app-layout">
      <Layout>
        <Topbar webview />

        <CustomModal />
        <Layout.Content className={`gx-layout-content gx-container-wrap `}>
          <Card style={{ padding: 20, margin: 20 }}>
            <Steps size="small" current={page} style={{ marginBottom: 40 }}>
              <Steps.Step title={<IntlMessages id="isletmeolustur" />} />
              <Steps.Step title={<IntlMessages id="masaolustur" />} />
              <Steps.Step title={<IntlMessages id="personelolustur" />} />
              <Steps.Step title={<IntlMessages id="urunekle" />} />
              <Steps.Step title={<IntlMessages id="bitir" />} />
            </Steps>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                margin: 20,
                marginTop: 50,
              }}
              className="steps-action"
            >
              {page > 0 && (
                <Button onClick={() => prev()}>
                  <IntlMessages id="oncekisayfa" />
                </Button>
              )}

              {[...[1, 2, 3], ...(is_cafe ? [0] : [])].includes(page) && (
                <Button type="primary" onClick={() => next()}>
                  <IntlMessages id="devam" />
                </Button>
              )}
              {page == 4 && (
                <Button
                  type="primary"
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  <IntlMessages id="kaydetvebitir" />
                </Button>
              )}
            </div>

            {page == 0 ? (
              <div>
                {is_cafe ? (
                  <Cafe />
                ) : (
                  <CafeAdd
                    onSave={() => {
                      onLogin();
                    }}
                  />
                )}
              </div>
            ) : page == 1 ? (
              <div>
                <Tables />
              </div>
            ) : page == 2 ? (
              <div>
                <Staffs />
              </div>
            ) : page == 3 ? (
              <div>
                <ProductList />
              </div>
            ) : page == 4 ? (
              <div></div>
            ) : null}
          </Card>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default observer(Setup);
