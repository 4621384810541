import React, {useEffect, useState} from "react";
import Home from "../Home";
import CustomScrollbars from "../../util/CustomScrollbars";
import {post} from "../../networking/Server";
import {toParse} from "../../functions/json";
import {useDispatch, useSelector} from "react-redux";
import {userSignIn} from "../../appRedux/actions";
import {Route,Switch} from "react-router-dom";
import CustomModal from "../../components/CustomModal/CustomModal";
import {Layout} from "antd";
import Topbar from "../../containers/Topbar";
import Loading from "../../components/Loading";
import OrderStatistics from "../Statistics/orders";
import Surveys from "../Surveys";
import {Authorities} from "../../constants/Config";
import asyncComponent from "../../util/asyncComponent";
import ProductList from "../Products";
import Error404 from "../404";
import SurveyStatistics from "../Statistics/survey";
import StockStatistics from "../Statistics/stock";
import Stock from "../Stock";
import Staffs from "../Staffs";
import Cafe from "../Cafe";
import Tables from "../Tables";
import Orders from "../Orders";
import OrderReservations from "../OrderReservations";
import OrderCameTake from "../OrderCameTake";
import OrderAddress from "../OrderAddress";
import Ads from "../Ads";
import OrderQr from "../OrderQR";
import CampaignList from "../Campaigns";
import LoyaltyList from "../Loyaltys";
const Token = ({history,match:{params:{token}}}) => {
  const [data,setData] = useState({})
  const [authorities,setAuthorities] = useState([])
  const dispact = useDispatch();

  const {navStyle} = useSelector(({settings}) => settings);




  useEffect(()=>{
      post("web_token",{token},false).then((res)=>{
          if(res.result){
            const _data = toParse(res.data)
            dispact(userSignIn(_data.token,res.user))

            setTimeout(()=>{
              setData(_data)
              setAuthorities(res.user['authorities'])
            },1000)
          }
      })
  },[])



  if(!data || !data['token']){
    return <Loading/>
  }

  function pathControl(){
    let find = Authorities.find(e => {
      if(e.path == data.path) return true;
      return (e.sub || []).find(k => k.path == data.path);
    })

    if(!find) return <Error404 history={history}/>

    if(find.path != data.path){
      find = find.sub.find(e => e.path == data.path)
    }

    if(!find) return <Error404 history={history}/>


    const auth =authorities.includes(find.id);

    if(!auth) return <Error404 history={history}/>


    switch (data.path){
      case "home":
        return <Home/>
      case "statics/orders":
        return <OrderStatistics/>
      case "statics/survey":
        return <SurveyStatistics/>
      case "statics/stock":
        return <StockStatistics/>
      case "product/list":
        return <ProductList/>
      case "stock/list":
        return <Stock/>
      case "staff/list":
        return <Staffs/>
      case "cafe/list":
        return <Cafe/>
      case "table/list":
        return <Tables/>
      case "order/list":
        return <Orders/>
      case "reservation/list":
        return <OrderReservations/>
      case "gelal/list":
        return <OrderCameTake/>
      case "address_order/list":
        return <OrderAddress/>
      case "survey/list":
        return <Surveys/>
      case "reklam/list":
        return <Ads/>
      case "qr_app/list":
        return <OrderQr/>
      case "campaigns/list":
        return <CampaignList/>
      case "product/loyalty":
        return <LoyaltyList/>

      default:
          return <Error404 history={history}/>
    }
  }

  return (
    <Layout className="gx-app-layout">
      <Layout>
        <CustomModal/>
        <Topbar webview/>
        <Layout.Content className={`gx-layout-content gx-container-wrap `}>
          {pathControl()}
        </Layout.Content>
      </Layout>
    </Layout>
  )

  /*return (
    <CustomScrollbars>
      {JSON.stringify(data)}
      <Home/>
    </CustomScrollbars>
  );*/
};

export default Token;
