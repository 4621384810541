import React, {useState} from "react";
import {Spin} from "antd";
const Loading = ({height})=>{

  return(
    <div style={{flex:1,height:height??'100%',width:'100%'}} className="gx-flex-row gx-justify-content-center gx-align-items-center">
      <Spin/>
    </div>
  )
}
export default Loading

