import React from "react";
import StaticticsFilter from "../../components/StaticticsFilter";
import IntlMessages from "../../util/IntlMessages";
import { getCafe } from "../../functions/getCafe";
import { Card, Col, Row } from "antd";
import { useIntl } from "react-intl";

export default function Home2() {
  const intl = useIntl();

  return (
    <div>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card className="gx-card" title={intl.formatMessage({ id: "home" })}>
            <IntlMessages id="gostergepanelidesc" />
          </Card>
        </Col>
      </Row>
    </div>
  );
}
