import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { post } from "../../networking/Server";
import { Store } from "../../networking/stores/Store";
import { getCafe } from "../../functions/getCafe";
import {
  Avatar,
  Button,
  Card,
  DatePicker,
  Form,
  InputNumber,
  Popconfirm,
  Select,
  Spin,
  Tag,
} from "antd";
import IntlMessages from "../../util/IntlMessages";
import { priceText } from "../../functions/priceText";
import { EditFilled, EyeFilled, SyncOutlined } from "@ant-design/icons";
import DataTable from "../../functions/dataTable";
import {
  getOrderStatusName,
  getOrderTypeName,
  OrderStatuses,
} from "../../constants/Config";
import { l_moment } from "../../functions/l_moment";
import { useIntl } from "react-intl";
import OrderEdit from "./edit";
import { error, info, success } from "../../functions/toast";
import { lang, languageText } from "../../functions/language";
import { getOrders } from "../../functions/intervals";
import ProductAdd from "../Loyaltys/add";
import TableOrders from "./table_orders";
import Categories from "../Categories";

let selected_ids = [];

const Orders = ({ history }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    setInterval(() => {
      getOrders();
    }, 3000);
  }, []);

  function getOrders(force = false) {
    if (!force && selected_ids.length) return;
    post("api/order/get-all", {
      cafe_id: getCafe(),
    }).then((res) => {
      if (res.result) {
        if (!force && selected_ids.length) return;
        setData([...res.orders]);
      }
    });
  }

  const columns = [
    {
      name: "order_id",
      label: "ID",
      options: {
        filter: false,
      },
    },
    {
      name: "order_type_id",
      label: lang(intl, "siparis"),
      options: {
        filter: false,
        customBodyRender: (order_type_id) => {
          return <IntlMessages id={getOrderTypeName(order_type_id)} />;
        },
      },
    },
    {
      name: "order_tables",
      label: lang(intl, "masakategorisi"),
      options: {
        filter: true,
        filterType: "dropdown",
        customBodyRender: (table) => {
          let category_name = "";

          try {
            table = table[0];
            category_name = table["table"]["table_category"]["name"];
            if (!category_name) category_name = "";
          } catch (e) {
            category_name = "";
          }
          return category_name;
        },
      },
    },
    {
      name: "order_tables",
      label: lang(intl, "masanumarasi"),
      options: {
        filter: true,
        filterType: "dropdown",
        customBodyRender: (table) => {
          let table_num = "";
          try {
            table = table[0];
            table_num = table["table"]["table_num"];
            if (!table_num) table_num = "";
          } catch (e) {
            table_num = "";
          }
          return table_num.toString();
        },
      },
    },
    {
      name: "customer",
      label: lang(intl, "musteri"),
      options: {
        filter: false,
        customBodyRender: (customer) => {
          if (customer) {
            if (customer["name"])
              return customer["name"] + " " + customer["surname"];
          }
          return "";
        },
      },
    },
    {
      name: "createdAt",
      label: lang(intl, "sipariszamani"),
      options: {
        filter: false,
        customBodyRender: (date) => {
          return l_moment(date).fromNow();
        },
      },
    },
    {
      name: "price",
      label: lang(intl, "fiyat"),
      options: {
        filter: false,
        customBodyRender: (price) => {
          return priceText(price);
        },
      },
    },
    {
      name: "online_payment",
      label: lang(intl, "onlineodeme"),
      options: {
        filter: false,
        customBodyRender: (online_payment) =>
          online_payment ? lang(intl, "evet") : lang(intl, "hayir"),
      },
    },
    {
      name: "order_follows",
      label: lang(intl, "durum"),
      options: {
        filter: true,
        customBodyRender: (order_follows) => {
          let last_order_follows = {};

          try {
            last_order_follows = order_follows[0];
          } catch (e) {
            last_order_follows = {};
          }
          if (!last_order_follows) last_order_follows = {};

          return (
            <Tag
              style={{
                fontWeight: "bold",
                color: getOrderStatusName(
                  last_order_follows["order_status_id"],
                  "color"
                ),
              }}
            >
              <IntlMessages
                id={getOrderStatusName(last_order_follows["order_status_id"])}
              />
            </Tag>
          );
        },
        filterOptions: {
          names: [
            ...Object.values(OrderStatuses).map((e) =>
              intl.formatMessage({ id: e.value })
            ),
          ],
          logic: (location, filters, row) => {
            let last_order_follows = {};

            try {
              last_order_follows = location[0];
            } catch (e) {
              last_order_follows = {};
            }
            if (!last_order_follows) last_order_follows = {};

            location = intl.formatMessage({
              id: getOrderStatusName(
                last_order_follows.order_status_id,
                "value"
              ),
            });

            if (filters.length) return !filters.includes(location);
            return false;
          },
        },
      },
    },
    {
      name: "order_follows",
      label: "",
      options: {
        display: false,
        filter: false,
        customBodyRender: (order_follows) => {
          let last_order_follows = {};

          try {
            last_order_follows = order_follows[0];
          } catch (e) {
            last_order_follows = {};
          }
          if (!last_order_follows) last_order_follows = {};

          return last_order_follows["order_status_id"];
        },
      },
    },
    {
      name: "order_products",
      label: "",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "cafe",
      label: "",
      options: {
        display: false,
        filter: false,
        customBodyRender: ({ name }) => {
          return name;
        },
      },
    },
    {
      name: "order_id",
      label: " ",
      options: {
        sort: false,
        filter: false,
        searchable: false,
        customBodyRender: (order_id, { rowIndex }) => {
          return (
            <div>
              <Button
                type="primary"
                shape="circle"
                icon={<EyeFilled />}
                onClick={() => {
                  Store.setModal({
                    title: "Detay",
                    child: (
                      <OrderEdit
                        order_products={data[rowIndex]["order_products"]}
                        cafe={data[rowIndex]["cafe"]}
                        order={data[rowIndex]}
                        order_id={order_id}
                        online_payment={data[rowIndex]["online_payment"]}
                        onChange={() => {
                          getOrders(true);
                        }}
                      />
                    ),
                  });
                }}
              />
            </div>
          );
        },
      },
    },
  ];

  return (
    <div>
      <Button
        type="primary"
        onClick={() => {
          Store.setModal({
            title: <IntlMessages id={"hesap"} />,
            child: (
              <TableOrders
                onChange={() => {
                  getOrders(true);
                }}
              />
            ),
          });
        }}
      >
        <IntlMessages id={"hesabikapat"} />
      </Button>
      <DataTable
        loading={true}
        title={<IntlMessages id="siparisler" />}
        data={data}
        columns={columns}
        options={{
          setRowProps: (row, b, c) => {
            try {
              let order_status_id = row[7];

              return {
                style: {
                  background:
                    getOrderStatusName(order_status_id, "color") + "33",
                  color: "white",
                },
              };
            } catch (e) {
              return {};
            }
          },
        }}
      />
    </div>
  );
};

export default observer(Orders);
