import { menuLanguages } from "../constants/Config";
import { post } from "../networking/Server";
import { defaultMenuLanguage } from "../components/MultiLanguage";
import { toParse } from "./json";

export function lang(intl, id) {
  return intl.formatMessage({ id });
}


export function languageText(
  text,
  lang = toParse(localStorage.getItem("language"))["lang"]
) {


  if (!lang) {
    lang = menuLanguages[0].lang;
    const userLang = navigator.language || navigator.userLanguage;
    if (userLang == "en") {
      lang = menuLanguages[1].lang;
    }
  }


  try {
    let res = "";

    if (typeof text == "string") {
      try {
        res = text
          .toString()
          .match(new RegExp(`<${lang}>(.*?)<\\/${lang}>`, "gsm"))[0]
          .replace(`<${lang}>`, "")
          .replace(`</${lang}>`, "");
      } catch (e) {}

      if (!res || res == "undefined") {
        res = text;

        for (const { lang } of menuLanguages) {
          let res = "";
          try {
            res = text
              .toString()
              .match(new RegExp(`<${lang}>(.*?)<\\/${lang}>`, "gsm"))[0]
              .replace(`<${lang}>`, "")
              .replace(`</${lang}>`, "");
          } catch (e) {}

          if (res && res != "undefined") return res;
        }
      }
    } else {
      res = text[lang];
    }

    return res;
  } catch (e) {
    return JSON.stringify(e);
  }

  try {
    if (!text.includes("</")) return text;
  } catch (e) {}
  return "";
}

export function encodingLangFormat(data, keys = []) {
  try {
    Object.entries(data).map(({ 0: key, 1: value }) => {
      let new_value = "";
      try {
        if (keys.includes(key)) {
          Object.entries(value).map(({ 0: key1, 1: value1 }) => {
            if (value1) new_value += `<${key1}>${value1}</${key1}>`;
          });
        } else {
          new_value = value;
        }
      } catch (e) {}

      data[key] = new_value;
    });
  } catch (e) {}

  return data;
}

export function decodingLangFormat(data, keys = []) {
  try {
    Object.entries(data).map(({ 0: key, 1: value }) => {
      let new_value = {};
      try {
        if (keys.includes(key)) {
          menuLanguages.map(({ lang }) => {
            new_value[lang] = languageText(value, lang);
          });
        }
      } catch (e) {}

      data[key] =
        Array.isArray(Object.keys(new_value)) && Object.keys(new_value).length
          ? new_value
          : value;
    });
  } catch (e) {}

  return data;
}

export async function translate(intl, data, lang, keys = []) {
  try {
    let d_lang = defaultMenuLanguage(intl.locale);
    for (let { 0: key, 1: value } of Object.entries(data)) {
      try {
        if (keys.includes(key)) {
          let text = value[d_lang];

          let res = await post("api/cafe/translate", {
            text,
            from: d_lang,
            to: lang,
          });
          value[lang] = res.text;
        }
      } catch (e) {}

      data[key] = value;
    }
  } catch (e) {}

  return data;
}
