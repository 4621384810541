import Axios from "./Axios";
import jwt_decode from "jwt-decode";
import { Store } from "./stores/Store";
import { MStore } from "./stores/MainStore";
import { error } from "../functions/toast";

let token = "";
let task;

export async function getMe(no_logout = false) {
  const res = await post("api/auth/get", {}, false);
  if (res.result) {
    if (no_logout) {
      localStorage.setItem("token", res.token);
      MStore.setUser(res.user);
    } else {
      if (
        JSON.stringify(MStore.user["authorities"]) !=
          JSON.stringify(res.user["authorities"]) ||
        JSON.stringify(MStore.user["staff_types"]) !=
          JSON.stringify(res.user["staff_types"])
      ) {
        logOut(2);
      } else {
        localStorage.setItem("token", res.token);
        MStore.setUser(res.user);
      }
    }
  } else {
    if ([2, 100, 101, 102, 106].includes(res.error)) {
      logOut(res.error);
      throw {};
    }
  }

  return;
}

export async function post(adres, params = null, create_token = true) {
  Axios.defaults.headers.common["x-access-token"] =
    "Bearer " + localStorage.getItem("token");
  if (create_token) {
    getMe();
  }
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      if (params) params = { ...params, web: true };
      task = Axios.post(adres, params);
      resolve(
        task
          .then(({ data }) => {
            try {
              data = JSON.parse(data);
            } catch {}

            if ([2, 100, 101, 102].includes(data.error) && create_token) {
              logOut(data.error);
              throw {};
            }

            return data;
          })
          .catch((err) => {
            //IStore.setConnection(1);
            return { result: false, error: 1 };
          })
      );
    }, 1000);
  });
}

function logOut(error) {
  localStorage.removeItem("token");
  if (error == 2) {
    localStorage.setItem("lock", "true");
    window.location.href = "/lock";
  } else {
    localStorage.removeItem("lock");
    window.location.href = "/signin";
  }
}

export function getTask() {
  return task;
}

export async function cancelPost(task) {
  task.cancel((res) => {
    console.warn("İstek İptal Edildi");
  });
}

export function getURL() {
  return "https://cyberistanbul.com:5000/";
}

export function getImageURL() {
  return getURL();
}

export function getUser(token) {
  try {
    if (token) {
      return jwt_decode(token);
    } else {
      return jwt_decode(localStorage.getItem("token"));
    }
  } catch (e) {}
  return {};
}
