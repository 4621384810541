import React, {useEffect, useState} from "react";

import {observer} from "mobx-react-lite";
import {post} from "../../networking/Server";
import {Store} from "../../networking/stores/Store";
import {getCafe} from "../../functions/getCafe";
import {Card} from "antd";

const OrderCameTake = ({history}) => {
  const [data,setData] = useState([])

  useEffect(()=>{
    getOrders()
  },[])

  function getOrders() {
    Store.setLoading(true)
    post("api/products/get_list",{cafe_id:getCafe()}).then((res)=>{
      Store.setLoading(false)

      if(res.result){
        setData(res.orders)
      }
    })
  }



  return (
    <Card title={"Gel Al Sipariş"}>

    </Card>
  );
};

export default observer(OrderCameTake);
