import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import {
  Button,
  Form,
  Select,
  Divider,
  Tag,
  Col,
  Input,
  Spin,
  Switch,
  TreeSelect,
  Popconfirm,
} from "antd";
import { post } from "../../networking/Server";
import { getCafe, getCafes } from "../../functions/getCafe";
import { Store } from "../../networking/stores/Store";
import { getStockTypeName, StockTypes } from "../../constants/Config";
import { CloseCircleOutlined, EditFilled } from "@ant-design/icons";
import { error, success } from "../../functions/toast";
import { toJSON, toParse } from "../../functions/json";
import IntlMessages from "../../util/IntlMessages";
import { useIntl } from "react-intl";
import { languageText } from "../../functions/language";
const EditProductStock = ({ product_id, onChange }) => {
  const [data, setData] = useState({});
  const [products, setProducts] = useState([]);
  const [subproducts, setSubProducts] = useState([]);
  const [removed_ids, setRemovedIds] = useState([]);

  const [loading, setLoading] = useState(true);
  const [button_loading, setButtonLoading] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    getProducs(product_id);
  }, []);

  function getProducs(product_id) {
    setLoading(true);
    post("api/stocks/get_product_stock", {
      cafe_id: getCafe(),
      product_id,
    }).then((res) => {
      if (res.result) {
        setData({
          ...res.product,
          product_id,
          sub_product_ids: res.sub_product_ids,
        });
        setProducts(res.products);
        setSubProducts(res.subproducts);
      }
      setLoading(false);
    });
  }
  let options = /*data["options"]*/ [];

  function setProductStock(values) {
    setButtonLoading(true);
    post("api/stocks/set_product_stock", {
      cafe_id: getCafe(),
      data: values,
      removed_ids,
    }).then((res) => {
      setButtonLoading(false);

      if (res.result) {
        success();
        onChange();
        Store.setModal({});
      } else {
        if (res.error) {
          error(intl.formatMessage({ id: res.error }));
        } else {
          error();
        }
      }
    });
  }

  function createOption(_option) {
    if (!Array.isArray(_option)) _option = [];
    let new_option = [];

    options.map(({ title }) => {
      let i = _option.findIndex((e) => e.title == title);
      if (i != -1) {
        new_option.push({
          name: languageText(_option[i]["title"]),
          decrease_stock: _option[i]["decrease_stock"],
        });
      } else {
        new_option.push({ name: languageText(title), decrease_stock: 0 });
      }
    });

    return new_option;
  }

  function getStockProduct(sub_product_id) {
    let product_id = sub_product_id;
    let stock = 0;
    let stock_type = 1;
    let name = "";
    let _options = [];
    try {
      let product_stocks = data["product_stocks"] ?? [];
      let product_stock_index = product_stocks.findIndex(
        (e) => e.sub_product_id == sub_product_id
      );
      if (product_stock_index == -1) {
        let sub_product = subproducts.filter(
          (e) => e.product_id == sub_product_id
        )[0];
        stock_type = getStockTypeName(sub_product["stock_type"]);
        name = sub_product["name"];
        _options = createOption([...options]);
      } else {
        const product_stock = product_stocks[product_stock_index];
        stock = product_stock["decrease_stock"];
        stock_type = getStockTypeName(
          product_stock["subproduct"]["stock_type"]
        );
        name = product_stock["subproduct"]["name"];
        _options = createOption(toParse(product_stock, "options"));
      }
    } catch (e) {}

    return { product_id, name, stock, stock_type, options: _options };
  }

  if (loading) {
    return <Spin className="gx-loader-container" />;
  }

  let sub_product_ids = [];
  try {
    sub_product_ids = data["sub_product_ids"].filter(
      (e) => !removed_ids.includes(e)
    );
  } catch (e) {}

  return (
    <div>
      <Form
        style={{ margin: 20 }}
        layout="vertical"
        name="validate_other"
        initialValues={{ ...data }}
        onFinish={setProductStock}
      >
        <Form.Item
          name={"product_id"}
          label={<IntlMessages id={"urunadi"} />}
          rules={[
            { required: true, message: <IntlMessages id={"gecersizbilgi"} /> },
          ]}
        >
          <Select
            onChange={(product_id) => {
              getProducs(product_id);
            }}
          >
            {Array.isArray(products)
              ? products.map(({ product_id, name }) => (
                  <Select.Option value={product_id}>
                    {languageText(name)}
                  </Select.Option>
                ))
              : null}
          </Select>
        </Form.Item>

        <Divider orientation="left">
          <IntlMessages id={"kullandigimalzemeler"} />
        </Divider>
        {sub_product_ids.map((sub_product_id, index) => {
          let { product_id, name, stock, stock_type, options } =
            getStockProduct(sub_product_id);

          return (
            <div
              style={{
                backgroundColor: "#50505010",
                padding: 5,
                marginBottom: 20,
                borderRadius: 10,
              }}
            >
              <Form.Item
                wrapperCol={{ span: 20 }}
                style={{ margin: 2 }}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"gecersizbilgi"} />,
                    min: 1,
                  },
                ]}
                initialValue={parseFloat(stock.toString()).toFixed(0)}
                name={["products", product_id.toString(), "decrease_stock"]}
              >
                <Input
                  prefix={<b>{name}: </b>}
                  suffix={<div>{stock_type}</div>}
                  addonAfter={
                    <CloseCircleOutlined
                      onClick={() => {
                        removed_ids.push(sub_product_id);
                        setRemovedIds([...removed_ids]);
                      }}
                    />
                  }
                  bordered={true}
                  type="number"
                />
              </Form.Item>

              {options.map(({ name, decrease_stock }, index) => {
                return (
                  <Form.Item
                    key={index}
                    wrapperCol={{ span: 18 }}
                    style={{ margin: 0, padding: 0 }}
                    initialValue={decrease_stock ?? 0}
                    name={["products", product_id.toString(), "options", name]}
                  >
                    <Input
                      size="small"
                      prefix={<div>-{name}: +</div>}
                      bordered={false}
                      type="number"
                    />
                  </Form.Item>
                );
              })}
            </div>
          );
        })}
        <Form.Item wrapperCol={{ span: 20 }} style={{ margin: 2 }}>
          <Select
            value={<IntlMessages id={"ekle"} />}
            onChange={(value) => {
              sub_product_ids.push(value);
              let removed_id_index = removed_ids.findIndex((e) => e == value);
              if (removed_id_index != -1)
                removed_ids.splice(removed_id_index, 1);
              setRemovedIds([...removed_ids]);
              setData({ ...data, sub_product_ids });
            }}
          >
            {Array.isArray(subproducts)
              ? subproducts
                  .filter((e) => !sub_product_ids.includes(e.product_id))
                  .map(({ product_id, name }) => (
                    <Select.Option value={product_id}>
                      {languageText(name)}
                    </Select.Option>
                  ))
              : null}
          </Select>
        </Form.Item>

        <Form.Item className=" gx-text-right gx-flex-row">
          <Button
            loading={button_loading}
            className="gx-mr-2"
            type="primary"
            htmlType="submit"
          >
            <IntlMessages id={"kaydet"} />
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(EditProductStock);
