import React from "react";
import { Link, Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import { getUser } from "../networking/Server";
import { Authorities } from "../constants/Config";

const App = ({ match }) => {
  let authorities = [];
  try {
    authorities = getUser()["authorities"];
    if (!Array.isArray(authorities)) authorities = [];
  } catch (e) {}

  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route
          path={match.url + "home"}
          component={asyncComponent(() => require("../routes/Home2/index"))}
        />
        {Authorities.filter((e) =>
          Array.isArray(e.id)
            ? authorities.some((id) => e.id.includes(id))
            : authorities.includes(e.id)
        ).map(({ sub, path, page }) => {
          if (sub) {
            return sub.map(({ id, path, page }) => {
              if (
                Array.isArray(id)
                  ? authorities.some((e) => id.includes(e))
                  : authorities.includes(id)
              ) {
                return (
                  <Route
                    path={match.url + path}
                    component={asyncComponent(() => page)}
                  />
                );
              } else {
                return null;
              }
            });
          }
          if (!page) return;
          return (
            <Route
              path={match.url + path}
              component={asyncComponent(() => page)}
            />
          );
        })}
        <Route
          path={match.url + "profile"}
          component={asyncComponent(() => import("./Profile"))}
        />
        <Route path={""} component={asyncComponent(() => import("./404"))} />
      </Switch>
    </div>
  );
};

export default App;
