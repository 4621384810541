import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import DataTable from "../../functions/dataTable";
import { post } from "../../networking/Server";
import { Store } from "../../networking/stores/Store";
import { priceText } from "../../functions/priceText";
import { getCafe } from "../../functions/getCafe";
import { Avatar, Button, Drawer } from "antd";
import { EditFilled } from "@ant-design/icons";
import ProductAdd from "./add";
import Categories from "../Categories";
import IntlMessages from "../../util/IntlMessages";
import { useIntl } from "react-intl";
import CampaignAdd from "./add";
import { lang } from "../../functions/language";

const CampaignList = ({ history }) => {
  const intl = useIntl();
  const [data, setData] = useState([]);

  useEffect(() => {
    getCampaigns();
  }, []);

  function getCampaigns() {
    Store.setLoading(true);
    post("api/campaigns/get_list", { cafe_id: getCafe() }).then((res) => {
      Store.setLoading(false);

      if (res.result) {
        setData(res.campaigns);
      }
    });
  }

  const columns = [
    {
      name: "image",
      label: " ",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value) => {
          return <Avatar src={value} className="gx-size-50" alt="" />;
        },
      },
    },
    {
      name: "campaign_id",
      label: "ID",
      options: {
        filter: false,
      },
    },
    {
      name: "title",
      label: "Başlık",
      options: {
        filter: false,
      },
    },
    {
      name: "short_desc",
      label: "Kısa Açıklama",
    },
    {
      name: "status",
      label: lang(intl, "aktiflik"),
      options: {
        sort: false,
        filter: true,
        searchable: false,
        customBodyRender: (value) =>
          value ? (
            <div className="gx-text-primary">Aktif</div>
          ) : (
            <div className="gx-text-danger">Kampanya Sona Erdi</div>
          ),
      },
    },
    {
      name: "campaign_id",
      label: " ",
      options: {
        sort: false,
        filter: false,
        searchable: false,
        customBodyRender: (campaign_id) => {
          return (
            <div>
              <Button
                type="primary"
                shape="circle"
                icon={<EditFilled />}
                onClick={() => {
                  Store.setModal({
                    title: "Güncelle",
                    child: (
                      <CampaignAdd
                        campaign_id={campaign_id}
                        onChange={(new_data) => {
                          try {
                            let index = data.findIndex(
                              (e) => e.campaign_id == campaign_id
                            );
                            data[index] = new_data;
                            setData([...data]);
                          } catch (e) {}
                        }}
                      />
                    ),
                  });
                }}
              />
            </div>
          );
        },
      },
    },
  ];

  return (
    <div>
      <Button
        type="primary"
        onClick={() => {
          Store.setModal({
            title: <IntlMessages id={"ekle"} />,
            child: (
              <CampaignAdd
                onChange={() => {
                  getCampaigns();
                }}
              />
            ),
          });
        }}
      >
        Kampanya Ekle
      </Button>
      <DataTable title={"Kampanyalar"} data={data} columns={columns} />
    </div>
  );
};

export default observer(CampaignList);
