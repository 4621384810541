import { Store } from "../networking/stores/Store";
import React from "react";
import { observer } from "mobx-react";
import MUIDataTable from "mui-datatables";
import { Empty, Spin } from "antd";
import CustomFooter from "../components/CustomFooter";
import { useIntl } from "react-intl";

const DataTable = ({ title, data, columns, loading = false, options = {} }) => {
  const intl = useIntl();
  const dataTableOpions = {
    responsive: "vertical",
    filterType: "checkbox",
    expandableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    downloadOptions: {
      filename: "SiparisDokumu.csv",
      separator: ",",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    ...(intl.locale == "en-TR"
      ? {
          textLabels: {
            body: {
              noMatch:
                Store.loading || loading ? (
                  <Spin className={"gx-m-5"} />
                ) : (
                  <Empty />
                ),
              toolTip: "Sırala",
              columnHeaderTooltip: (column) => `${column.label} İçin Sıralandı`,
            },
            pagination: {
              next: "Sonraki Sayfa",
              previous: "Önceki Sayfa",
              rowsPerPage: "Satır:",
              displayRows: "Toplam:",
            },
            toolbar: {
              search: "Ara",
              downloadCsv: "CSV İndir",
              print: "Print",
              viewColumns: "Sütunları Görüntüle",
              filterTable: "Filtre Tablosu",
            },
            filter: {
              all: "TÜMÜ",
              title: "FİLTRELER",
              reset: "SIFIRLA",
            },
            viewColumns: {
              title: "Sütunları Göster",
              titleAria: "Tablo Sütununu Göster / Gizle",
            },
            selectedRows: {
              text: "Satır Seçildi",
              delete: "Sil",
              deleteAria: "Seçili Satırları Sil",
            },
          },
        }
      : {}),
  };

  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={{ ...dataTableOpions, ...options }}
    />
  );
};
export default observer(DataTable);
