// Epson printer specific codes
const INIT_PRINT = '\x1B' + '\x40';
const LINE_BREAK = '\x0A';
const CUT_PAPER = '\x1B' + '\x69';

// USB devices can have 1-16 possible endpoints that can be connected to
// we don't know what endpoint we need to connect to to send a successful print command
// so we just start at endpoint 1, and we shuffle until 16 to see which endpoint the printer is using
let endpointNumber = 1;


const sendMessageToPrinter = (device, content) => {
  const encoder = new TextEncoder();

  const data = encoder.encode(content);

  console.log('trying ', endpointNumber, typeof content,content)

  return device.transferOut(endpointNumber, content);
}

const startPrint = async  (device, content) => sendMessageToPrinter(device, content).catch(e => {
  if (e.message.includes('The specified endpoint is not part of a claimed and selected alternate interface')) {
    if (endpointNumber < 15) {
      endpointNumber = endpointNumber + 1;
      startPrint(device, content);
      return;
    }

    console.log('failed!');
    return;
  }

  console.error('Send Error:', e);
}).then(e => console.log(e))


let device;

const getDevices = async () => navigator.usb.getDevices().then(([printer]) => Promise.resolve(printer));

const requestPrinter = async () => {
  await navigator.usb.requestDevice({
    filters: [{vendorId: 1046}],
  })
}

const initPrinter = async () => {
  device = await getDevices();


  if(device){
    await device.open();
    await device.selectConfiguration(1);

    device.claimInterface(
      device.configuration.interfaces[0].interfaceNumber
    );

    endpointNumber = 1;
    return device
  }else{
    requestPrinter()
  }

  return false
};


export function Print(data) {
  initPrinter().then((_device)=>{
    if(!_device)
      return;
    device=_device

    startPrint(device, data);
  })
}
