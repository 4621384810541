import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import DataTable from "../../functions/dataTable";
import { post } from "../../networking/Server";
import { Store } from "../../networking/stores/Store";
import { priceText } from "../../functions/priceText";
import { getCafe } from "../../functions/getCafe";
import { Avatar, Button, Tag } from "antd";
import { EditFilled } from "@ant-design/icons";
import Categories from "../Categories";
import ProductAdd from "../Products/add";
import {
  Authorities,
  getAuthorities,
  getStaffTypeName,
  StaffTypes,
} from "../../constants/Config";
import IntlMessages from "../../util/IntlMessages";
import { useIntl } from "react-intl";
import StaffEdit from "./edit";
import { lang } from "../../functions/language";

const Staffs = ({ history }) => {
  const intl = useIntl();
  const [data, setData] = useState([]);

  useEffect(() => {
    getStaffs();
  }, []);

  function getStaffs() {
    Store.setLoading(true);
    post("api/staffs/get", { cafe_id: getCafe() }).then((res) => {
      Store.setLoading(false);

      if (res.result) {
        setData(res.users);
      }
    });
  }

  const columns = [
    {
      name: "image",
      label: " ",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (image) => {
          return <Avatar src={image} className="gx-size-50" alt="" />;
        },
      },
    },
    {
      name: "user_id",
      label: "ID",
      options: {
        filter: false,
      },
    },
    {
      name: "username",
      label: lang(intl, "isimsoyisim"),
      options: {
        filter: false,
      },
    },
    {
      name: "email",
      label: lang(intl, "kullaniciadi"),
      options: {
        filter: false,
      },
    },
    {
      name: "staffs",
      label: lang(intl, "gorev"),
      options: {
        customBodyRender: (staff) => {
          let staff_name = "";
          try {
            staff = staff[0];
            let staff_type_id = staff["staff_type_id"];

            staff_name = <IntlMessages id={getStaffTypeName(staff_type_id)} />;
          } catch (e) {}
          return staff_name;
        },
      },
    },
    {
      name: "authorities",
      label: lang(intl, "yetkiler"),
      options: {
        filter: false,
        sort: false,
        searchable: true,
        filterType: "multiselect",

        customBodyRender: (data) => {
          let authorities = [];
          try {
            data = JSON.parse(data);
            data.map((e) => {
              authorities.push(
                <Tag color={"warning"}>
                  <IntlMessages
                    id={getAuthorities().filter((e2) => e == e2.id)[0]["type"]}
                  />
                </Tag>
              );
            });
          } catch (e) {}

          return authorities;
        },
      },
    },
    {
      name: "password",
      label: lang(intl, "aktiflik"),
      options: {
        filter: false,
        short: false,
        customBodyRender: (active) => {
          if (active)
            return (
              <Tag color="success">
                <IntlMessages id="aktif" />
              </Tag>
            );
          return (
            <Tag color="error">
              <IntlMessages id="aktifdegil" />
            </Tag>
          );
        },
      },
    },
    {
      name: "user_id",
      label: " ",
      options: {
        sort: false,
        filter: false,
        searchable: false,
        customBodyRender: (user_id) => {
          return (
            <div>
              <Button
                type="primary"
                shape="circle"
                icon={<EditFilled />}
                onClick={() => {
                  Store.setModal({
                    title: <IntlMessages id="guncelle" />,
                    child: (
                      <StaffEdit
                        user_id={user_id}
                        onChange={(new_data) => {
                          try {
                            let index = data.findIndex(
                              (e) => e.user_id == user_id
                            );
                            data[index] = new_data;
                            setData([...data]);
                          } catch (e) {}
                        }}
                      />
                    ),
                  });
                }}
              />
            </div>
          );
        },
      },
    },
  ];

  return (
    <div>
      <Button
        type="primary"
        onClick={() => {
          Store.setModal({
            title: <IntlMessages id="ekle" />,
            child: (
              <StaffEdit
                onChange={() => {
                  getStaffs();
                }}
              />
            ),
          });
        }}
      >
        <IntlMessages id="personelekle" />
      </Button>

      <DataTable
        title={<IntlMessages id="personeller" />}
        data={data.filter((e) => e?.status)}
        columns={columns}
      />
    </div>
  );
};

export default observer(Staffs);
