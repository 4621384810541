import React, {useState} from "react";
import {Link} from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import {useIntl} from "react-intl";
import {Authorities} from "../../constants/Config";


const Error404 = ({history}) => {

  const code = Authorities.findIndex(e=>"/"+e.path==history.location.pathname)!=-1?"403":"404"

  return(
    <div className="gx-page-error-container">
      <div className="gx-page-error-content">
        <div className="gx-error-code gx-mb-4">{code}</div>
        <h2 className="gx-text-center">
          <IntlMessages id={code}/>
        </h2>
      </div>
    </div>
  )
};

export default Error404;
