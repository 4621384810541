import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { post } from "../../networking/Server";
import { getCafe, getCafes } from "../../functions/getCafe";
import {
  Input,
  Steps,
  InputNumber,
  Form,
  Spin,
  Button,
  Select,
  Checkbox,
  Popconfirm,
  Popover,
  Switch,
} from "antd";
import { Store } from "../../networking/stores/Store";
import { error, success } from "../../functions/toast";
import IntlMessages from "../../util/IntlMessages";
import { useIntl } from "react-intl";
import { lang } from "../../functions/language";

const SurveyAdd = ({ onChange = () => {} }) => {
  const [data, setData] = useState({});
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const intl = useIntl();

  function onSet(values) {
    if (!controlOptions(options, values["rate_type"])) {
      return;
    }

    values = { ...data, ...values };
    setLoading(true);
    post("api/rates/set", { cafe_id: getCafe(), data: values, options }).then(
      (res) => {
        if (res.result) {
          success();
          onChange(res.rate);
          Store.setModal({});
        } else {
          setLoading(false);
          if (res.error) {
            error(intl.formatMessage({ id: res.error }));
          } else {
            error();
          }
        }
      }
    );
  }

  function controlOptions(options, rate_type) {
    try {
      let length = options.filter((e) => !!e && !!e["option"]).length;
      if (rate_type == 1) {
        if (length == 5) {
          return true;
        } else {
          error("Emojili Ankette Tüm Seçeneklerin Olması Gerekmektedir.");
          return false;
        }
      } else if (rate_type == 2) {
        if (length >= 2) {
          return true;
        } else {
          error("En Az 2 Seçeneğiniz Olması Gerekmektedir.");
          return false;
        }
      }
    } catch (e) {}

    error();
    return false;
  }

  return (
    <div>
      <Form
        style={{ margin: 20 }}
        layout="vertical"
        name="validate_other"
        initialValues={data}
        onFinish={onSet}
      >
        <Form.Item
          name={"question"}
          label={lang(intl, "soru")}
          rules={[
            { required: true, message: <IntlMessages id={"gecersizbilgi"} /> },
          ]}
        >
          <Input.TextArea maxLength={1000} />
        </Form.Item>

        <Form.Item
          name={"rate_type"}
          label={lang(intl, "ankettipi")}
          rules={[
            { required: true, message: <IntlMessages id={"gecersizbilgi"} /> },
          ]}
        >
          <Select
            onChange={(rate_type) => {
              if (rate_type == 1) {
                setOptions([
                  { option: lang(intl, "cokkotu") },
                  { option: lang(intl, "kotu") },
                  { option: lang(intl, "orta") },
                  { option: lang(intl, "iyi") },
                  { option: lang(intl, "cokiyi") },
                ]);
              } else {
                setOptions([]);
              }
            }}
          >
            <Select.Option value={"1"}>
              {lang(intl, "emojianket")}
            </Select.Option>
            <Select.Option value={"2"}>
              {lang(intl, "seceneklianket")}
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label={lang(intl, "secenekler")}>
          <Steps size="small" current={-1} direction="vertical">
            {[0, 1, 2, 3, 4].map((index) => {
              let option = "";
              try {
                option = options[index]["option"];
              } catch (e) {}
              return (
                <Steps.Step
                  title={
                    <Input.TextArea
                      size="small"
                      value={option}
                      maxLength={300}
                      onChange={(event) => {
                        options[index] = {
                          option: event.target.value,
                        };
                        setOptions([...options]);
                      }}
                    />
                  }
                />
              );
            })}
          </Steps>
        </Form.Item>

        <Form.Item className=" gx-text-right gx-flex-row">
          <Button
            loading={loading}
            className="gx-mr-2"
            type="primary"
            htmlType="submit"
          >
            <IntlMessages id={"kaydet"} />
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(SurveyAdd);
