import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import {
  Button,
  Form,
  Select,
  InputNumber,
  Divider,
  Input,
  Spin,
  Popconfirm,
} from "antd";
import { post } from "../../networking/Server";
import { getCafe } from "../../functions/getCafe";
import { Store } from "../../networking/stores/Store";
import { PlusOutlined } from "@ant-design/icons";
import { error, success } from "../../functions/toast";
import { StockTypes } from "../../constants/Config";
import IntlMessages from "../../util/IntlMessages";
import { useIntl } from "react-intl";
import { languageText } from "../../functions/language";

const EditStock = ({ onChange }) => {
  const intl = useIntl();
  const [data, setData] = useState({});
  const [products, setProducts] = useState([]);
  const [name, setName] = useState("");

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [errors, setErrors] = useState({ name: false, stock: false });

  useEffect(() => {
    getProducs();
  }, []);

  function getStock(name) {
    let res = name.split("||");
    name = res[0];
    let product_id;
    if (Array.isArray(res) && res.length > 1)
      product_id = res[1] != "undefined" ? res[1] : null;

    setLoading2(true);
    post("api/stocks/get_one", { cafe_id: getCafe(), name, product_id }).then(
      (res) => {
        if (res.result) {
          setData({ ...res.stock, name, product_id });
        }
        setTimeout(() => {
          setLoading2(false);
        }, 500);
      }
    );
  }
  function getProducs() {
    post("api/products/get_stock_list", { cafe_id: getCafe() }).then((res) => {
      Store.setLoading(false);

      if (res.result) {
        setProducts(res.products);
      }
    });
  }

  function setStock(data) {
    let name = false;
    let stock = false;
    if (!data["name"] || !data["name"].toString().trim()) {
      name = true;
    }
    if (!data["stock"] || !data["stock"].toString().trim()) {
      stock = true;
    }
    if (
      !data["stock_type"] ||
      !data["stock"] ||
      !data["stock"].toString().trim()
    ) {
      stock = true;
    }
    setErrors({ name, stock });

    if (name || stock) {
      return;
    }

    setLoading(true);
    post("api/stocks/set", { cafe_id: getCafe(), data }).then((res) => {
      setLoading(false);

      if (res.result) {
        onChange();
        Store.setModal({});
      } else {
        if (res.error) {
          error(intl.formatMessage({ id: res.error }));
        } else {
          error();
        }
      }
    });
  }

  function removeStock(product_id) {
    setLoading(true);
    post("api/stocks/set", {
      cafe_id: getCafe(),
      data: { stock: -1, product_id },
    }).then((res) => {
      setLoading(false);
      onChange();
      setData({});
      Store.setModal({});
    });
  }

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = () => {
    if (name) {
      products.push({ name });
      setProducts([...products]);
      setName("");
    }
  };

  if (loading2) return <Spin className="gx-loader-container" />;

  return (
    <div>
      <Form
        style={{ margin: 20 }}
        layout="vertical"
        name="validate_other"
        onFinish={() => {
          setStock(data);
        }}
      >
        <Form.Item
          label={<IntlMessages id={"urunadi"} />}
          validateStatus={errors["name"] ? "error" : ""}
          help={errors["name"] ? <IntlMessages id={"gecersizbilgi"} /> : ""}
        >
          <Select
            value={data["product_id"]}
            onChange={(product_id) => {
              setErrors({ ...errors, name: false });
              try {
                let product = products.filter(
                  (e) => e.product_id == product_id
                )[0];
                setData({ ...product });
              } catch (e) {}
            }}
            showSearch
            loading={!Array.isArray(products) || !products.length}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            dropdownRender={(menu) => (
              <div>
                {menu}
                <Divider style={{ margin: "4px 0" }} />
                <div
                  style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}
                >
                  <Input
                    placeholder={intl.formatMessage({ id: "stokornek" })}
                    style={{ flex: "auto" }}
                    value={name}
                    onChange={onNameChange}
                  />
                  <a
                    style={{
                      flex: "none",
                      padding: "8px",
                      display: "block",
                      cursor: "pointer",
                    }}
                    onClick={addItem}
                  >
                    <PlusOutlined /> <IntlMessages id={"ekle"} />
                  </a>
                </div>
              </div>
            )}
          >
            {Array.isArray(products)
              ? products.map(({ product_id, name }) => (
                  <Select.Option value={product_id}>
                    {languageText(name)}
                  </Select.Option>
                ))
              : null}
          </Select>
        </Form.Item>
        <Form.Item
          label={"Stok"}
          validateStatus={errors["stock"] ? "error" : ""}
          help={errors["stock"] ? <IntlMessages id={"gecersizbilgi"} /> : ""}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <InputNumber
              value={data["stock"] > 0 ? data["stock"] : ""}
              style={{ width: 150 }}
              min={1}
              onChange={(stock) => {
                setErrors({ ...errors, stock: false });
                setData({ ...data, stock });
              }}
            />
            <Select
              value={data["stock_type"]}
              onChange={(stock_type) => {
                setErrors({ ...errors, stock: false });
                setData({ ...data, stock_type });
              }}
            >
              {Object.values(StockTypes).map(({ id, value }) => {
                return (
                  <Select.Option value={id}>
                    <IntlMessages id={value} />
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        </Form.Item>

        <Form.Item className=" gx-text-right gx-flex-row">
          {data["product_id"] ? (
            <Popconfirm
              title={<IntlMessages id={"stoksiluyari"} />}
              onConfirm={() => {
                removeStock(data["product_id"]);
              }}
            >
              <Button loading={loading} className="gx-mr-2" danger>
                <IntlMessages id={"sil"} />
              </Button>
            </Popconfirm>
          ) : null}
          <Button
            loading={loading}
            className="gx-mr-2"
            type="primary"
            htmlType="submit"
          >
            <IntlMessages id={"kaydet"} />
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(EditStock);
