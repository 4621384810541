import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import URLSearchParams from "url-search-params";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { ConfigProvider, Modal } from "antd";
import { IntlProvider, useIntl } from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../../routes/Login/SignIn";
import SignUp from "../../routes/Login/SignUp";
import LockScreen from "../../routes/Login/Lock";

import { setInitUrl } from "appRedux/actions/Auth";
import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType,
} from "appRedux/actions/Setting";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";
import { MStore } from "../../networking/stores/MainStore";
import Password from "../../routes/Login/Password";
import EmailControl from "../../routes/Login/EmailControl";
import CreateStaff from "../../routes/Login/CreateStaff";
import Home from "../../routes/Home";
import Token from "../../routes/Token";
import Promo from "../../routes/Promo";
import Register from "../../routes/Login/Register";
import { getUser } from "../../networking/Server";
import Setup from "../../routes/Setup";
import languageData from "../Topbar/languageData";
import { toParse } from "../../functions/json";
import { Authorities } from "../../constants/Config";
import { intervals } from "../../functions/intervals";
import { getNotificationToken } from "../../services/Notification";

const RestrictedRoute = ({
  component: Component,
  location,
  token,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      token ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: location },
          }}
        />
      )
    }
  />
);

const App = () => {
  const dispatch = useDispatch();
  const { locale, navStyle, layoutType } = useSelector(
    ({ settings }) => settings
  );
  const { token, initURL } = useSelector(({ auth }) => auth);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    let link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "/css/style.css";
    link.className = "gx-style";
    document.body.appendChild(link);
  });

  useEffect(() => {
    getNotificationToken();

    if (initURL === "") {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      dispatch(setThemeType(params.get("theme")));
    }
    if (params.has("nav-style")) {
      dispatch(onNavStyleChange(params.get("nav-style")));
    }
    if (params.has("layout-type")) {
      dispatch(onLayoutTypeChange(params.get("layout-type")));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [
    dispatch,
    initURL,
    layoutType,
    location.pathname,
    location.search,
    navStyle,
  ]);

  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("full-layout");
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove("full-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("boxed-layout");
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("full-layout");
      document.body.classList.add("framed-layout");
    }
  };

  const setNavStyle = (navStyle) => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add("full-scroll");
      document.body.classList.add("horizontal-layout");
    } else {
      document.body.classList.remove("full-scroll");
      document.body.classList.remove("horizontal-layout");
    }
  };

  useEffect(() => {
    intervals();

    let authorities = [];
    try {
      authorities = getUser(token)["authorities"];
      if (!Array.isArray(authorities)) authorities = [];
    } catch (e) {}

    let cafe = getUser(token)["cafe"];

    if (token && location.pathname != "/setup") {
      if (!cafe || !Array.isArray(cafe) || !cafe.length) {
        if (cafe && !cafe["cafe_id"]) {
          history.push("/setup");
          return;
        }
      }
    }

    if (location.pathname === "/") {
      if (!token) {
        localStorage.setItem("last_path", location.pathname);
        history.push("/signin");
      } else if (
        initURL === "" ||
        initURL === "/" ||
        initURL === "/signin" ||
        initURL.includes("/signin/") ||
        initURL === "/lock" ||
        initURL === "/emailcontrol" ||
        initURL === "/createstaff" ||
        initURL === "/setup" ||
        initURL.split("/")[1] === "password"
      ) {
        /*let flag = true;

        if (
          JSON.stringify(authorities) == "[1,5]" ||
          JSON.stringify(authorities) == "[1,11,14,16]" ||
          JSON.stringify(authorities) == "[1,11,14]"
        ) {
          flag = false;
          history.push("/product/list");
        } else {
          for (const { id: ids, path } of Authorities.find((e) =>
            e.id.includes(4)
          )["sub"]) {
            if (
              Array.isArray(ids)
                ? authorities.some((e) => ids.includes(e))
                : authorities.includes(ids)
            ) {
              flag = false;
              console.log("gridi", path);
              history.push("/" + path);
              break;
            }
          }
        }

        if (flag) history.push("/home");*/

        history.push("/home");
      } else {
        history.push(initURL);
      }
    } else if (location.pathname === "/lock") {
      if (!localStorage.getItem("lock")) {
        history.push("/");
      }
    }
  }, [token, initURL, location, history]);

  let currentAppLocale;

  //  localStorage.removeItem("language");

  let language = localStorage.getItem("language");
  if (language) language = toParse(language);
  else language = {};

  if (!language["locale"]) {
    var local = navigator.language || navigator.userLanguage;

    if (local.search("tr") != -1 || local.search("TR") != -1) {
      MStore.setLanguage(languageData[0]);
      localStorage.setItem("language", languageData[0]);
      currentAppLocale = AppLocale[languageData[0].locale];
    } else {
      MStore.setLanguage(languageData[1]);
      localStorage.setItem("language", languageData[1]);
      currentAppLocale = AppLocale[languageData[1].locale];
    }
  } else {
    currentAppLocale = AppLocale[language["locale"]];
  }

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Switch>
          <Route exact path="/signin/:login_token" component={SignIn} />
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/lock" component={LockScreen} />
          <Route exact path="/password/:token" component={Password} />
          <Route exact path="/emailcontrol" component={EmailControl} />
          <Route exact path="/createstaff" component={CreateStaff} />
          <Route exact path="/setup" component={Setup} />
          <Route exact path="/token/:token" component={Token} />
          <Route exact path="/promo/:promo_id/:index" component={Promo} />
          <RestrictedRoute
            path={`${match.url}`}
            token={token}
            location={location}
            component={MainApp}
          />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default memo(App);
