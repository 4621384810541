import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { post } from "../../networking/Server";
import { getCafe } from "../../functions/getCafe";
import { Spin, Button, Popconfirm, Tag } from "antd";
import { Store } from "../../networking/stores/Store";
import { error, success } from "../../functions/toast";
import IntlMessages from "../../util/IntlMessages";
import { useIntl } from "react-intl";
import Loading from "../../components/Loading";
import { OrderStatuses } from "../../constants/Config";
import { createLine, toEnglish } from "../../functions/turkishCharacters";
import { priceText } from "../../functions/priceText";
import moment from "moment";
import { Print } from "../../functions/EscPrinter";
import EscPosEncoder from "esc-pos-encoder";
import OrderDetail from "../Statistics/order_detail";
import { SyncOutlined } from "@ant-design/icons";
import { lang, languageText } from "../../functions/language";
import { getSelected } from "../../components/Options";

const OrderEdit = ({
  order_id = 0,
  order_products = [],
  cafe = {},
  order = {},
  online_payment = 0,
  onChange = () => {},
}) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const encoder = new EscPosEncoder();

  const intl = useIntl();

  function createAddition(cafe_name, order_id, order_products, online_payment) {
    let result = encoder.initialize().codepage("windows1254");

    result = result
      .underline(true)
      .bold(true)
      .align("center")
      .line(toEnglish(cafe_name))
      .underline(false)
      .bold(false)
      .align("left")
      .newline()
      .newline();

    order_products.map(({ count, options, price, product: { name }, note }) => {
      name = languageText(name);

      name = name + "X" + count;
      price = priceText(price, "");

      result = result.line(
        toEnglish(
          name + Array(createLine(name, price)).fill(" ").join("") + price
        ),
        40
      );

      options.map(({ title, multiple, require, options }) => {
        let selecteds = getSelected({ options, multiple }, false);

        if (Array.isArray(selecteds)) {
          for (let { name, price } of selecteds) {
            name = " -" + languageText(name);
            price = price ? priceText(price, "") : "";

            result = result
              .size("small")
              .line(
                toEnglish(
                  name +
                    Array(createLine(name, price, 40)).fill(" ").join("") +
                    price
                ),
                40
              )
              .size("normal");
          }
        }
      });
    });

    result = result
      .newline()
      .newline()
      .align("center")
      .line(toEnglish(online_payment ? lang(intl, "onlineodeme") : ""), 30)
      .size("small")
      .line(toEnglish(moment(new Date()).format("DD.MM.YYYY HH:mm:ss")), 30)
      .line(
        toEnglish(intl.formatMessage({ id: "siparis" }) + " ID: " + order_id),
        30
      )
      .line(
        toEnglish(
          intl.formatMessage({ id: "bizitercihettiginizicintesekkurederiz" })
        ),
        20
      );

    result = result.newline().newline().newline().newline().encode();

    Print(result);
  }

  function updateOrderStatus(order_status_id) {
    order = {
      ...order,
      order_follows: [
        {
          status: 1,
          createdAt: null,
          order_status_id,
          updatedAt: new Date(),
        },
      ],
      status: 1,
    };

    setLoading(order_status_id);

    post("api/order/set", { order, cafe_id: cafe.cafe_id }).then((res) => {
      setLoading(-1);
      if (res.result) {
        success();
        onChange();
        Store.setModal({});
      }
    });
  }

  return (
    <div>
      <div>
        <div>
          <Button
            className="gx-mr-2"
            type="primary"
            onClick={() => {
              createAddition(
                cafe.name,
                order_id,
                order_products,
                online_payment
              );
            }}
          >
            <IntlMessages id="adisyoncikar" />
          </Button>
        </div>
        {Object.values(OrderStatuses).map(({ id, value, color }) => {
          return (
            <Popconfirm
              disabled={loading}
              title={
                <div>
                  {intl
                    .formatMessage({ id: "siparisiniolarakguncelle" })
                    .replace("$$", intl.formatMessage({ id: value }))}
                </div>
              }
              onConfirm={() => {
                updateOrderStatus(id);
              }}
            >
              <Tag
                style={{ fontSize: 15, cursor: "pointer" }}
                key={id}
                icon={loading == id ? <SyncOutlined spin /> : null}
                color={color}
              >
                <IntlMessages id={value} />
              </Tag>
            </Popconfirm>
          );
        })}
      </div>
      <OrderDetail order_id={order_id} />
    </div>
  );
};

export default OrderEdit;
