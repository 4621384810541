import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { post } from "../../networking/Server";
import { Store } from "../../networking/stores/Store";
import { getCafe } from "../../functions/getCafe";
import { Avatar, Button, Card, Popconfirm, Tag } from "antd";
import IntlMessages from "../../util/IntlMessages";
import DataTable from "../../functions/dataTable";
import SurveyAdd from "./add";
import { error, success } from "../../functions/toast";
import { useIntl } from "react-intl";
import { lang } from "../../functions/language";
import OrderQr from "../OrderQR";
import SurveyQR from "../SurveyQR";

const Surveys = ({ history }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(0);

  const intl = useIntl();

  useEffect(() => {
    getSurveys();
  }, []);

  function getSurveys() {
    Store.setLoading(true);
    post("api/rates/get_list", { cafe_id: getCafe() }).then((res) => {
      Store.setLoading(false);
      setLoading(0);

      if (res.result) {
        setData(res.rates);
      }
    });
  }

  const onDelete = ({ data: rows }) => {
    const indexs = rows.map((item) => item.dataIndex);

    const ids = data.filter((e, i) => indexs.includes(i)).map((e) => e.rate_id);

    post("api/rates/delete", { cafe_id: getCafe(), ids });

    return true;
  };

  function onRemove(rate_id) {
    setLoading(rate_id);
    post("api/rates/remove", { cafe_id: getCafe(), rate_id }).then((res) => {
      if (res.result) {
        success();
        getSurveys();
      } else {
        setLoading(0);
        if (res.error) {
          error(intl.formatMessage({ id: res.error }));
        } else {
          error();
        }
      }
    });
  }

  const columns = [
    {
      name: "rate_id",
      label: "ID",
      options: {
        filter: false,
      },
    },
    {
      name: "question",
      label: lang(intl, "soru"),
      options: {
        filter: false,
        customBodyRender: (e) => (
          <div style={{ whiteSpace: "pre-line" }}>{e}</div>
        ),
      },
    },
    {
      name: "rate_type",
      label: lang(intl, "ankettipi"),
      options: {
        customBodyRender: (value) =>
          value == 1
            ? lang(intl, "emojianket")
            : value == 2
            ? lang(intl, "seceneklianket")
            : "",
      },
    },
    {
      name: "rate_options",
      label: lang(intl, "secenekler"),
      options: {
        sort: false,
        filter: false,
        searchable: false,
        customBodyRender: (value) => () => {
          let _value = "--";
          try {
            _value = value.map((e) => (
              <Tag color="warning">
                <div style={{ whiteSpace: "pre-line" }}>{e["option"]}</div>
              </Tag>
            ));
          } catch (e) {}

          return _value;
        },
      },
    },
    {
      name: "rate_id",
      label: " ",
      options: {
        sort: false,
        filter: false,
        searchable: false,
        customBodyRender: (rate_id) => {
          return (
            <div>
              <Popconfirm
                title={lang(intl, "anketkaldiruyari")}
                onConfirm={() => {
                  onRemove(rate_id);
                }}
              >
                <Button loading={loading == rate_id} className="gx-mr-2" danger>
                  {lang(intl, "kaldir")}
                </Button>
              </Popconfirm>
            </div>
          );
        },
      },
    },
  ];

  return (
    <div>
      <Button
        type="primary"
        onClick={() => {
          Store.setModal({
            title: <IntlMessages id={"ekle"} />,
            child: (
              <SurveyAdd
                onChange={() => {
                  getSurveys();
                }}
              />
            ),
          });
        }}
      >
        {lang(intl, "anketekle")}
      </Button>

      <Button
        type="warning"
        onClick={() => {
          Store.setModal({
            title: "Anket QR CODE",
            child: <SurveyQR />,
          });
        }}
      >
        QR CODE
      </Button>

      <DataTable
        title={<IntlMessages id={"anketler"} />}
        data={data.filter((e) => e?.status)}
        columns={columns}
        options={{
          selectableRowsHideCheckboxes: false,
          onRowsDelete: onDelete,
        }}
      />
    </div>
  );
};

export default observer(Surveys);
