import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { post } from "../../networking/Server";
import { getCafe, getCafes } from "../../functions/getCafe";
import {
  Input,
  TreeSelect,
  InputNumber,
  Form,
  Spin,
  Button,
  Select,
  Checkbox,
  Popconfirm,
  Popover,
  Switch,
} from "antd";
import { Store } from "../../networking/stores/Store";
import Options from "../../components/Options";
import ImagePicker from "../../components/ImagePicker";
import { error, success } from "../../functions/toast";
import IntlMessages from "../../util/IntlMessages";
import CKEditor from "react-ckeditor-component";
import { useIntl } from "react-intl";

const CampaignAdd = ({ campaign_id = 0, onChange = () => {} }) => {
  const [data, setData] = useState({});
  const [desc, setDesc] = useState("");
  const [loading, setLoading] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    if (campaign_id) {
      getCampaign(campaign_id);
    }
  }, []);

  function getCampaign(campaign_id, set = false) {
    post("api/campaigns/get", { cafe_id: getCafe(), campaign_id }).then(
      (res) => {
        setLoading(false);
        if (res.result) {
          if (set) {
            success();
            onChange(res.campaign);
            Store.setModal({});
          }
          setData(res.campaign);
          setDesc(res.campaign["desc"]);
        }
      }
    );
  }

  function onSet(values) {
    values = { ...data, ...values, desc };
    setLoading(true);
    post("api/campaigns/set", { cafe_id: getCafe(), data: values }).then(
      (res) => {
        if (res.result) {
          getCampaign(res.campaign_id, true);
        } else {
          setLoading(false);
          if (res.error) {
            error(intl.formatMessage({ id: res.error }));
          } else {
            error();
          }
        }
      }
    );
  }

  if (!data["campaign_id"] && campaign_id) {
    return <Spin className="gx-loader-container" />;
  }
  return (
    <div>
      <ImagePicker
        onChange={(images) => {
          setData({ ...data, image: images[0] });
        }}
        images={data["image"] ? [data["image"]] : []}
        maxCount={1}
        accept={".png, .jpg, .jpeg"}
      />
      <Form
        style={{ margin: 20 }}
        layout="vertical"
        name="validate_other"
        initialValues={data}
        onFinish={onSet}
      >
        <Form.Item
          name={"title"}
          label={"Başlık"}
          rules={[
            { required: true, message: <IntlMessages id={"gecersizbilgi"} /> },
          ]}
        >
          <Input maxLength={100} />
        </Form.Item>
        <Form.Item
          name={"short_desc"}
          label={"Kısa Açıklama"}
          rules={[{ required: false }]}
        >
          <Input.TextArea rows={3} maxLength={500} />
        </Form.Item>
        <Form.Item name={"desc"} label={"Açıklama"}>
          <CKEditor
            content={desc}
            activeClass="p10"
            events={{
              change: (evt) => {
                setDesc(evt.editor.getData());
              },
            }}
          />
        </Form.Item>

        <Form.Item className=" gx-text-right gx-flex-row">
          {campaign_id ? (
            <Button
              loading={loading}
              className="gx-mr-2"
              danger
              onClick={() => {
                onSet({ status: 0 });
              }}
            >
              Kampanyayı Bitir
            </Button>
          ) : null}
          <Button
            loading={loading}
            className="gx-mr-2"
            type="primary"
            htmlType="submit"
          >
            <IntlMessages id={"kaydet"} />
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(CampaignAdd);
