import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import DataTable from "../../functions/dataTable";
import { post } from "../../networking/Server";
import { Store } from "../../networking/stores/Store";
import { priceText } from "../../functions/priceText";
import { getCafe } from "../../functions/getCafe";
import { Avatar, Button, message } from "antd";
import { EditFilled, DragOutlined } from "@ant-design/icons";
import ProductAdd from "./add";
import Categories from "../Categories";
import IntlMessages from "../../util/IntlMessages";
import { useIntl } from "react-intl";
import { lang, languageText } from "../../functions/language";
import OrderQr from "../OrderQR";
import { error, success } from "../../functions/toast";
import ChangeOrder from "./changeOrder";

const ProductList = ({ history }) => {
  const intl = useIntl();
  const [data, setData] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  function getProducts(hide = false) {
    Store.setLoading(true);
    post("api/products/get_list", { cafe_id: getCafe() }).then((res) => {
      Store.setLoading(false);

      if (res.result) {
        if (hide) {
          hide();
          success();
        }

        setData(res.products);
      }
    });
  }

  const onDelete = ({ data: rows }) => {
    const indexs = rows.map((item) => item.dataIndex);

    const ids = data
      .filter((e, i) => indexs.includes(i))
      .map((e) => e.product_id);

    post("api/products/delete", { cafe_id: getCafe(), ids });

    return true;
  };

  const columns = [
    {
      name: "images",
      label: " ",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value) => {
          let image = "";
          try {
            image = JSON.parse(value)[0];
          } catch (e) {}
          return <Avatar src={image} className="gx-size-50" alt="" />;
        },
      },
    },
    {
      name: "product_id",
      label: "ID",
      options: {
        filter: false,
      },
    },
    {
      name: "name",
      label: lang(intl, "urunadi"),
      options: {
        filter: false,
        customBodyRender: (e) => languageText(e),
      },
    },
    {
      name: "category",
      label: lang(intl, "kategori"),
      options: {
        customBodyRender: (value) => languageText(value?.name),
      },
    },
    {
      name: "price",
      label: lang(intl, "fiyat"),
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value) => priceText(value),
      },
    },
    {
      name: "discount_price",
      label: lang(intl, "indirimlifiyat"),
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value) => (value ? priceText(value) : "--"),
      },
    },
    {
      name: "options",
      label: lang(intl, "opsiyonlar"),
      options: {
        sort: false,
        filter: false,
        searchable: false,
        customBodyRender: (value) => () => {
          let _value = "--";
          try {
            _value = value.map((e) => languageText(e.title)).join(", ");
          } catch (e) {}

          return _value;
        },
      },
    },
    {
      name: "active",
      label: lang(intl, "aktiflik"),
      options: {
        sort: false,
        filter: true,
        searchable: false,
        customBodyRender: (value) =>
          value ? (
            <div className="gx-text-primary">
              <IntlMessages id="aktif" />
            </div>
          ) : (
            <div className="gx-text-danger">
              <IntlMessages id="satisakapali" />
            </div>
          ),
      },
    },
    {
      name: "product_id",
      label: " ",
      options: {
        sort: false,
        filter: false,
        searchable: false,
        customBodyRender: (product_id) => {
          return (
            <div>
              <Button
                type="primary"
                shape="circle"
                icon={<EditFilled />}
                onClick={() => {
                  Store.setModal({
                    title: "Güncelle",
                    child: (
                      <ProductAdd
                        product_id={product_id}
                        onRemove={() => {
                          try {
                            let index = data.findIndex(
                              (e) => e.product_id == product_id
                            );
                            data.splice(index, 1);
                            setData([...data]);
                          } catch (e) {}
                        }}
                        onChange={(new_data) => {
                          try {
                            let index = data.findIndex(
                              (e) => e.product_id == product_id
                            );
                            data[index] = new_data;
                            setData([...data]);
                          } catch (e) {}
                        }}
                      />
                    ),
                  });
                }}
              />
            </div>
          );
        },
      },
    },
  ];

  return (
    <div>
      <Button
        type="primary"
        onClick={() => {
          Store.setModal({
            title: <IntlMessages id={"ekle"} />,
            child: (
              <ProductAdd
                onChange={() => {
                  getProducts();
                }}
              />
            ),
          });
        }}
      >
        <IntlMessages id={"urunekle"} />
      </Button>
      <Button
        type="primary"
        onClick={() => {
          Store.setModal({
            title: <IntlMessages id={"kategoriler"} />,
            child: (
              <Categories
                onChange={() => {
                  getProducts();
                }}
              />
            ),
          });
        }}
      >
        <IntlMessages id={"kategoriler"} />
      </Button>

      <Button
        type="primary"
        onClick={() => {
          Store.setModal({
            title: <IntlMessages id={"sirala"} />,
            child: (
              <ChangeOrder
                onChange={() => {
                  getProducts();
                }}
              />
            ),
          });
        }}
      >
        <IntlMessages id={"sirala"} />
      </Button>

      <Button
        type="warning"
        onClick={() => {
          Store.setModal({
            title: "Menu QR CODE",
            child: <OrderQr just_menu={true} />,
          });
        }}
      >
        Menu QR CODE
      </Button>

      <DataTable
        title={<IntlMessages id={"urunler"} />}
        data={data.filter((e) => e?.status)}
        columns={columns}
        options={{
          selectableRowsHideCheckboxes: false,
          onRowsDelete: onDelete,
        }}
      />
    </div>
  );
};

export default observer(ProductList);
