import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { post } from "../../networking/Server";
import { Store } from "../../networking/stores/Store";
import { getCafe } from "../../functions/getCafe";
import DataTable from "../../functions/dataTable";
import { getStockTypeName, StockTypes } from "../../constants/Config";
import { error, success } from "../../functions/toast";
import IntlMessages from "../../util/IntlMessages";
import { Button, Tag } from "antd";
import { EditFilled } from "@ant-design/icons";
import ProductAdd from "../Products/add";
import EditStock from "./edit";
import EditProductStock from "./edit_product_stock";
import { useIntl } from "react-intl";
import { lang, languageText } from "../../functions/language";

const ProductStocks = () => {
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    getProductStocks();
  }, []);

  function getProductStocks() {
    setLoading(true);
    post("api/stocks/get_product_stocks", { cafe_id: getCafe() }).then(
      (res) => {
        if (res.result) {
          setStocks(res.stocks);
          //alert(JSON.stringify(res.stocks))
        }
        setLoading(false);
      }
    );
  }

  const columns = [
    {
      name: "name",
      label: lang(intl, "urunadi"),
      options: {
        filter: false,
        customBodyRender: (e) => languageText(e),
      },
    },
    {
      name: "product_stocks",
      label: lang(intl, "kullanilanmalzemeler"),
      options: {
        filter: false,
        customBodyRender: (product_stocks) => {
          let tags = [];
          try {
            product_stocks.map(({ decrease_stock, subproduct }) => {
              tags.push(
                <Tag color={"warning"}>
                  {subproduct["name"] + " " + decrease_stock}
                  <IntlMessages
                    id={getStockTypeName(subproduct["stock_type"])}
                  />
                </Tag>
              );
            });
          } catch (e) {}

          return tags;
        },
      },
    },
    {
      name: "product_id",
      label: " ",
      options: {
        sort: false,
        filter: false,
        searchable: false,
        customBodyRender: (product_id) => {
          return (
            <div>
              <Button
                type="primary"
                shape="circle"
                icon={<EditFilled />}
                onClick={() => {
                  Store.setModal({
                    center: true,
                    title: <IntlMessages id={"malzemeguncelle"} />,
                    child: (
                      <EditProductStock
                        product_id={product_id}
                        onChange={() => {
                          getProductStocks();
                        }}
                      />
                    ),
                  });
                }}
              />
            </div>
          );
        },
      },
    },
  ];

  return (
    <div>
      <Button
        type="primary"
        onClick={() => {
          Store.setModal({
            center: true,
            title: <IntlMessages id={"malzemeekle"} />,
            child: (
              <EditProductStock
                onChange={() => {
                  getProductStocks();
                }}
              />
            ),
          });
        }}
      >
        <IntlMessages id={"malzemeekle"} />
      </Button>
      <DataTable
        loading={loading}
        title={<IntlMessages id={"stogabagliurunler"} />}
        data={stocks}
        columns={columns}
        options={{
          filter: false,
        }}
      />
    </div>
  );
};

export default observer(ProductStocks);
