import React, { useEffect, useState } from "react";

import IntlMessages from "util/IntlMessages";
import { getUser, post } from "../../networking/Server";
import { Store } from "../../networking/stores/Store";
import { observer } from "mobx-react-lite";
import { MStore } from "../../networking/stores/MainStore";
import IconWithTextCard from "../../components/Metrics/IconWithTextCard";
import {
  Row,
  Col,
  DatePicker,
  Select,
  Card,
  Avatar,
  Spin,
  Tag,
  Button,
} from "antd";
import CartPie from "../../components/Charts/CartPie";
import CardLine from "../../components/Charts/CartLine";
import CardBar from "../../components/Charts/CartBar";
import LinesEllipsis from "react-lines-ellipsis";
import {
  getAuthorities,
  getOrderStatusName,
  getOrderTypeName,
  getPaymentTypeName,
  OrderStatuses,
  StaffTypes,
} from "../../constants/Config";
import { getCafe } from "../../functions/getCafe";
import { l_moment } from "../../functions/l_moment";
import { toParse } from "../../functions/json";
import Loading from "../../components/Loading";
import StaticticsFilter from "../../components/StaticticsFilter";
import DataTable from "../../functions/dataTable";
import { EyeOutlined } from "@ant-design/icons";
import StaffEdit from "../Staffs/edit";
import { priceText } from "../../functions/priceText";
import ProductAdd from "../Products/add";
import OrderDetail from "./order_detail";
import { useIntl } from "react-intl";
import { lang, languageText } from "../../functions/language";

const OrderStatistics = () => {
  const [date, setDate] = useState({
    start: l_moment().set({
      hour: 5,
      minute: 0,
      second: 0,
      millisecond: 0,
    }),
    end: l_moment()
      .set({
        hour: 4,
        minute: 59,
        second: 59,
        millisecond: 0,
      })
      .add(1, "days"),
    period: "DAY",
  });
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({ orders: [] });
  const intl = useIntl();

  useEffect(() => {
    getData();
  }, [date]);

  function getData() {
    setLoading(true);
    post("api/statistics/orders", { date, _cafe_id: getCafe() }).then((res) => {
      setLoading(false);
      if (res.result) {
        setData(res.data);
      }
    });
  }

  const {
    orders,
    order_status,
    order_counts,
    products_5,
    products_all,
    payment_types,
  } = data;

  const columns = [
    {
      name: "order_id",
      label: "ID",
      options: {
        filter: false,
      },
    },
    {
      name: "order_type_id",
      label: lang(intl, "siparistipi"),
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value) => (
          <IntlMessages id={getOrderTypeName(value)} />
        ),
      },
    },
    {
      name: "order_tables",
      label: lang(intl, "masakategorisi"),
      options: {
        filter: true,
        filterType: "dropdown",
        customBodyRender: (table) => {
          let category_name = "";

          try {
            table = table[0];
            category_name = table["table"]["table_category"]["name"];
            if (!category_name) category_name = "";
          } catch (e) {
            category_name = "";
          }
          return category_name;
        },
      },
    },
    {
      name: "order_tables",
      label: lang(intl, "masanumarasi"),
      options: {
        filter: true,
        filterType: "dropdown",
        customBodyRender: (table) => {
          let table_num = "";
          try {
            table = table[0];
            table_num = table["table"]["table_num"];
            if (!table_num) table_num = "";
          } catch (e) {
            table_num = "";
          }
          return table_num.toString();
        },
      },
    },
    {
      name: "price",
      label: lang(intl, "fiyat"),
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value, { rowIndex }) => {
          try {
            return priceText(value, orders[rowIndex]["cafe"]["currency"]);
          } catch (e) {}
          return priceText(value, "");
        },
      },
    },
    {
      name: "cafe",
      label: lang(intl, "isletme"),
      options: {
        searchable: false,
        customBodyRender: (value) => value.name,
      },
    },
    {
      name: "orderAt",
      label: lang(intl, "sipariszamani"),
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value) => l_moment(value).format("DD/MM/YYYY HH:mm"),
      },
    },
    {
      name: "order_follows",
      label: lang(intl, "siparisdurumu"),
      options: {
        searchable: false,
        filterOptions: {
          logic: (location, filters, row) => {
            try {
              if (filters.length)
                return !filters.includes(
                  getOrderStatusName(location[0]["order_status_id"])
                );
              return false;
            } catch (e) {
              return false;
            }
          },
          names: Object.values(OrderStatuses).map(({ value }) => value),
        },
        customBodyRender: (value) => {
          try {
            let res = Object.values(OrderStatuses).filter(
              (e) => e.id == value[0]["order_status_id"]
            )[0];
            return (
              <div style={{ color: res.color }}>
                <IntlMessages id={res.value} />
              </div>
            );
          } catch (e) {
            return "--";
          }
        },
      },
    },
    {
      name: "order_id",
      label: " ",
      options: {
        sort: false,
        filter: false,
        searchable: false,
        customBodyRender: (order_id) => {
          return (
            <div>
              <Button
                type="primary"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => {
                  Store.setModal({
                    title: <IntlMessages id="detay" />,
                    child: <OrderDetail order_id={order_id} />,
                  });
                }}
              />
            </div>
          );
        },
      },
    },
  ];

  const columns_products = [
    {
      name: "product_id",
      label: "ID",
      options: {
        filter: false,
      },
    },
    {
      name: "name",
      label: lang(intl, "urunadi"),
      options: {
        filter: false,
        customBodyRender: (value, { rowIndex }) => {
          return languageText(value);
        },
      },
    },
    {
      name: "count",
      label: lang(intl, "adet"),
      options: {
        filter: false,
      },
    },
    {
      name: "price",
      label: lang(intl, "fiyat"),
      options: {
        filter: false,
        customBodyRender: (value, { rowIndex }) => {
          try {
            return priceText(value, orders[rowIndex]["cafe"]["currency"]);
          } catch (e) {}
          return priceText(value, "");
        },
      },
    },
  ];

  const currency = getCafe("currency");

  return (
    <div>
      <div className="ant-row-flex">
        <div>
          <h1>
            <IntlMessages id="siparisistatistikleri" />
          </h1>
          <p>
            <IntlMessages id="siparisistatistikleridesc" />
          </p>
        </div>
        <div className="gx-ml-auto gx-p-2 gx-card">
          <StaticticsFilter date={date} setDate={setDate} loading={loading} />
        </div>
      </div>

      <Row>
        <Col xl={12} lg={12} md={24} sm={24} xs={24}>
          <Card
            style={{ height: 460 }}
            className="gx-card"
            title={<IntlMessages id="siparisozeti" />}
          >
            <CardLine
              removeY
              area
              data={order_counts}
              height={370}
              format={({ x, y }) => {
                return {
                  order: l_moment(y).format("YYYY-MM-DD HH:mm:ss"),
                  y: l_moment(y).format("MMM Do"),
                  x: parseInt(x),
                };
              }}
            />
          </Card>
        </Col>
        <Col xl={12} lg={12} md={24} sm={24} xs={24}>
          <Card
            style={{ height: 460 }}
            className="gx-card"
            title={<IntlMessages id="trendurunler" />}
          >
            {Array.isArray(products_5) ? (
              !products_5.length ? (
                <div
                  style={{ height: "100%", width: "100%" }}
                  className="gx-flex-row gx-justify-content-center gx-align-items-center"
                >
                  <IntlMessages id="noistatis" />
                </div>
              ) : (
                products_5.map(({ product, order, value }) => {
                  let image = "";
                  try {
                    image = toParse(product, "images")[0];
                  } catch (e) {}
                  return (
                    <div>
                      <div className="gx-media gx-task-list-item gx-flex-nowrap">
                        <Avatar className="gx-mr-3 gx-size-36" src={image} />
                        <div className="gx-media-body gx-task-item-content">
                          <div className="gx-task-item-content-left">
                            <h5 className="gx-text-truncate gx-task-item-title">
                              {languageText(product?.name)}
                            </h5>
                            <p className="gx-text-grey gx-fs-sm gx-mb-0">
                              <span style={{ maxLines: 1 }} className="gx-link">
                                {order?.cafe?.name}
                              </span>
                              <LinesEllipsis
                                text={languageText(product?.description)}
                                maxLine="1"
                                ellipsis="..."
                                trimRight
                                basedOn="letters"
                              />
                            </p>
                          </div>
                          <div className="gx-task-item-content-right">
                            {value} <IntlMessages id="adet" />
                          </div>
                        </div>
                      </div>

                      <div className="gx-task-list-item gx-d-block gx-d-sm-none">
                        <h5 className="gx-text-primary gx-mb-0 gx-pointer" />
                      </div>
                    </div>
                  );
                })
              )
            ) : (
              <Loading height={380} />
            )}
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Card
            className="gx-card"
            style={{ paddingBottom: 50 }}
            title={
              getCafe("name") +
              " " +
              intl.formatMessage({ id: "siparisdurumlari" })
            }
          >
            <CartPie
              height={330}
              data={order_status}
              unit={""}
              format={({ name, value }) => {
                return {
                  name: <IntlMessages id={getOrderStatusName(name)} />,
                  value: priceText(value),
                  color: getOrderStatusName(name, "color"),
                };
              }}
              total_values={[1, 2, 3, 4, 5, 8]}
              header={({ total = 0 }) => (
                <div
                  className="gx-m-1 gx-p-2 gx-flex-row gx-align-items-center gx-rounded-base"
                  style={{ backgroundColor: "#e7faec" }}
                >
                  <div
                    className="gx-flex-row gx-justify-content-center gx-align-items-center gx-bg-primary gx-text-white gx-fs-xl gx-font-weight-bold gx-fs-xl gx-rounded-base"
                    style={{ padding: "7px 20px 7px 20px" }}
                  >
                    {priceText(total)}
                  </div>
                  <div className="gx-fs-lg gx-ml-2 gx-font-weight-semi-bold">
                    <IntlMessages id={"toplamsiparis"} />
                  </div>
                  <i className="icon icon-circle gx-fs-lg gx-m-2 gx-text-primary" />
                </div>
              )}
            />
          </Card>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Card
            className="gx-card"
            title={
              getCafe("name") + " " + intl.formatMessage({ id: "siparisozeti" })
            }
          >
            <CartPie
              height={330}
              data={payment_types}
              unit={currency}
              format={({ name, value }) => {
                return {
                  name: lang(intl, getPaymentTypeName(name)),
                  value: Math.abs(parseFloat(value).toFixed(1)),
                };
              }}
              total_values={[1, 2, 4]}
              header={({ total = 0 }) => (
                <div
                  className="gx-m-1 gx-p-2 gx-flex-row gx-align-items-center gx-rounded-base"
                  style={{ backgroundColor: "#e7faec" }}
                >
                  <div
                    className="gx-flex-row gx-justify-content-center gx-align-items-center gx-bg-primary gx-text-white gx-fs-xl gx-font-weight-bold gx-fs-xl gx-rounded-base"
                    style={{ padding: "7px 20px 7px 20px" }}
                  >
                    {total + currency}
                  </div>
                  <div className="gx-fs-lg gx-ml-2 gx-font-weight-semi-bold">
                    <IntlMessages id="toplamgelir" />
                  </div>
                  <i className="icon icon-circle gx-fs-lg gx-m-2 gx-text-primary" />
                </div>
              )}
            />
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <DataTable
            loading={loading}
            title={<IntlMessages id={"siparisler"} />}
            data={orders}
            columns={columns}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <DataTable
            loading={loading}
            title={<IntlMessages id={"urunler"} />}
            data={products_all}
            columns={columns_products}
          />
        </Col>
      </Row>
    </div>
  );
};

export default observer(OrderStatistics);
