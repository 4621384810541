import React, { useEffect, useRef, useState } from "react";
import { getURL, post } from "../../networking/Server";
import { Button, Carousel, Image, Layout } from "antd";
import { Store } from "../../networking/stores/Store";
import { FullscreenOutlined } from "@ant-design/icons";
import { io, Socket } from "socket.io-client";

const config = {
  jsonp: false,
  reconnection: true,
  reconnectionDelay: 100,
  reconnectionAttempts: 100000,
  transports: ["websocket"],
};

let socket;

const Promo = ({
  history,
  match: {
    params: { promo_id, index = 0 },
  },
}) => {
  const [data, setData] = useState({});
  const [visible, setVisible] = useState(!document.fullscreenElement);

  const slider = useRef(null);

  useEffect(() => {
    getPromos();
    let a = "";
  }, []);

  function getPromos() {
    Store.setLoading(true);
    post("api/promos/get_one", { promo_id }).then((res) => {
      Store.setLoading(false);
      if (res.result) {
        setData(res.promo);
        const { sliders } = res.promo;

        socket = io(getURL(), {
          ...config,
          query: { token: localStorage.getItem("token"), promo_id },
        });

        socket.on("connect", () => {
          socket.emit("login-promo", {
            promo_id,
            delay: sliders[0].delay,
            maxIndex: sliders.length,
          });
          socket.on("next-image", (index) => {
            try {
              slider.current.goTo(index);
              const el = document.getElementById("video-" + index);

              if (el) {
                el.play();
              }
            } catch {}
          });
          socket.on("reload-page", (index) => {
            try {
              window.location.reload();
            } catch {}
          });
        });
      }
    });
  }

  const { sliders } = data;

  let elem = document.documentElement;
  function openFullScreen() {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  }

  window.addEventListener("beforeunload", function () {
    socket.disconnect();
  });

  document.addEventListener("fullscreenchange", (event) => {
    if (document.fullscreenElement) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  });

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      {visible ? (
        <Button
          style={{ position: "absolute", top: 30, right: 30, zIndex: 10000 }}
          type="primary"
          icon={<FullscreenOutlined />}
          onClick={openFullScreen}
        />
      ) : null}
      <Carousel
        ref={slider}
        style={{
          width: "100%",
          height: "100%",
        }}
        effect="fade"
        afterChange={(index) => {
          /*setTimeout(() => {
            try {
              slider.current.next();
            } catch {}
          }, sliders[index].delay * 1000);*/

          let _index = index;

          if (index + 1 > sliders.length) _index = 0;
          else _index = 0;

          socket.emit("changed-image", {
            promo_id,
            index,
            delay: sliders[index].delay,
          });
        }}
      >
        {Array.isArray(sliders)
          ? sliders.map(({ url }, i) => (
              <div>
                <div>
                  <div
                    style={{
                      background: `url(${url[index]}) no-repeat`,
                      backgroundSize: "cover",
                      zIndex: -1,
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      left: 0,
                      top: 0,
                      filter: "blur(15px)",
                      transform: "scale(1.2)",
                    }}
                  />
                  {url[index].includes(".mp4") ||
                  url[index].includes(".avi") ? (
                    <video
                      id={"video-" + i}
                      style={{
                        zIndex: 2000,
                        objectFit: "contain",
                        width: "100vw",
                        height: "100vh",
                      }}
                      src={url[index]}
                    />
                  ) : (
                    <img
                      style={{
                        zIndex: 2000,
                        objectFit: "contain",
                        width: "100vw",
                        height: "100vh",
                      }}
                      src={url[index]}
                    />
                  )}
                </div>
              </div>
            ))
          : null}
      </Carousel>
    </div>
  );
};

export default Promo;
