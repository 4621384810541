import {getUser} from "../networking/Server";
import {Store} from "../networking/stores/Store";
import {MStore} from "../networking/stores/MainStore";

export function getCafe(key="cafe_id") {
  let cafe=MStore.cafe;
  let res;

  try {
    if(!cafe['cafe_id']){
      let cafe=getCafes();
      if(Array.isArray(cafe)){
        res=cafe[0][key]
      }else{
        res=cafe[key]
      }
    }else{
      res = cafe[key]
    }

  }catch (e) {

  }

  return res;
}


export function getCafes() {
  let cafes=[];

  try {
    cafes=getUser()['cafe'];
    if(!cafes){
      cafes=[]
    }
    if(!Array.isArray(cafes)){
      cafes=[cafes];
    }

  }catch (e) {

  }

  return cafes;
}
