import React, { useEffect, useRef, useState } from "react";

import { observer } from "mobx-react-lite";
import { post } from "../../networking/Server";
import { getCafe, getCafes } from "../../functions/getCafe";
import {
  Input,
  TreeSelect,
  InputNumber,
  Form,
  Spin,
  Button,
  Select,
  Checkbox,
  Popconfirm,
  Popover,
  Switch,
  Modal,
} from "antd";
import { Store } from "../../networking/stores/Store";
import Options from "../../components/Options";
import ImagePicker from "../../components/ImagePicker";
import { error, success } from "../../functions/toast";
import IntlMessages from "../../util/IntlMessages";
import { useIntl } from "react-intl";
import { toJSON, toParse } from "../../functions/json";
import MultiLanguage, {
  defaultMenuLanguage,
} from "../../components/MultiLanguage";
import {
  decodingLangFormat,
  encodingLangFormat,
  languageText,
  translate,
} from "../../functions/language";

const ProductAdd = ({
  product_id = 0 /*1211*/,
  onChange = () => {},
  onRemove = () => {},
}) => {
  const [data, setData] = useState({});
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading_lang, setLoadingLang] = useState(false);

  const intl = useIntl();
  const form = useRef();

  const multi_keys = ["name", "description", "calorie", "time"];
  const d_lang = defaultMenuLanguage(intl.locale);

  useEffect(() => {
    if (product_id) {
      getProduct(product_id);
    }
    getCategories();
  }, []);

  function getProduct(product_id, set = false) {
    post("api/products/get_one", { cafe_id: getCafe(), product_id }).then(
      (res) => {
        setLoading(false);
        if (res.result && res.product) {
          if (set) {
            success();
            onChange(res.product);
            Store.setModal({});
            return;
          }

          res.product["images"] = toParse(res.product["images"]);
          res.product = decodingLangFormat(res.product, multi_keys);

          setData(res.product);
        }
      }
    );
  }
  function getCategories() {
    post("api/products/get_categories", { cafe_id: getCafe() }).then((res) => {
      if (res.result) {
        convertCategories(res.categories);
      }
    });
  }

  function convertCategories(categories) {
    let _cat = [];
    try {
      _cat = JSON.parse(
        JSON.stringify(categories)
          .replaceAll("category_id", "value")
          .replaceAll("name", "title")
          .replaceAll("subcategory", "children")
      );

      _cat = _cat.map(({ title, value, children }) => ({
        title: languageText(title),
        value,
        children: children.map(({ title, value, children }) => ({
          title: languageText(title),
          value,
          children,
        })),
      }));
    } catch (e) {}

    console.warn(_cat);
    setCategories(_cat);
  }

  function onSet(values) {
    values = { ...data, ...values };
    values["images"] = toJSON(values["images"]);

    values = encodingLangFormat(values, multi_keys);

    setLoading(true);
    post("api/products/set", { cafe_id: getCafe(), data: values }).then(
      (res) => {
        if (res.result) {
          if (values.status == 0) {
            success();
            onRemove();
            Store.setModal({});
            return;
          }
          getProduct(res.product_id, true);
        } else {
          setLoading(false);
          if (res.error) {
            error(intl.formatMessage({ id: res.error }));
          } else {
            error();
          }
        }
      }
    );
  }

  const currenct = getCafe("currency");

  if (!data["product_id"] && product_id) {
    return <Spin className="gx-loader-container" />;
  }
  return (
    <div>
      <Form
        ref={form}
        style={{ margin: 20 }}
        layout="vertical"
        name="validate_other"
        initialValues={data}
        onFinish={onSet}
        onValuesChange={(values) => {
          setData({ ...data, ...values });
        }}
      >
        <Form.Item
          name={"images"}
          valuePropName={"images"}
          label={<IntlMessages id={"resimler"} />}
        >
          <ImagePicker title={""} multiple accept={".png, .jpg, .jpeg"} />
        </Form.Item>

        <MultiLanguage
          loading={loading_lang}
          onLoad={(from, to) => {
            setLoadingLang(true);
            translate(intl, data, to, multi_keys).then((e) => {
              setLoadingLang(false);
              form.current.setFieldsValue(e);
            });
          }}
        >
          {(lang) => (
            <div>
              {product_id ? (
                <Form.Item
                  label={<IntlMessages id={"aktiflik"} />}
                  valuePropName={"checked"}
                  name={"active"}
                  rules={[{ required: false }]}
                >
                  <Switch checkedChildren={<IntlMessages id={"aktif"} />} />
                </Form.Item>
              ) : null}
              <Form.Item
                name={["name", lang]}
                label={<IntlMessages id={"urunadi"} />}
                rules={
                  d_lang == lang
                    ? [
                        {
                          required: true,
                          message: <IntlMessages id={"gecersizbilgi"} />,
                        },
                      ]
                    : []
                }
              >
                <Input maxLength={100} />
              </Form.Item>
              <Form.Item
                name={["description", lang]}
                label={<IntlMessages id={"urunaciklamasi"} />}
                rules={[{ required: false }]}
              >
                <Input.TextArea rows={3} maxLength={500} />
              </Form.Item>
              <Form.Item
                name={"score"}
                label={"Sadakat Puanı"}
                rules={[{ required: false }]}
              >
                <InputNumber min={0} />
              </Form.Item>
              <Form.Item
                name={"price"}
                label={<IntlMessages id={"fiyat"} />}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"gecersizbilgi"} />,
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  formatter={(value) =>
                    `${currenct} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) =>
                    value.replace(new RegExp(currenct + "s?|(,*)", "g"), "")
                  }
                />
              </Form.Item>
              <Form.Item
                name={"discount_price"}
                label={<IntlMessages id={"indirimlifiyat"} />}
                rules={[{ required: false }]}
              >
                <InputNumber
                  min={0}
                  max={data["price"] - 0.1}
                  formatter={(value) =>
                    `${currenct} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) =>
                    value.replace(new RegExp(currenct + "s?|(,*)", "g"), "")
                  }
                />
              </Form.Item>

              <Form.Item
                style={{ maxWidth: "50%" }}
                name={["calorie", lang]}
                label={<IntlMessages id={"urunkalorisi"} />}
                rules={[{ required: false }]}
              >
                <Input maxLength={50} />
              </Form.Item>
              <Form.Item
                style={{ maxWidth: "50%" }}
                name={["time", lang]}
                label={<IntlMessages id={"urunhazirlanmasuresi"} />}
                rules={[{ required: false }]}
              >
                <Input maxLength={50} />
              </Form.Item>

              <Form.Item
                name={"options"}
                label={<IntlMessages id={"opsiyonlar"} />}
                rules={[{ required: false }]}
                valuePropName={"options"}
              >
                <Options
                  onAdd={(option, index) => {
                    let options = data["options"];
                    if (!Array.isArray(options)) options = [];

                    if (index != undefined) {
                      options[index] = option;
                    } else {
                      options.push(option);
                    }

                    data["options"] = options;
                    setData({ ...data });
                    form.current.setFieldsValue(data);
                  }}
                  onRemove={(index) => {
                    let options = data["options"];
                    try {
                      options.splice(index, 1);
                      data["options"] = options;
                      setData({ ...data });
                      form.current.setFieldsValue(data);
                    } catch (e) {}
                  }}
                />
              </Form.Item>

              <Form.Item
                name={"category_id"}
                label={<IntlMessages id={"kategori"} />}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"gecersizbilgi"} />,
                  },
                ]}
              >
                <TreeSelect
                  loading={!Array.isArray(categories) || !categories.length}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  treeData={categories}
                  treeDefaultExpandAll
                />
              </Form.Item>

              <Form.Item
                name={"cafe_id"}
                label={<IntlMessages id={"isletme"} />}
                initialValue={getCafe()}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"gecersizbilgi"} />,
                  },
                ]}
              >
                <Select disabled={!product_id}>
                  {getCafes().map(({ cafe_id, name }) => {
                    return (
                      <Select.Option value={cafe_id}>{name}</Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          )}
        </MultiLanguage>

        <Form.Item className=" gx-text-right gx-flex-row">
          {product_id ? (
            <Popconfirm
              title={<IntlMessages id={"urunsilmeuyari"} />}
              onConfirm={() => {
                onSet({ status: 0 });
              }}
            >
              <Button loading={loading} className="gx-mr-2" danger>
                <IntlMessages id={"sil"} />
              </Button>
            </Popconfirm>
          ) : null}
          <Button
            loading={loading}
            className="gx-mr-2"
            type="primary"
            htmlType="submit"
          >
            <IntlMessages id={"kaydet"} />
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(ProductAdd);
