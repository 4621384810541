import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { post } from "../../networking/Server";
import { getCafe } from "../../functions/getCafe";
import {
  Spin,
  Button,
  Popconfirm,
  Tag,
  Form,
  Select,
  Card,
  Divider,
  Avatar,
} from "antd";
import { Store } from "../../networking/stores/Store";
import { error, success } from "../../functions/toast";
import IntlMessages from "../../util/IntlMessages";
import { useIntl } from "react-intl";
import Loading from "../../components/Loading";
import { OrderStatuses } from "../../constants/Config";
import { createLine, toEnglish } from "../../functions/turkishCharacters";
import { priceText } from "../../functions/priceText";
import moment from "moment";
import { Print } from "../../functions/EscPrinter";
import EscPosEncoder from "esc-pos-encoder";
import OrderDetail from "../Statistics/order_detail";
import { SyncOutlined } from "@ant-design/icons";
import { lang, languageText } from "../../functions/language";
import Options, { getSelected } from "../../components/Options";
import { toParse } from "../../functions/json";

const TableOrders = ({ onChange = () => {} }) => {
  const [selected_table_cat, setSelectedTableCat] = useState({});
  const [selected_table, setSelectedTable] = useState({});
  const [data, setData] = useState({});
  const [orders, setOrders] = useState([]);
  const [tables, setTables] = useState([]);
  const [get_loading, setGetLoading] = useState(false);
  const [table_loading, setTableLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const intl = useIntl();

  const encoder = new EscPosEncoder();

  useEffect(() => {
    getTables();
  }, []);

  function getTables() {
    setTableLoading(true);

    post("api/order/get-tables", {
      cafe_id: getCafe(),
    }).then((res) => {
      setTableLoading(false);

      if (res.result) {
        setTables([...res.tables]);
      } else {
        setTables([]);
      }
    });
  }

  function getTableOrders(table_id) {
    setGetLoading(true);

    post("api/order/get-order-table", {
      table_id,
    }).then((res) => {
      setGetLoading(false);

      if (res.result) {
        setData({ ...combineOrders(res.orders) });
        setOrders([...res.orders]);
      } else {
        error();
      }
    });
  }

  function updateOrderStatus(order_status_id) {
    let _orders = orders.map((order) => ({
      ...order,
      order_follows: [
        {
          status: 1,
          createdAt: null,
          order_status_id,
          updatedAt: new Date(),
        },
      ],
      status: 1,
    }));

    setLoading(order_status_id);

    post("api/order/set-multi", {
      orders: _orders,
      cafe_id: getCafe(),
    }).then((res) => {
      setLoading(-1);
      if (res.result) {
        success();
        onChange();
        Store.setModal({});
      }
    });
  }

  function createAddition(cafe_name, order_products) {
    let result = encoder.initialize().codepage("windows1254");

    result = result
      .underline(true)
      .bold(true)
      .align("center")
      .line(toEnglish(cafe_name))
      .underline(false)
      .bold(false)
      .align("left")
      .newline()
      .newline();

    order_products.map(({ count, options, price, product: { name }, note }) => {
      name = languageText(name);

      name = name + "X" + count;
      price = priceText(price, "");

      result = result.line(
        toEnglish(
          name + Array(createLine(name, price)).fill(" ").join("") + price
        ),
        40
      );

      options.map(({ title, multiple, require, options }) => {
        let selecteds = getSelected({ options, multiple }, false);

        if (Array.isArray(selecteds)) {
          for (let { name, price } of selecteds) {
            name = " -" + languageText(name);
            price = price ? priceText(price, "") : "";

            result = result
              .size("small")
              .line(
                toEnglish(
                  name +
                    Array(createLine(name, price, 40)).fill(" ").join("") +
                    price
                ),
                40
              )
              .size("normal");
          }
        }
      });
    });

    result = result
      .newline()
      .newline()
      .align("center")
      .size("small")
      .line(toEnglish(moment(new Date()).format("DD.MM.YYYY HH:mm:ss")), 30)
      .line(
        toEnglish(
          intl.formatMessage({ id: "bizitercihettiginizicintesekkurederiz" })
        ),
        20
      );

    result = result.newline().newline().newline().newline().encode();

    Print(result);
  }

  function combineOrders(orders) {
    let new_order = {
      order_products: [],
      order_tables: [],
      customers: [],
    };

    let order_products = [];
    let combined_order_products = [];

    for (const { order_products: products, order_tables, customer } of orders) {
      new_order["order_tables"].push(...order_tables);
      new_order["customers"].push(customer);

      order_products.push(...products);
    }
    for (const order_product of order_products) {
      let index = combined_order_products.findIndex(
        (e) =>
          e.product_id == order_product.product_id &&
          JSON.stringify(e.options) == JSON.stringify(order_product.options) &&
          e.note == order_product.note
      );
      if (index != -1) {
        combined_order_products[index]["count"] += order_product.count;
        combined_order_products[index]["price"] += order_product.price;
      } else {
        combined_order_products.push(order_product);
      }
    }

    new_order["customers"] = [
      ...new Map(
        new_order["customers"].map((item) => [item["customer_id"], item])
      ).values(),
    ];

    new_order["order_tables"] = [
      ...new Map(
        new_order["order_tables"].map((item) => [item["table_num"], item])
      ).values(),
    ];

    new_order["order_products"] = combined_order_products;

    return new_order;
  }

  function createTotal(orders) {
    let total = 0;
    try {
      for (const { price } of orders["order_products"]) {
        total += price;
      }
    } catch (e) {
      total = 0;
    }

    return total;
  }

  return (
    <div>
      <div className="gx-m-1">Masa Seç</div>

      <Form
        style={{ maxWidth: 200, margin: 20 }}
        layout="vertical"
        name="validate_other"
      >
        <Form.Item>
          <Select
            value={selected_table_cat["table_category_id"]}
            loading={table_loading}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder={"Masa Kategorisi"}
            onChange={(table_category_id) => {
              let table = tables.find(
                (e) => e.table_category_id == table_category_id
              );

              if (table) {
                setSelectedTableCat(table);
                setSelectedTable({});
              }
            }}
          >
            {Array.isArray(tables)
              ? tables.map(({ table_category_id, name }) => (
                  <Select.Option value={table_category_id}>
                    {name}
                  </Select.Option>
                ))
              : null}
          </Select>
        </Form.Item>

        <Form.Item>
          {selected_table_cat["table_category_id"] ? (
            <Select
              value={selected_table["table_id"]}
              onChange={(table_id) => {
                let table = selected_table_cat["tables"].find(
                  (e) => e.table_id == table_id
                );

                if (table) {
                  setSelectedTable(table);
                }

                getTableOrders(table_id);
              }}
              showSearch
              optionFilterProp="children"
              placeholder={"Masa Numarası"}
            >
              {Array.isArray(selected_table_cat["tables"])
                ? selected_table_cat["tables"].map(
                    ({ table_id, table_num }) => (
                      <Select.Option value={table_id}>
                        {table_num}
                      </Select.Option>
                    )
                  )
                : null}
            </Select>
          ) : null}
        </Form.Item>
      </Form>
      {get_loading ? <Spin className="gx-loader-container" /> : null}

      {Array.isArray(data["order_products"]) &&
      data["order_products"].length ? (
        <>
          <Divider className="gx-mt-5" dashed orientation="left">
            <IntlMessages id="islemler" />
          </Divider>
          <div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Button
                style={{ display: "flex", alignSelf: "flex-end" }}
                className="gx-mr-2"
                type="primary"
                onClick={() => {
                  createAddition(getCafe("name"), data.order_products);
                }}
              >
                <IntlMessages id="adisyoncikar" />
              </Button>
              <div>
                {Object.values(OrderStatuses).map(({ id, value, color }) => {
                  return (
                    <Popconfirm
                      disabled={loading}
                      title={
                        <div>
                          {intl
                            .formatMessage({ id: "siparisiniolarakguncelle" })
                            .replace("$$", intl.formatMessage({ id: value }))}
                        </div>
                      }
                      onConfirm={() => {
                        updateOrderStatus(id);
                      }}
                    >
                      <Tag
                        style={{ fontSize: 15, cursor: "pointer" }}
                        key={id}
                        icon={loading == id ? <SyncOutlined spin /> : null}
                        color={color}
                      >
                        <IntlMessages id={value} />
                      </Tag>
                    </Popconfirm>
                  );
                })}
              </div>
              <div
                style={{
                  padding: 15,
                  display: "flex",
                  alignSelf: "flex-end",
                  justifyContent: "flex-end",
                  flexDirection: "row",
                  fontSize: 18,
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 5,
                  }}
                >
                  Toplam:
                </span>
                <span style={{ fontSize: 22, fontWeight: "bold" }}>
                  {priceText(createTotal(data))}
                </span>
              </div>
            </div>
          </div>

          <Divider className="gx-mt-5" dashed orientation="left">
            <IntlMessages id="musteri" />
          </Divider>
          <div>
            {Array.isArray(data["customers"])
              ? data["customers"].map(
                  ({ image, name, surname, email, phone }, index) => {
                    return (
                      <div className="gx-media gx-task-list-item gx-flex-nowrap">
                        <Avatar className="gx-size-36" src={image} />
                        <div className="gx-media-body gx-task-item-content">
                          <div
                            className="gx-task-item-content-left"
                            style={{ width: "50%" }}
                          >
                            <h5 className="gx-text-truncate gx-task-item-title">
                              {name + " " + surname}
                            </h5>
                            <p className="gx-text-grey gx-fs-sm gx-mb-2">
                              {email}
                            </p>
                          </div>
                          <div className="gx-task-item-content-right">
                            <div style={{ fontWeight: "bold", color: "red" }}>
                              {phone}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )
              : null}
          </div>

          <Divider className="gx-mt-5" dashed orientation="left">
            <IntlMessages id="masa" />
          </Divider>
          <div>
            {Array.isArray(data["order_tables"])
              ? data["order_tables"].map(
                  (
                    {
                      table: {
                        table_num,
                        table_category: { name },
                      },
                    },
                    index
                  ) => {
                    return <Tag key={index}>{name + " " + table_num}</Tag>;
                  }
                )
              : null}
          </div>

          <Divider className="gx-mt-5" dashed orientation="left">
            <IntlMessages id="urunler" />
          </Divider>
          <div>
            {Array.isArray(data["order_products"])
              ? data["order_products"].map(
                  ({ product, count, options, price, note }, index) => {
                    console.warn(product);
                    let { name, images, category } = product;
                    name = languageText(name);
                    images = toParse(images);
                    images.length ? (images = images[0]) : (images = "");

                    return (
                      <div
                        key={index}
                        className="gx-media gx-task-list-item gx-flex-nowrap"
                      >
                        <Avatar className="gx-size-36" src={images} />
                        <div className="gx-media-body gx-task-item-content">
                          <div className="gx-task-item-content-left gx-w-100">
                            <h5 className="gx-text-truncate gx-task-item-title">
                              {name} X {count}
                            </h5>
                            <p className="gx-flex-row gx-text-grey gx-fs-sm gx-mb-2">
                              <div className="gx-mr-1">
                                <IntlMessages id="opsiyonlar" />:
                              </div>
                              <Options disabled options={options} />
                            </p>
                            {note ? (
                              <p className="gx-flex-row gx-text-grey gx-fs-sm gx-mb-2">
                                <div className="gx-mr-1">
                                  Not:{" "}
                                  <span className="gx-text-secondary">
                                    {note}
                                  </span>
                                </div>
                              </p>
                            ) : null}
                          </div>
                          <div className="gx-task-item-content-right">
                            {priceText(price)}
                          </div>
                        </div>
                      </div>
                    );
                  }
                )
              : null}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default TableOrders;
