import React, { useEffect, useRef, useState } from "react";

import { observer } from "mobx-react-lite";
import { post } from "../../networking/Server";
import { getCafe, getCafes } from "../../functions/getCafe";
import {
  Input,
  TreeSelect,
  InputNumber,
  Form,
  Spin,
  Button,
  Select,
  Checkbox,
  Popconfirm,
  Popover,
  Switch,
  Modal,
} from "antd";
import { Store } from "../../networking/stores/Store";
import Options from "../../components/Options";
import ImagePicker from "../../components/ImagePicker";
import { error, success } from "../../functions/toast";
import IntlMessages from "../../util/IntlMessages";
import { useIntl } from "react-intl";
import { toJSON, toParse } from "../../functions/json";
import MultiLanguage, {
  defaultMenuLanguage,
} from "../../components/MultiLanguage";
import {
  decodingLangFormat,
  encodingLangFormat,
  languageText,
  translate,
} from "../../functions/language";

const AddressAdd = ({ cafe_address_id = 0 /*1211*/, onChange = () => {} }) => {
  const [data, setData] = useState({ min_price: 0, price: 0 });
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [d_loading, setDLoading] = useState(false);

  const form = useRef();
  const intl = useIntl();

  useEffect(() => {
    if (cafe_address_id) {
      getAddress(cafe_address_id);
    }

    getCitys(1);
  }, []);

  function getAddress(cafe_address_id, set = false) {
    post("api/cafe/get_address_one", {
      cafe_id: getCafe(),
      cafe_address_id,
    }).then((res) => {
      setLoading(false);
      if (res.result && res.address) {
        res.address["minute"] = JSON.parse(res.address["minute"])[1] - 5;
        setData(res.address);
      }
    });
  }

  function getCitys(country_id) {
    post("api/cafe/get_cities", { country_id }).then((res) => {
      if (res.result) {
        setDistricts([]);
        setCities(res.cities);
      }
    });
  }
  function getDistricts(city_id) {
    setDLoading(true);
    post("api/cafe/get_districts", { city_id }).then((res) => {
      setDLoading(false);
      if (res.result) {
        setDistricts(res.districts);
      }
    });
  }

  function onSet(values) {
    values = { ...data, ...values };

    setLoading(true);
    post("api/cafe/add_address", {
      cafe_id: getCafe(),
      cafe_address_id,
      ...values,
    }).then((res) => {
      if (res.result) {
        success();
        onChange();
        Store.setModal({});
      } else {
        setLoading(false);
        if (res.error) {
          error(intl.formatMessage({ id: res.error }));
        } else {
          error();
        }
      }
    });
  }

  const currenct = getCafe("currency");

  if (!data["cafe_address_id"] && cafe_address_id) {
    return <Spin className="gx-loader-container" />;
  }
  return (
    <div>
      <Form
        ref={form}
        style={{ margin: 20 }}
        layout="vertical"
        name="validate_other"
        initialValues={data}
        onFinish={onSet}
        onValuesChange={(values) => {
          setData({ ...data, ...values });
        }}
      >
        <Form.Item
          label={<IntlMessages id="ulke" />}
          rules={[
            {
              required: true,
              message: <IntlMessages id={"gecersizbilgi"} />,
            },
          ]}
        >
          <Select
            value={1}
            disabled
            loading={!Array.isArray(cities) || !cities.length}
          >
            <Select.Option value={1}>{"Türkiye"}</Select.Option>
          </Select>
        </Form.Item>

        {!cafe_address_id ? (
          <Form.Item
            name={"city_id"}
            label={<IntlMessages id="sehir" />}
            rules={[
              {
                required: true,
                message: <IntlMessages id={"gecersizbilgi"} />,
              },
            ]}
          >
            <Select
              loading={!Array.isArray(cities) || !cities.length}
              onChange={(city_id) => {
                form.current.setFieldsValue({ district_id: null });
                setDistricts([]);
                getDistricts(city_id);
              }}
            >
              {cities.map(({ city_id, name }) => {
                return <Select.Option value={city_id}>{name}</Select.Option>;
              })}
            </Select>
          </Form.Item>
        ) : null}

        {!cafe_address_id ? (
          <Form.Item
            name={"district_id"}
            label={<IntlMessages id="ilce" />}
            rules={[
              {
                required: true,
                message: <IntlMessages id={"gecersizbilgi"} />,
              },
            ]}
          >
            <Select onChange={() => {}} loading={d_loading}>
              {districts.map(({ district_id, name }) => {
                return (
                  <Select.Option value={district_id}>{name}</Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        ) : null}

        <Form.Item
          name={"min_price"}
          label={<IntlMessages id="minimumsiparistutari" />}
          rules={[
            {
              required: true,
              message: <IntlMessages id={"gecersizbilgi"} />,
            },
          ]}
        >
          <InputNumber
            min={0}
            formatter={(value) =>
              `${currenct} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) =>
              value.replace(new RegExp(currenct + "s?|(,*)", "g"), "")
            }
          />
        </Form.Item>
        <Form.Item
          name={"price"}
          label={<IntlMessages id={"servisucreti"} />}
          rules={[
            {
              required: true,
              message: <IntlMessages id={"gecersizbilgi"} />,
            },
          ]}
        >
          <InputNumber
            min={0}
            formatter={(value) =>
              `${currenct} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) =>
              value.replace(new RegExp(currenct + "s?|(,*)", "g"), "")
            }
          />
        </Form.Item>
        <Form.Item
          name={"minute"}
          label={<IntlMessages id="servissuresi" />}
          rules={[
            {
              required: true,
              message: <IntlMessages id={"gecersizbilgi"} />,
            },
          ]}
        >
          <InputNumber min={1} />
        </Form.Item>

        <Form.Item className=" gx-text-right gx-flex-row">
          {cafe_address_id ? (
            <Popconfirm
              title={<IntlMessages id={"urunsilmeuyari"} />}
              onConfirm={() => {
                onSet({ status: 0 });
              }}
            >
              <Button loading={loading} className="gx-mr-2" danger>
                <IntlMessages id={"sil"} />
              </Button>
            </Popconfirm>
          ) : null}
          <Button
            loading={loading}
            className="gx-mr-2"
            type="primary"
            htmlType="submit"
          >
            <IntlMessages id={"kaydet"} />
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(AddressAdd);
