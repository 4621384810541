import moment from "moment";
import "moment/locale/tr";
import "moment/locale/zh-cn";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/ar";
import "moment/locale/ru";
import { MStore } from "../networking/stores/MainStore";

export function l_moment(date, format) {
  moment.locale(MStore.language.locale);
  return moment(date, format);
}
