import React, {useEffect, useRef, useState} from "react";

import { observer } from "mobx-react-lite";
import {post } from "../../networking/Server";
import { getCafe } from "../../functions/getCafe";
import {
  Input,
  Form,
  Spin,
  Button,
  Popconfirm
} from "antd";
import ImagePicker from "../../components/ImagePicker";
import IntlMessages from "../../util/IntlMessages";
import { useIntl } from "react-intl";
import {decodingLangFormat, encodingLangFormat, translate} from "../../functions/language";
import MultiLanguage, {defaultMenuLanguage} from "../../components/MultiLanguage";
import {Store} from "../../networking/stores/Store";

const CategoryEdit = ({ category_id = 0, onChange = () => {} }) => {
  const intl = useIntl();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading_lang, setLoadingLang] = useState(false);

  const d_lang = defaultMenuLanguage(intl.locale);
  const form = useRef();

  useEffect(() => {
    if (category_id) {
      getCategory(category_id);
    }
  }, []);

  function getCategory(category_id) {
    setLoading(true);
    post("api/products/get_category", { cafe_id: getCafe(), category_id }).then((res) => {
      setLoading(false);

      if (res.result) {
        let category =  decodingLangFormat(res.category, ["name"]);
        category["image"] = [category.image]

        setData({...category});
      }
    });
  }


  function onSet(values) {
    values = encodingLangFormat(values, ["name"]);
    if(Array.isArray(values.image) && values.image.length){
      values.image = values.image[0]
    }else{
      values.image=null
    }

    onChange(values)
    Store.setModal2({})
    /*setLoading(true);
    post("api/products/set_category", { cafe_id: getCafe(), ...values, category_id }).then((res) => {
      setLoading(false);
      if (res.result) {
        success()
        onChange()
        Store.setModal2({})
      }else{
        error()
      }
    });*/
  }

  if (!data["category_id"] && category_id) {
    return <Spin className="gx-loader-container" />;
  }


  return (
    <div>

      <Form
        ref={form}
        style={{ margin: 20 }}
        layout="vertical"
        name="validate_other"
        initialValues={data}
        onFinish={onSet}
        onValuesChange={(values) => {
          setData({ ...data, ...values });
        }}
      >
        <MultiLanguage
          loading={loading_lang}
          onLoad={(from, to) => {
            setLoadingLang(true);
            translate(intl, data, to, ["name"]).then((e) => {

              setLoadingLang(false);
              form.current.setFieldsValue(e);
            });
          }}
        >
          {
            (lang)=>
              <div>
                <Form.Item valuePropName="images" name={"image"} label={"Görsel"}>
                  <ImagePicker
                    title={""}
                    multiple={false}
                    accept={".png, .jpg, .jpeg"}
                    maxCount={1}
                  />
                </Form.Item>
                <Form.Item
                  name={["name",lang]}
                  label={<IntlMessages id="kategori" />}
                  rules={
                    d_lang == lang
                      ? [
                        {
                          required: true,
                          message: <IntlMessages id={"gecersizbilgi"} />,
                        },
                      ]
                      : []
                  }
                >
                  <Input maxLength={150}  />
                </Form.Item>
              </div>
          }
        </MultiLanguage>



        <Form.Item className="gx-text-right gx-flex-row">

          {category_id ? (
            <Popconfirm
              title={<IntlMessages id="kategorisilwarn" />}
              onConfirm={() => {
                onSet({
                  ...data,
                  status: 0,
                  category_id,
                });
              }}
            >
              <Button
                loading={loading}
                className="gx-mr-2"
                danger
              >
                <IntlMessages id="kaldir" />
              </Button>
            </Popconfirm>
          ) : null}
          <Button
            loading={loading}
            className="gx-mr-2"
            type="primary"
            htmlType="submit"
          >
            <IntlMessages id="kaydet" />
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(CategoryEdit);
