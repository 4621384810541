import React, { useEffect, useState } from "react";

import { getMe, getUser, post } from "../../networking/Server";
import { observer } from "mobx-react-lite";
import {
  Row,
  Col,
  Button,
  Card,
  Divider,
  Form,
  Input,
  InputNumber,
  Spin,
  Checkbox,
  TimePicker,
  Switch,
  Select,
} from "antd";
import { getCafe, getCafes } from "../../functions/getCafe";
import ImagePicker from "../../components/ImagePicker";
import { error, success } from "../../functions/toast";
import { MStore } from "../../networking/stores/MainStore";
import { toJSON, toParse } from "../../functions/json";
import { l_moment } from "../../functions/l_moment";
import { TwitterPicker, BlockPicker } from "react-color";
import { createColor } from "../../functions/createColor";
import Customizer from "../../containers/Customizer";
import IntlMessages from "../../util/IntlMessages";
import { OrderTypes } from "../../constants/Config";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const _colors = [
  "#31c149",
  "#fb3d67",
  "#D9E3F0",
  "#F47373",
  "#697689",
  "#37D67A",
  "#2CCCE4",
  "#555555",
  "#ff8a65",
  "#ba68c8",
];
const opening_hours = [
  { open: true, start: "08:00:00", end: "20:00:00" },
  { open: true, start: "08:00:00", end: "20:00:00" },
  { open: true, start: "08:00:00", end: "20:00:00" },
  { open: true, start: "08:00:00", end: "20:00:00" },
  { open: true, start: "08:00:00", end: "20:00:00" },
  { open: true, start: "08:00:00", end: "20:00:00" },
  { open: false, start: "08:00:00", end: "20:00:00" },
];
const CafeAdd = ({ onSave }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [on_loading, setOnLoading] = useState(false);

  useEffect(() => {}, []);



  function setCafe(_data) {
    _data["image"] = Array.isArray(_data["image"]) ? _data["image"][0] : "";
    _data["opening_hours"] = data["opening_hours"];
    _data["colors"] = data["colors"];
    _data["cafe_id"] = data["cafe_id"];
    _data["order_types"] = toJSON(_data["order_types"]);
    _data["no_require_pay"] = toJSON(_data["no_require_pay"]);

    setLoading(true);
    post("api/cafe/add", { data: _data }, true).then((res) => {
      setLoading(false);
      if (res.result) {
        MStore.setCafe(res["cafe"]);
        success();
        onSave();
      } else {
        setLoading(false);
        error();
      }
    });
  }

  let colors = toParse(data, "colors");

  let setting = {};
  try {
    setting = data["settings"][0];
  } catch (e) {}

  return (
    <Card style={{ padding: 20 }}>
      <Form
        style={{ marginTop: 20 }}
        {...layout}
        layout="vertical"
        name="validate_other"
        initialValues={data}
        onFinish={setCafe}
      >
        <Form.Item valuePropName="images" name={"image"} label={"Logo"}>
          <ImagePicker
            title={""}
            multiple={false}
            accept={".png, .jpg, .jpeg"}
            maxCount={1}
          />
        </Form.Item>

        <Form.Item
          name={"name"}
          label={"Kafe İsmi"}
          rules={[{ required: true, message: "Boş Bırakmayınız" }]}
        >
          <Input maxLength={100} />
        </Form.Item>

        <Form.Item
          style={{ maxWidth: "50%" }}
          name={"currency"}
          label={"Para Birimi"}
          rules={[{ required: true, message: "Boş Bırakmayınız" }]}
        >
          <Input maxLength={5} />
        </Form.Item>

        <Form.Item
          name={"device_id"}
          initialValue={setting["device_id"]}
          label={"Lokal Sunucu Cihaz ID"}
        >
          <Input maxLength={100} />
        </Form.Item>

        <Form.Item
          name={"address"}
          label={"Adres"}
          rules={[{ required: false }]}
        >
          <Input.TextArea rows={2} />
        </Form.Item>

        <Form.Item
          name={"phone"}
          label={"Telefon"}
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          style={{ maxWidth: "50%" }}
          name={"tax_name"}
          label={"Vergi İsmi"}
          rules={[{ required: false }]}
        >
          <Input maxLength={50} />
        </Form.Item>

        <Form.Item
          style={{ maxWidth: "50%" }}
          name={"tax_rate"}
          label={"Vergi Oranı"}
          rules={[{ required: false }]}
        >
          <InputNumber
            min={0}
            max={100}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace("%", "")}
          />
        </Form.Item>

        <div>
          <Divider dashed orientation="left">
            Renkler
          </Divider>
          <Row
            style={{ flexDirection: "row", alignItems: "center", margin: 20 }}
          >
            <Col
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                Pos Ana Renk
              </h4>
              <BlockPicker
                colors={_colors}
                color={colors["primary"] ? colors["primary"] : "#31c149"}
                onChangeComplete={({ hex }) => {
                  colors["primary"] = hex;
                  data["colors"] = toJSON(colors);
                  setData({ ...data });
                }}
              />
            </Col>
            <Col
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                Pos İkinci Renk
              </h4>
              <BlockPicker
                colors={_colors}
                color={colors["secondary"] ? colors["secondary"] : "#fb3d67"}
                onChangeComplete={({ hex }) => {
                  colors["secondary"] = hex;
                  data["colors"] = toJSON(colors);
                  setData({ ...data });
                }}
              />
            </Col>
            <Col
              style={{
                marginTop: 50,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
            >
              <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                İstatistik Grafik Renk
              </h4>
              <BlockPicker
                colors={_colors}
                color={colors["statistic"]}
                onChangeComplete={({ hex }) => {
                  colors["statistic"] = hex;
                  data["colors"] = toJSON(colors);
                  setData({ ...data });
                }}
              />
            </Col>
          </Row>
        </div>

        <div>
          <Divider dashed orientation="left">
            QR Uygulama Ayarları
          </Divider>
          <Form.Item
            name={"order_types"}
            initialValue={toParse(setting["order_types"])}
            label={"Desteklenen Siparişler"}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              defaultValue={data["order_types"]}
            >
              {Object.values(OrderTypes)
                .filter(({ id }) => [3, 4, 7].includes(id))
                .map(({ id, value }) => (
                  <Select.Option value={id}>{value}</Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name={"no_require_pay"}
            initialValue={toParse(setting["no_require_pay"])}
            label={"Nakit Ödeme Destekleyen Siparişler"}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              defaultValue={data["no_require_pay"]}
            >
              {Object.values(OrderTypes)
                .filter(({ id }) => [3, 4, 7].includes(id))
                .map(({ id, value }) => (
                  <Select.Option value={id}>{value}</Select.Option>
                ))}
            </Select>
          </Form.Item>
        </div>

        {Array.isArray(opening_hours) && opening_hours.length ? (
          <div style={{ marginTop: 50 }}>
            <Divider dashed orientation="left">
              <IntlMessages id="calismasaatleri" />
            </Divider>
            {opening_hours.map(({ open, start, end }, index) => {
              return (
                <Row
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    margin: 20,
                  }}
                >
                  <Col xl={4} lg={6} md={8} sm={8} xs={24}>
                    <Checkbox
                      checked={open}
                      style={
                        !open ? { textDecorationLine: "line-through" } : {}
                      }
                      onChange={(e) => {
                        opening_hours[index]["open"] = e.target.checked;
                        data["opening_hours"] = toJSON(opening_hours);
                        setData({ ...data });
                      }}
                    >
                      {l_moment()
                        .day(index + 1)
                        .format("dddd")}
                    </Checkbox>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                    <TimePicker.RangePicker
                      order={false}
                      disabled={!open}
                      minuteStep={15}
                      allowClear={false}
                      format={"HH:mm"}
                      value={[l_moment(start, "HH:mm"), l_moment(end, "HH:mm")]}
                      onChange={(_, hours) => {
                        opening_hours[index]["start"] = hours[0];
                        opening_hours[index]["end"] = hours[1];
                        data["opening_hours"] = toJSON(opening_hours);

                        setData({ ...data });
                      }}
                    />
                  </Col>
                </Row>
              );
            })}
          </div>
        ) : null}

        <Form.Item style={{ marginTop: 50 }}>
          <Button
            loading={loading}
            className="gx-mr-2"
            type="primary"
            htmlType="submit"
          >
            Kaydet
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default observer(CafeAdd);
