import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { post } from "../../networking/Server";
import { getCafe, getCafes } from "../../functions/getCafe";
import {
  Input,
  TreeSelect,
  InputNumber,
  Form,
  Spin,
  Button,
  Select,
  Checkbox,
  Popconfirm,
  Popover,
  Switch,
} from "antd";
import { Store } from "../../networking/stores/Store";
import { error, success } from "../../functions/toast";
import IntlMessages from "../../util/IntlMessages";
import { useIntl } from "react-intl";
import { languageText } from "../../functions/language";

const LoyaltyAdd = ({ loyalty_product_id = 0, onChange = () => {} }) => {
  const [data, setData] = useState({});
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    if (loyalty_product_id) {
      setLoading(true);
      getLoyaltyProduct(loyalty_product_id);
    }

    getProducts();
  }, []);

  function getLoyaltyProduct(loyalty_product_id, set = false) {
    post("api/loyalty/get_one", {
      cafe_id: getCafe(),
      loyalty_product_id,
    }).then((res) => {
      setLoading(false);
      if (res.result) {
        if (set) {
          success();
          onChange(res.product);
          Store.setModal({});
        }
        setData(res.product);
      }
    });
  }

  function onSet(values) {
    values = { ...data, ...values };
    setLoading(true);
    post("api/loyalty/set", { cafe_id: getCafe(), data: values }).then(
      (res) => {
        if (res.result) {
          getLoyaltyProduct(res.loyalty_product_id, true);
        } else {
          setLoading(false);
          if (res.error) {
            error(intl.formatMessage({ id: res.error }));
          } else {
            error();
          }
        }
      }
    );
  }

  function getProducts() {
    post("api/loyalty/get_products", { cafe_id: getCafe() }).then((res) => {
      if (res.result) {
        setProducts(res.products);
      }
    });
  }

  if (!data["loyalty_product_id"] && loyalty_product_id) {
    return <Spin className="gx-loader-container" />;
  }
  return (
    <div>
      <Form
        style={{ margin: 20 }}
        layout="vertical"
        name="validate_other"
        initialValues={data}
        onFinish={onSet}
      >
        <Form.Item
          name={"product_id"}
          label={<IntlMessages id={"urun"} />}
          rules={[
            { required: true, message: <IntlMessages id={"gecersizbilgi"} /> },
          ]}
        >
          <Select
            loading={Array.isArray(products) && !products.length}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {Array.isArray(products)
              ? products.map(({ product_id, name }) => {
                  return (
                    <Select.Option value={product_id}>
                      {languageText(name)}
                    </Select.Option>
                  );
                })
              : null}
          </Select>
        </Form.Item>

        <Form.Item
          name={"score"}
          label={<IntlMessages id={"puan"} />}
          rules={[
            { required: true, message: <IntlMessages id={"gecersizbilgi"} /> },
          ]}
        >
          <InputNumber min={1} />
        </Form.Item>

        <Form.Item className=" gx-text-right gx-flex-row">
          {loyalty_product_id ? (
            <Popconfirm
              title={<IntlMessages id="sadakatsilwarn" />}
              onConfirm={() => {
                onSet({ status: 0 });
              }}
            >
              <Button loading={loading} className="gx-mr-2" danger>
                <IntlMessages id={"sil"} />
              </Button>
            </Popconfirm>
          ) : null}
          <Button
            loading={loading}
            className="gx-mr-2"
            type="primary"
            htmlType="submit"
          >
            <IntlMessages id={"kaydet"} />
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(LoyaltyAdd);
