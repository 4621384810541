import React, { useEffect, useState } from "react";
import { Button, Form, Input, message } from "antd";
import IntlMessages from "util/IntlMessages";
import { MailFilled, LockFilled } from "@ant-design/icons";
import { post } from "../../networking/Server";
import { useIntl } from "react-intl";
import { success, error } from "../../functions/toast";
import { CheckOutlined } from "@ant-design/icons";

const EmailControl = ({ history }) => {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const onLogin = (values) => {
    setLoading(true);
    post("send_email", values, false).then((res) => {
      setLoading(false);

      if (res.result) {
        success();
        setPage(2);
      } else {
        error(intl.formatMessage({ id: res.error }));
      }
    });
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        {page == 1 ? (
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content gx-align-items-center gx-align-self-center">
              <div className="gx-app-logo-wid">
                <div
                  style={{
                    height: 120,
                    width: 120,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 1000,
                    backgroundColor: "#f0f0f0",
                  }}
                >
                  <img
                    height={80}
                    width={80}
                    alt="example"
                    src={require("assets/images/ci-logo2.png")}
                  />
                </div>
              </div>
            </div>
            <div className="gx-app-login-content">
              <h1
                className="gx-mt-4 gx-text-center"
                style={{ fontWeight: 500 }}
              >
                CyberPos <IntlMessages id="emailkontrol" />
              </h1>
              <p className="gx-mb-5 gx-text-center">
                <IntlMessages id="emailkontrolaciklama" />
              </p>
              <Form
                initialValues={{ remember: true }}
                name="basic"
                onFinish={onLogin}
                className="gx-signin-form gx-form-row0 gx-text-center"
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: "gecersizbilgi" }),
                    },
                  ]}
                  name="email"
                >
                  <Input
                    allowClear
                    bordered={false}
                    prefix={<MailFilled className="gx-text-secondary" />}
                    style={{ backgroundColor: "#f5f5f5", borderRadius: 20 }}
                    placeholder={intl.formatMessage({ id: "email" })}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    loading={loading}
                    style={{ width: "100%", borderRadius: 20 }}
                    type="primary"
                    className="gx-mb-0"
                    htmlType="submit"
                  >
                    <IntlMessages id="devamet" />
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        ) : page == 2 ? (
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content gx-align-items-center gx-align-self-center">
              <div className="gx-app-logo-wid">
                <div
                  style={{
                    height: 120,
                    width: 120,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 1000,
                    backgroundColor: "#f0f0f0",
                  }}
                >
                  <CheckOutlined style={{ fontSize: 60, color: "#31c149" }} />
                </div>
              </div>
            </div>
            <div className="gx-app-login-content">
              <h1
                className="gx-mt-4 gx-text-center"
                style={{ fontWeight: 500 }}
              >
                <IntlMessages id="emailgonderildi" />
              </h1>
              <p className="gx-mb-5 gx-text-center">
                <IntlMessages id="emailgonderildiaciklama" />
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EmailControl;
