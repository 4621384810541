import React, { useEffect, useState } from "react";

import { getMe, getUser, post } from "../../networking/Server";
import { observer } from "mobx-react-lite";
import {
  Button,
  Card,
  Collapse,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Spin,
} from "antd";
import { getCafe, getCafes } from "../../functions/getCafe";
import { PlusOutlined } from "@ant-design/icons";
import { Store } from "../../networking/stores/Store";
import { error, success } from "../../functions/toast";
import IntlMessages from "../../util/IntlMessages";
import { useIntl } from "react-intl";

const EditTable = ({
  table_num,
  table_id,
  table_category_id,
  status = 1,
  onChange = () => {},
}) => {
  const [loading, setLoading] = useState(false);

  const intl = useIntl();

  function editTable(data) {
    setLoading(true);
    post("api/cafe/set_table", {
      cafe_id: getCafe(),
      data: { ...data, table_id, table_category_id },
    }).then((res) => {
      setLoading(false);
      Store.setModal({});
      if (res.result) {
        success();
        onChange();
      } else {
        if (res.error) {
          error(intl.formatMessage({ id: res.error }));
        } else {
          error();
        }
      }
    });
  }
  return (
    <div>
      <Form
        style={{ margin: 20 }}
        layout="horizontal"
        name="validate_other"
        initialValues={{ table_num, table_id }}
        onFinish={editTable}
      >
        <Form.Item
          name={"table_num"}
          label={<IntlMessages id="masanumarasi" />}
          rules={[
            { required: true, message: <IntlMessages id="gecersizbilgi" /> },
          ]}
        >
          <InputNumber min={1} />
        </Form.Item>

        <Form.Item className=" gx-text-right gx-flex-row">
          {table_id ? (
            <Popconfirm
              title={<IntlMessages id="masayısilwarn" />}
              onConfirm={() => {
                editTable({ table_id, status: 0 });
              }}
            >
              <Button loading={loading} className="gx-mr-2" danger>
                <IntlMessages id="sil" />
              </Button>
            </Popconfirm>
          ) : null}
          <Button
            loading={loading}
            className="gx-mr-2"
            type="primary"
            htmlType="submit"
          >
            <IntlMessages id="kaydet" />
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default observer(EditTable);
