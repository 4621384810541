import React, { useEffect, useState } from "react";

import { getUser, post } from "../../networking/Server";
import { Store } from "../../networking/stores/Store";
import { observer } from "mobx-react-lite";
import {
  Row,
  Col,
  DatePicker,
  Select,
  Card,
  Avatar,
  Spin,
  Tag,
  Button,
} from "antd";
import CartPie from "../../components/Charts/CartPie";
import CardLine from "../../components/Charts/CartLine";
import {
  getOrderStatusName,
  getOrderTypeName,
  getStockTypeName,
  OrderStatuses,
} from "../../constants/Config";
import { getCafe } from "../../functions/getCafe";
import { l_moment } from "../../functions/l_moment";
import StaticticsFilter from "../../components/StaticticsFilter";
import DataTable from "../../functions/dataTable";
import { EyeOutlined } from "@ant-design/icons";
import { priceText } from "../../functions/priceText";
import OrderDetail from "./order_detail";
import CardBar from "../../components/Charts/CartBar";
import IntlMessages from "../../util/IntlMessages";
import { useIntl } from "react-intl";
import { lang, languageText } from "../../functions/language";

const StockStatistics = () => {
  const [date, setDate] = useState({
    start: l_moment().set({
      hour: 5,
      minute: 0,
      second: 0,
      millisecond: 0,
    }),
    end: l_moment()
      .set({
        hour: 4,
        minute: 59,
        second: 59,
        millisecond: 0,
      })
      .add(1, "days"),
    period: "DAY",
  });
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({ orders: [] });

  const intl = useIntl();

  useEffect(() => {
    getData();
  }, [date]);

  function getData() {
    setLoading(true);
    post("api/statistics/stocks", { date, _cafe_id: getCafe() }).then((res) => {
      setLoading(false);
      if (res.result) {
        try {
          res.data["product_list_s"] = res.data["product_list_s"].map((e) => ({
            ...e,
            unit: " " + getStockTypeName(e["unit"]),
          }));
        } catch (e) {}

        setData(res.data);
      }
    });
  }

  const { product_list, stock_list, product_stocks } = data;

  const columns = [
    {
      name: "stock_list_id",
      label: "ID",
      options: {
        filter: false,
      },
    },
    {
      name: "product",
      label: lang(intl, "urun"),
      options: {
        filter: false,
        searchable: false,
        customBodyRender: ({ name }) => languageText(name),
      },
    },
    {
      name: "decrease_stock",
      label: lang(intl, "kullanilanmiktar"),
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value, { rowIndex }) => {
          try {
            return (
              value +
              " " +
              getStockTypeName(stock_list[rowIndex]["product"]["stock_type"])
            );
          } catch (e) {}
          return value;
        },
      },
    },
    {
      name: "cafe",
      label: lang(intl, "isletme"),
      options: {
        searchable: false,
        customBodyRender: ({ name }) => name,
      },
    },
    {
      name: "orderAt",
      label: lang(intl, "sipariszamani"),
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value) => l_moment(value).format("DD/MM/YYYY HH:mm"),
      },
    },
  ];

  return (
    <div>
      <div className="ant-row-flex">
        <div>
          <h1>
            <IntlMessages id="stokistatistigi" />
          </h1>
          <p>
            <IntlMessages id="stokistatistigidesc" />
          </p>
        </div>
        <div className="gx-ml-auto gx-p-2 gx-card">
          <StaticticsFilter date={date} setDate={setDate} loading={loading} />
        </div>
      </div>

      <Row>
        {/* <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card
            style={{ height: 460 }}
            className="gx-card"
            title={<IntlMessages id="urunstoklari" />}
          >
            <CardBar height={330} data={product_stocks} />
          </Card>
        </Col>*/}
      </Row>
      {/*
         <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card
            className="gx-card"
            style={{ paddingBottom: 50 }}
            title={<IntlMessages id="stokgrafigi" />}
          >
            <CardLine
              removeY
              area
              data={product_list}
              height={370}
              format={({ x, y }) => {
                return {
                  order: l_moment(y).format("YYYY-MM-DD HH:mm:ss"),
                  y: l_moment(y).format("MMM Do"),
                  x: parseInt(x),
                };
              }}
            />
          </Card>
        </Col>
      </Row>
        * */}

      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <DataTable
            loading={loading}
            title={<IntlMessages id="kullanilanstoklar" />}
            data={stock_list}
            columns={columns}
          />
        </Col>
      </Row>
    </div>
  );
};

export default observer(StockStatistics);
