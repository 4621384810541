import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import DataTable from "../../functions/dataTable";
import { post } from "../../networking/Server";
import { Store } from "../../networking/stores/Store";
import { priceText } from "../../functions/priceText";
import { getCafe } from "../../functions/getCafe";
import { Avatar, Button, Drawer, Popconfirm } from "antd";
import { EditFilled } from "@ant-design/icons";
import ProductAdd from "./add";
import Categories from "../Categories";
import IntlMessages from "../../util/IntlMessages";
import { useIntl } from "react-intl";
import { CloseOutlined } from "@material-ui/icons";
import LoyaltyAdd from "./add";
import { lang, languageText } from "../../functions/language";

const LoyaltyList = ({ history }) => {
  const intl = useIntl();
  const [data, setData] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    Store.setLoading(true);
    post("api/loyalty/get_list", { cafe_id: getCafe() }).then((res) => {
      Store.setLoading(false);

      if (res.result) {
        setData(res.products);
      }
    });
  }

  const columns = [
    {
      name: "images",
      label: " ",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value) => {
          let image = "";
          try {
            image = JSON.parse(value)[0];
          } catch (e) {}
          return <Avatar src={image} className="gx-size-50" alt="" />;
        },
      },
    },
    {
      name: "product_id",
      label: "ID",
      options: {
        filter: false,
      },
    },
    {
      name: "loyalty_products",
      label: lang(intl, "puan"),
      options: {
        sort: false,
        filter: true,
        searchable: false,
        customBodyRender: (value) => {
          let score = 0;
          try {
            score = value[0]["score"];
          } catch (e) {}
          return (
            <div className="gx-text-warning gx-font-weight-bold gx-fs-lg">
              {score}
            </div>
          );
        },
      },
    },
    {
      name: "name",
      label: lang(intl, "urunadi"),
      options: {
        filter: false,
        customBodyRender: (value) => languageText(value),
      },
    },
    {
      name: "category",
      label: lang(intl, "kategori"),
      options: {
        customBodyRender: (value) => languageText(value?.name),
      },
    },
    {
      name: "price",
      label: lang(intl, "fiyat"),
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value) => priceText(value),
      },
    },
    {
      name: "discount_price",
      label: lang(intl, "indirimlifiyat"),
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value) => (value ? priceText(value) : "--"),
      },
    },
    {
      name: "active",
      label: lang(intl, "aktiflik"),
      options: {
        sort: false,
        filter: true,
        searchable: false,
        customBodyRender: (value) =>
          value ? (
            <div className="gx-text-primary">
              <IntlMessages id="aktif" />
            </div>
          ) : (
            <div className="gx-text-danger">
              <IntlMessages id="satisakapali" />
            </div>
          ),
      },
    },
    {
      name: "loyalty_products",
      label: " ",
      options: {
        sort: false,
        filter: false,
        searchable: false,
        customBodyRender: (loyalty_product_id) => {
          try {
            loyalty_product_id = loyalty_product_id[0]["loyalty_product_id"];
          } catch (e) {}
          return (
            <div>
              <Button
                type="primary"
                shape="circle"
                icon={<EditFilled />}
                onClick={() => {
                  Store.setModal({
                    title: "Güncelle",
                    child: (
                      <LoyaltyAdd
                        loyalty_product_id={loyalty_product_id}
                        onChange={(new_data) => {
                          getProducts();
                        }}
                      />
                    ),
                  });
                }}
              />
            </div>
          );
        },
      },
    },
  ];

  return (
    <div>
      <Button
        type="primary"
        onClick={() => {
          Store.setModal({
            title: <IntlMessages id={"ekle"} />,
            child: (
              <ProductAdd
                onChange={() => {
                  getProducts();
                }}
              />
            ),
          });
        }}
      >
        <IntlMessages id={"urunekle"} />
      </Button>

      <DataTable
        title={<IntlMessages id={"urunler"} />}
        data={data.filter((e) => e?.status)}
        columns={columns}
      />
    </div>
  );
};

export default observer(LoyaltyList);
