import React from "react";
import { Badge, Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
import { Authorities } from "../../constants/Config";
import { getUser } from "../../networking/Server";
import { observer, Observer } from "mobx-react-lite";
import { Store } from "../../networking/stores/Store";
import { MStore } from "../../networking/stores/MainStore";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  let { navStyle, themeType } = useSelector(({ settings }) => settings);
  let { pathname } = useSelector(({ common }) => common);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "gx-no-header-notifications";
  };

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  let authorities = [];
  try {
    authorities = getUser()["authorities"];
    if (!Array.isArray(authorities)) authorities = [];
  } catch (e) {}

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications  ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile pathname={pathname} />
          {/* <AppsNavigation/> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <Menu.Item key={"home"}>
              <Link to={"/home"}>
                <i className={"icon icon-home"} />

                <IntlMessages id={"home"} />
              </Link>
            </Menu.Item>
            {Authorities.filter((e) =>
              Array.isArray(e.id)
                ? authorities.some((id) => e.id.includes(id))
                : authorities.includes(e.id) && e.nav
            ).map(({ id, type, icon, path, sub }) => {
              if (sub) {
                return (
                  <SubMenu
                    key={path}
                    className={getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className={"icon icon-" + icon} />
                        <IntlMessages id={type} />
                      </span>
                    }
                  >
                    {sub.map(({ id, type, path }) => {
                      if (
                        Array.isArray(id)
                          ? authorities.some((e) => id.includes(e))
                          : authorities.includes(id)
                      ) {
                        return (
                          <Menu.Item key={path}>
                            <Link to={"/" + path}>
                              <IntlMessages id={type} />
                            </Link>
                          </Menu.Item>
                        );
                      }
                      return null;
                    })}
                  </SubMenu>
                );
              }

              return (
                <Menu.Item key={path}>
                  <Observer
                    render={(props) => (
                      <Badge
                        count={MStore.counts[path]}
                        overflowCount={99}
                        offset={[0, 3]}
                        style={{
                          left: 0,
                          right: "auto",
                        }}
                      >
                        <Link to={"/" + path}>
                          <i className={"icon icon-" + icon} />

                          <IntlMessages id={type} />
                        </Link>
                      </Badge>
                    )}
                  />
                </Menu.Item>
              );
            })}

            <Menu.Item key={"document"}>
              <a target="_blank" href={"https://posdocument.cyberistanbul.com"}>
                <i className={"icon icon-copy"} />
                Document
              </a>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
