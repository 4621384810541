import React, { useState } from "react";
import { Badge, Button, Spin, Tabs } from "antd";
import { menuLanguages } from "../constants/Config";
import IntlMessages from "../util/IntlMessages";
import { useIntl } from "react-intl";
import Loading from "./Loading";

export function defaultMenuLanguage(locale) {
  let res = menuLanguages.find((e) => e.lang == locale);
  if (res) return res.lang;
  else return "en-US";
}

const MultiLanguage = ({ children, onLoad, loading }) => {
  const intl = useIntl();

  const d_lang = defaultMenuLanguage(intl.locale);

  const [lang, setLang] = useState(d_lang);

  return (
    <div>
      <Tabs
        loading={true}
        defaultActiveKey={d_lang}
        onChange={(lang) => {
          setLang(lang);
        }}
        tabBarExtraContent={
          d_lang != lang && onLoad ? (
            <Button
              loading={loading}
              style={{ margin: 0 }}
              type="primary"
              onClick={() => {
                onLoad(d_lang, lang);
              }}
            >
              {loading ? "Dil Dosyası Güncelleniyor" : "Otomatik Çevir"}
            </Button>
          ) : null
        }
      >
        {menuLanguages.map(({ lang, name }) => (
          <Tabs.TabPane forceRender tab={<IntlMessages id={name} />} key={lang}>
            {children(lang)}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};
export default MultiLanguage;
