import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { post } from "../../networking/Server";
import { Store } from "../../networking/stores/Store";
import { getCafe, getCafes } from "../../functions/getCafe";
import {
  Button,
  Card,
  Collapse,
  Divider,
  Form,
  InputNumber,
  message,
  Row,
  Select,
} from "antd";
import IntlMessages from "../../util/IntlMessages";
import { toJSON, toParse } from "../../functions/json";
import Loading from "../../components/Loading";
import { OrderTypes } from "../../constants/Config";
import { success } from "../../functions/toast";
import saveSvgAsPng from "save-svg-as-png";
import EditTable from "../Tables/edit";
import { PlusOutlined } from "@ant-design/icons";

const QRCode = require("qrcode.react");

const OrderQr = ({ history, just_menu = false }) => {
  const [data, setData] = useState([]);
  const [tables, setTables] = useState([]);
  const [loading_select, setLoadingSelect] = useState(false);
  const [qr_loading, setQrLoading] = useState(false);

  useEffect(() => {
    getSettings();
    getTables();
  }, []);

  function getSettings() {
    Store.setLoading(true);
    post("api/settings/get", { cafe_id: getCafe() }).then((res) => {
      Store.setLoading(false);

      if (res.result) {
        res.setting["order_types"] = toParse(res.setting["order_types"]);
        res.setting["no_require_pay"] = toParse(res.setting["no_require_pay"]);
        setData(res.setting);
      }
    });
  }

  function getTables() {
    post("api/cafe/get_tables", { cafe_id: getCafe() }).then((res) => {
      if (res.result) {
        setTables(res.tables);
      }
    });
  }

  function setSettings(value) {
    let _data = {};
    Object.entries(value).map((res) => {
      _data[res[0]] = toJSON(res[1]);
    });
    _data["setting_id"] = data["setting_id"];

    setLoadingSelect(true);

    post("api/settings/set", { cafe_id: getCafe(), data: _data }).then(
      (res) => {
        setLoadingSelect(false);

        if (res.result) {
          success();
        }
      }
    );
  }

  function createTableQr(category_name, table_nums) {
    let hide = message.loading("QR KODLAR OLUŞTURULUYOR", 0);
    post("api/cafe/createQr", { category_name, table_nums }).then((res) => {
      hide();
      if (!res.error) {
        success();
        window.open(res.fileUrl);
      }
    });
  }

  const downloadQR = (id) => {
    const canvas = document.getElementById(id);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = id + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  if (!data || !data["cafe_id"]) {
    return <Loading />;
  }
  const currenct = getCafe("currency");

  const menu_qr_code =
    "https://qrmenu.cyberistanbul.com/categories/" +
    window.btoa(getCafe("cafe_id")) +
    "/" +
    getCafe("app_id");

  if (just_menu) {
    return (
      <div>
        <Card title={"Menü QR Kod"}>
          <p>
            Bu qr kod ile müşterileriniz işletmelerinize ait menüyü uygulama
            indirmeden görüntüleyebilir.
            <br /> Bu qr codu magnetlerde, kartlarınızda, afişlerde,
            masalarınızda veya reklamlarınızda kullanabilirsiniz.
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <QRCode
              id="menu-qr-code"
              size={300}
              level={"H"}
              value={menu_qr_code}
              includeMargin={true}
            />
            <div
              style={{
                margin: 10,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{ margin: 0, padding: 0 }}
                type="link"
                onClick={() => {
                  window.open(menu_qr_code, "_blank");
                }}
              >
                Menüye Git
              </Button>
              <Button
                style={{ margin: 0, padding: 0 }}
                type="link"
                onClick={() => {
                  downloadQR("menu-qr-code");
                }}
              >
                İndir
              </Button>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div>
      <Card title={"Ayarlar"}>
        <Form
          style={{ margin: 20 }}
          layout="vertical"
          name="validate_other"
          initialValues={data}
          onFinish={setSettings}
        >
          <Form.Item
            name={"order_types"}
            initialValue={toParse(data["order_types"])}
            label={<IntlMessages id="desteklenensiparisler" />}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "50%" }}
              defaultValue={data["order_types"]}
            >
              {Object.values(OrderTypes)
                .filter(({ id }) => [3, 4, 7].includes(id))
                .map(({ id, value }) => (
                  <Select.Option value={id}>
                    <IntlMessages id={value} />
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name={"no_require_pay"}
            initialValue={toParse(data["no_require_pay"])}
            label={<IntlMessages id="nakitodemedestekleyensiparisler" />}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "50%" }}
              defaultValue={data["no_require_pay"]}
            >
              {Object.values(OrderTypes)
                .filter(({ id }) => [3, 4, 7].includes(id))
                .map(({ id, value }) => (
                  <Select.Option value={id}>
                    <IntlMessages id={value} />
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={"min_order_price"}
            initialValue={data["min_order_price"]}
            label={<IntlMessages id="minimumsiparistutari" />}
            rules={[
              { required: true, message: <IntlMessages id="gecersizbilgi" /> },
            ]}
          >
            <InputNumber
              min={1}
              formatter={(value) =>
                `${currenct} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) =>
                value.replace(new RegExp(currenct + "s?|(,*)", "g"), "")
              }
            />
          </Form.Item>
          <Form.Item style={{ marginTop: 50 }}>
            <Button
              loading={loading_select}
              className="gx-mr-2"
              type="primary"
              htmlType="submit"
            >
              <IntlMessages id="kaydet" />
            </Button>
          </Form.Item>
        </Form>
      </Card>

      <Card title={"Menü QR Kod"}>
        <p>
          Bu qr kod ile müşterileriniz işletmelerinize ait menüyü uygulama
          indirmeden görüntüleyebilir.
          <br /> Bu qr codu magnetlerde, kartlarınızda, afişlerde, masalarınızda
          veya reklamlarınızda kullanabilirsiniz.
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <QRCode
            id="menu-qr-code"
            size={300}
            level={"H"}
            value={menu_qr_code}
            includeMargin={true}
          />
          <div
            style={{
              margin: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{ margin: 0, padding: 0 }}
              type="link"
              onClick={() => {
                window.open(menu_qr_code, "_blank");
              }}
            >
              Menüye Git
            </Button>
            <Button
              style={{ margin: 0, padding: 0 }}
              type="link"
              onClick={() => {
                downloadQR("menu-qr-code");
              }}
            >
              İndir
            </Button>
          </div>
        </div>
      </Card>
      <Card title={"Masa QR Kod"}>
        <p>
          Bu qr kodları işletmenizdeki masalara koyarak müşterilerinizden masaya
          sipariş alabilirsiniz.
          <br /> Her masa için ayrı bir qr kod oluşturulacaktır.
          <br />
          <br /> <strong>Not:</strong> Masa numarasının üzerine tıklayarak da
          tek bir qr kod indirebilirsiniz.
        </p>

        <Collapse
          ghost
          activeKey={new Array(30).fill(0).map((e, i) => (i + 1).toString())}
        >
          {tables.map(({ table_category_id, name, tables }, index) => {
            return (
              <Collapse.Panel
                header={
                  <Button
                    style={{ margin: 0, padding: 0 }}
                    size="large"
                    type={"link"}
                    onClick={() => {
                      createTableQr(
                        name,
                        tables.map(({ table_num, table_id }) => ({
                          table_num,
                          table_id,
                        }))
                      );
                    }}
                  >
                    {"[name] İçin Tüm QR Kodları İndir".replace("[name]", name)}
                  </Button>
                }
                key={index + 1}
              >
                <Divider dashed orientation="left">
                  <IntlMessages id="masalar" />
                </Divider>
                <div className="gx-flex-row gx-ml-4">
                  {Array.isArray(tables)
                    ? tables.map(({ table_id, table_num }) => {
                        return (
                          <Button
                            size="large"
                            key={table_id}
                            onClick={() => {
                              createTableQr(name, [{ table_num, table_id }]);
                            }}
                          >
                            {table_num}
                          </Button>
                        );
                      })
                    : null}
                </div>
              </Collapse.Panel>
            );
          })}
        </Collapse>
      </Card>
    </div>
  );
};

export default observer(OrderQr);
