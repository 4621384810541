import { observable, action } from "mobx";
import { create, persist } from "mobx-persist";

class MainStoreC {
  @persist("object") @observable cafe = { cafe_id: 0, currency: "" };
  @persist("object") @observable language = {};
  @persist("object") @observable user = {};
  @persist("object") @observable counts = {};

  @action setCafe(data) {
    this.cafe = data;
  }
  @action setLanguage(data) {
    this.language = data;
  }
  @action setUser(data) {
    this.user = data;
  }
  @action setCounts(data) {
    this.counts = data;
  }
}

const hydrate = create({});

export const MStore = new MainStoreC();

hydrate("MStore", MStore)
  .then(() => console.info("MStore hydrated"))
  .catch((e) => console.error("Store Error: ", e));
