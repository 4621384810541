import React from "react";

import Widget from "components/Widget/index";
import {connect} from "react-redux";
import {THEME_TYPE_DARK} from "../../constants/ThemeSetting";
import CountUp from 'react-countup';

const IconWithTextCard = (props) => {
  const {icon, title, subTitle} = props;
  let {iconColor,suffix} = props;
  if (props.themeType === THEME_TYPE_DARK) {
    iconColor = "white";
  }





  return (
    <Widget>
      <div className="gx-media gx-align-items-center gx-flex-nowrap">
        <div className="gx-mr-lg-4 gx-mr-3">
          <i  className={`icon icon-${icon} gx-text-primary gx-fs-xlxl gx-text-${iconColor} gx-d-flex`}
              style={{fontSize: 45, color:iconColor??""}}/>
        </div>
        <div className="gx-media-body">
          <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">
            <CountUp
              suffix={suffix}
              start={0}
              end={title?parseInt(title):0}
            />
          </h1>
          <p className="gx-text-grey gx-mb-0">{subTitle}</p>
        </div>
      </div>
    </Widget>
  );
};

const mapStateToProps = ({settings}) => {
  const {themeType} = settings;
  return {themeType}
};
export default connect(mapStateToProps, null)(IconWithTextCard);
