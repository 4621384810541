import { getUser, post } from "../networking/Server";
import { Store } from "../networking/stores/Store";
import { info } from "./toast";
import { OrderStatuses } from "../constants/Config";
import { MStore } from "../networking/stores/MainStore";
import React from "react";

let playAlert;

try {
  playAlert=require( "alert-sound-notify")
}catch (e) {

}



function controlAuth(id) {
  let authorities = [];
  try {
    authorities = getUser()["authorities"];
    if (!Array.isArray(authorities)) authorities = [];
  } catch (e) {}

  return !!authorities.includes(id);
}

function controlChrome() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  const mobile = toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });

  if (mobile) {
    return false;
  } else {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        return true;
      }
    }

    return false;
  }
}

function notifyMe() {
  if (controlChrome()) {
    if(playAlert)
      playAlert("purr");
    info("Yeni Siparişiniz Var!!");

    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      new Notification("Yeni Siparişiniz Var!!", {
        silent: true,
      });
    }
  }
}

export function intervals() {
  let location = window.location.pathname;

  try {
    location = location.split("/")[1];
    if (
      [
        "signin",
        "token",
        "lock",
        "password",
        "emailcontrol",
        "setup",
        "promo",
      ].includes(location)
    )
      return;
  } catch (e) {
    return;
  }



  if (controlChrome()) {
    if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
        }
      });
    }
  }

  if (controlAuth(7)) {
    getOrders(false);
  }

  if (controlAuth(19)) {
    controlNotifications()
  }

  let intervals = Store.intervals;
  if (intervals) clearInterval(intervals);
  Store.setIntervals(
    setInterval(() => {
      if (controlAuth(7)) {
        getOrders(Store.orders);
      }
      if (controlAuth(19)) {
        controlNotifications()
      }
    }, 10000)
  );
}

export function getOrders(old_data) {
  post("api/order/get-all").then((res) => {
    if (res.result) {
      if (JSON.stringify(res.orders) != JSON.stringify(old_data)) {
        if (old_data && res.orders.length > old_data.length) {
          notifyMe();
        }

        let count = 0;

        res.orders.map(({ order_follows }) => {
          let last_order_follows = {};

          try {
            last_order_follows = order_follows[0];
          } catch (e) {
            last_order_follows = {};
          }
          if (!last_order_follows) last_order_follows = {};

          const order_status_id = last_order_follows["order_status_id"];

          if (
            ![
              OrderStatuses.Odendi.id,
              OrderStatuses.Iade.id,
              OrderStatuses.Iptal.id,
            ].includes(order_status_id)
          ) {
            count++;
          }
        });

        MStore.setCounts({ "order/list": count });

        Store.setOrders([...res.orders]);
      }
    }
  });
}

function controlNotifications() {
  post("api/user/notification-count").then((res)=>{
    if(res.result){
      MStore.setCounts({ "notifications": res.count??0 });
    }
  })
}
